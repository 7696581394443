import { ApolloClient, FetchResult } from '@apollo/client';
import { omit } from 'ramda';
import uuid from 'uuid/v4';
import {
  PrintHeadingCreationInput,
  LocationPage_UpsertPrintHeading,
  LocationPage_UpsertPrintHeadingVariables,
  UpsertSubject,
  UpsertSubjectVariables,
} from '../../../../__generated__/queries-topic';
import { UPSERT_SUBJECT } from '../../../SubjectForm/Form/upsertSubjectMutation.topic.graphql';
import { UPSERT_PRINT_HEADING } from '../PrintHeadingDialogForm/upsertPrintHeading.topic.graphql';

export const mutateHeadingAutomaticSubject = (
  client: ApolloClient<any>,
  printHeading: Omit<
    PrintHeadingCreationInput,
    'printIssue' | 'PrintTemplate' | '__typename'
  >,
  printIssueId: string,
): Promise<FetchResult<LocationPage_UpsertPrintHeading>> =>
  client.mutate<
    LocationPage_UpsertPrintHeading,
    LocationPage_UpsertPrintHeadingVariables
  >({
    mutation: UPSERT_PRINT_HEADING,
    variables: {
      create: {
        ...omit(['__typename', 'PrintTemplate', 'printIssue'], printHeading),
        printIssue: {
          connect: { id: printIssueId },
        },
      },
      update: {
        ...omit(
          ['__typename', 'id', 'PrintTemplate', 'printIssue'],
          printHeading,
        ),
        printIssue: {
          connect: { id: printIssueId },
        },
      },
      where: { id: printHeading.id },
    },
  });

export const createAutoSubject = async (
  client: ApolloClient<any>,
  title: string,
  subjectId: string | undefined | null,
  unitId: string,
  printHeadingId: string,
  subjectCategoryId?: string,
  subSubjectCategoryId?: string,
): Promise<FetchResult<UpsertSubject>> => {
  const id = subjectId || uuid();
  const category = subSubjectCategoryId || subjectCategoryId;
  const subject = {
    title,
    unit: { connect: { id: unitId } },
    isAuto: true,
    subjectCategory: category
      ? {
          connect: { id: category },
        }
      : undefined,
    assignments: {
      create: [
        {
          printPublication: null,
          printIssue: null,
          printHeading: {
            connect: { id: printHeadingId },
          },
        },
      ],
    },
  };

  return await client.mutate<UpsertSubject, UpsertSubjectVariables>({
    mutation: UPSERT_SUBJECT,
    variables: {
      create: { id, ...subject },
      update: { ...subject },
      where: { id },
    },
  });
};
