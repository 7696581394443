import { gql, useMutation } from '@apollo/client';
import {
  DeleteAttachment,
  DeleteAttachmentVariables,
} from '../../../../__generated__/queries-topic';

const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($id: ID!) {
    deleteAttachment(where: { id: $id }) {
      id
    }
  }
`;

export const useAttachmentDeleter = () =>
  useMutation<DeleteAttachment, DeleteAttachmentVariables>(DELETE_ATTACHMENT);
