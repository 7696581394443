import { gql, useQuery } from '@apollo/client';
import {
  SubjectListPage_GetCategoryTree,
  SubjectListPage_GetFilteredCategoryTree,
  SubjectListPage_GetFilteredCategoryTreeVariables,
} from '../../../../__generated__/queries-topic';

const GET_CATEGORY_TREE = gql`
  query SubjectListPage_GetCategoryTree {
    subjectCategories(first: 1000, where: { parent_is_null: true }) {
      id
      title
      children(first: 100) {
        id
        title
      }
    }
  }
`;

const GET_FILTERED_CATEGORY_TREE = gql`
  query SubjectListPage_GetFilteredCategoryTree(
    $where: SubjectCategoryFilterInput!
  ) {
    subjectCategories(
      first: 1000
      where: { AND: [{ parent_is_null: true }, $where] }
    ) {
      id
      title
      children(first: 100) {
        id
        title
      }
    }
  }
`;

export const useGetCategoryTree = () =>
  useQuery<SubjectListPage_GetCategoryTree>(GET_CATEGORY_TREE);

export const useGetFilteredCategoryTree = (
  variables: SubjectListPage_GetFilteredCategoryTreeVariables,
) =>
  useQuery<
    SubjectListPage_GetFilteredCategoryTree,
    SubjectListPage_GetFilteredCategoryTreeVariables
  >(GET_FILTERED_CATEGORY_TREE, { variables });
