import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import CheckIcon from '@mui/icons-material/CheckBoxTwoTone';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HighlightIcon from '@mui/icons-material/HighlightTwoTone';
import ImageIcon from '@mui/icons-material/ImageTwoTone';
import ShortTextIcon from '@mui/icons-material/ShortTextTwoTone';
import SubjectIcon from '@mui/icons-material/SubjectTwoTone';
import TitleIcon from '@mui/icons-material/TitleTwoTone';
import VideoLabelIcon from '@mui/icons-material/VideoLabelTwoTone';
import WarningIcon from '@mui/icons-material/WarningTwoTone';
import FrameIcon from '@mui/icons-material/WebAssetTwoTone';
import React, { Fragment, useState } from 'react';
import { getSize } from '../../../utils/getSize';
import {
  GetPrintHeading_printHeading_printTemplates_printTemplate,
  GetPrintHeading_printHeading_printTemplates_printTemplate_fields,
} from '../../../__generated__/queries-topic';
import { useCommonStyles } from '../common.styles';
import { CustomDialog } from '../Dialog';
import { useStyles } from './styles';
import { TabPanel } from './TabPanel';

interface DetailsPrintTemplateDialogProps {
  open?: boolean;
  printTemplate: GetPrintHeading_printHeading_printTemplates_printTemplate;
  active: boolean;
  onClose: () => void;
}

// TODO: requires precise typing using indesign client exports
const FieldTypeVarIconMap: Record<any, any> = {
  TITLE: <TitleIcon />,
  CHAPO: <ShortTextIcon />,
  FRAME: <FrameIcon />,
  TEXT: <SubjectIcon />,
  SIGNATURE: <CheckIcon />,
  IMAGE: <ImageIcon />,
  LEGEND: <VideoLabelIcon />,
  HIGHLIGHTS: <HighlightIcon />,
  UNASSIGNED: <WarningIcon />,
};

export const DetailsPrintTemplateDialog: React.FC<DetailsPrintTemplateDialogProps> = ({
  open = false,
  printTemplate,
  active,
  onClose,
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [callapseField, setCallapseField] = useState<Record<number, boolean>>(
    {},
  );

  const autoFields = printTemplate.fields.filter(
    Boolean,
  ) as GetPrintHeading_printHeading_printTemplates_printTemplate_fields[];
  const { size, unit } = getSize(printTemplate.filesize || 0);

  return (
    <CustomDialog
      open={open}
      aria-labelledby="dialog-delete-printTemplate"
      maxWidth="md"
    >
      <DialogTitle id="dialog-delete-printTemplate">
        Détails Gabarit {printTemplate.label}
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={activeTab}
          indicatorColor="secondary"
          textColor="primary"
          onChange={(event, tab) => setActiveTab(tab)}
          aria-label="template details"
          centered
        >
          <Tab label="Informations" />
          <Tab label={`Champs Automatisés (${autoFields.length})`} />
        </Tabs>
        <TabPanel active={activeTab === 0}>
          <Table size="small" className={commonClasses.tableInfo}>
            <TableBody>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">{printTemplate.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>ID Objet S3</TableCell>
                <TableCell align="right">{printTemplate.S3ObjectId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nom fichier</TableCell>
                <TableCell align="right">{printTemplate.filename}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Taille</TableCell>
                <TableCell align="right">
                  {size}
                  {unit}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nombre de pages</TableCell>
                <TableCell align="right">{printTemplate.pageCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Activé</TableCell>
                <TableCell align="right">{active ? 'Oui' : 'Non'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Version extension</TableCell>
                <TableCell align="right">
                  {printTemplate.extVersion || 'N/A'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Créateur</TableCell>
                <TableCell align="right">
                  {(printTemplate.createdBy && printTemplate.createdBy.name) ||
                    'N/A'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date création</TableCell>
                <TableCell align="right">
                  {new Date(printTemplate.createdAt).toLocaleString('fr')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>M-à-j par</TableCell>
                <TableCell align="right">
                  {(printTemplate.updatedBy && printTemplate.updatedBy.name) ||
                    'N/A'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date m-à-j</TableCell>
                <TableCell align="right">
                  {new Date(printTemplate.updatedAt).toLocaleString('fr')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel active={activeTab === 1}>
          <List aria-label="template fields" dense>
            {autoFields.filter(Boolean).map((field, index) => (
              <Fragment key={index}>
                <ListItem
                  button
                  onClick={() =>
                    setCallapseField((data) => ({
                      ...data,
                      [field.id]: !data[field.id],
                    }))
                  }
                >
                  <ListItemIcon>
                    {FieldTypeVarIconMap[field.typeVariation]}
                  </ListItemIcon>
                  <ListItemText primary={field.name} />
                  {callapseField[field.id] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={callapseField[field.id]}
                  unmountOnExit
                  className={classes.callapseContainer}
                >
                  <Table size="small" className={commonClasses.tableInfo}>
                    <TableBody>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">{field.id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Page Number</TableCell>
                        <TableCell align="right">
                          {typeof field.page !== 'object'
                            ? field.page
                            : JSON.stringify(field.page, null, 2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Page Coordinates</TableCell>
                        <TableCell align="right">
                          {JSON.stringify(
                            field.page.coordinates || {},
                            null,
                            2,
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell align="right">{field.type}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Variation</TableCell>
                        <TableCell align="right">
                          {field.typeVariation}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Activé</TableCell>
                        <TableCell align="right">
                          {field.enabled ? 'Oui' : 'Non'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Coordonnés</TableCell>
                        <TableCell align="right">
                          {JSON.stringify(field.coordinates, null, 2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Données</TableCell>
                        <TableCell align="right">
                          {JSON.stringify(field.data, null, 2)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </Fragment>
            ))}
          </List>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Fermer
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};
