import { gql, useQuery } from '@apollo/client';
import {
  GetPhotosCount,
  GetPhotosCountVariables,
  SearchPhotosVariables,
  SearchPhotos_searchPhotos,
  MetadataNameEnumType,
} from '__generated__/queries-photo';
import { PHOTO_FRAGMENT } from '../fragments/photo.photo.graphql';

interface MetadataValueType {
  id: number;
  value: string | null;
}

export const SEARCH_PHOTOS = gql`
  query SearchPhotos(
    $where: PhotoWhereInput
    $first: Int!
    $orderBy: [PhotoOrderByInput!]
    $skip: Int
  ) {
    searchPhotos(where: $where, first: $first, orderBy: $orderBy, skip: $skip) {
      ...Photo
    }
  }
  ${PHOTO_FRAGMENT}
`;

export type SearchPhotosPhoto = Omit<
  SearchPhotos_searchPhotos,
  'metadataByName'
> & { metadataByName: Record<MetadataNameEnumType, MetadataValueType[]> };
interface SearchPhotosResult {
  searchPhotos: SearchPhotosPhoto[];
}

export const useGetPhotos = (
  variables: SearchPhotosVariables,
  skip?: boolean,
) => {
  return useQuery<SearchPhotosResult, SearchPhotosVariables>(SEARCH_PHOTOS, {
    variables,
    skip,
  });
};

export const GET_PHOTOS_COUNT = gql`
  query GetPhotosCount($where: PhotoWhereInput) {
    photoCount: searchPhotosCount(where: $where)
  }
`;

export const useGetPhotosCount = (
  variables: GetPhotosCountVariables,
  skip?: boolean,
) => {
  return useQuery<GetPhotosCount, GetPhotosCountVariables>(GET_PHOTOS_COUNT, {
    variables,
    skip,
  });
};
