import { IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import React, { FC } from 'react';
import { TopicLink } from '../../../components/Link/TopicLink';
import { paths, replaceParams } from '../../../routing';
import { getColorByStatus } from '../../../utils/rawArticleStatus';
import {
  GetPrintHeadingById_printHeading_assignments,
  GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments,
} from '../../../__generated__/queries-topic';
import { useStyles } from './styles';

interface SubjectAutoItemProps {
  assignment:
    | GetPrintHeadingById_printHeading_assignments
    | GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments;
  isEmpty?: boolean;
}

export const SubjectAutoItem: FC<SubjectAutoItemProps> = ({
  assignment,
  isEmpty,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.paperWrapper}>
      <Paper elevation={4} className={classes.paper}>
        <Typography className={classes.title} variant="subtitle1">
          {assignment.subject.title}
        </Typography>
        <div className={classes.auto}>
          {!isEmpty && (
            <TopicLink
              to={{
                pathname: replaceParams(paths.ARTICLE_AUTO_EDIT, {
                  id: assignment?.rawArticle?.id || '',
                }),
              }}
            >
              <IconButton
                className={classes.assignmentButton}
                disabled={!assignment?.rawArticle}
                size="large"
              >
                <BrightnessAutoIcon
                  style={{
                    color: getColorByStatus(
                      assignment?.rawArticle?.lastVersion?.status,
                    ),
                  }}
                />
              </IconButton>
            </TopicLink>
          )}
        </div>
      </Paper>
    </div>
  );
};
