import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
  MetadataUndersubjectWhereInput,
  GetUnderSubjects,
  GetUnderSubjectsVariables,
  MetadataUndersubjectOrderByInput,
  GetUnderSubjects_metadataUndersubjectList,
} from '__generated__/queries-photo';

export const GET_UNDER_SUBJECTS = gql`
  query GetUnderSubjects(
    $where: MetadataUndersubjectWhereInput!
    $first: Int!
    $orderBy: [MetadataUndersubjectOrderByInput!]
  ) {
    metadataUndersubjectList(where: $where, first: $first, orderBy: $orderBy) {
      id
      value
    }
  }
`;

export const useGetUnderSubjects = (
  where: MetadataUndersubjectWhereInput,
  skip?: boolean,
) => {
  const { data, ...rest } = useQuery<
    GetUnderSubjects,
    GetUnderSubjectsVariables
  >(GET_UNDER_SUBJECTS, {
    variables: {
      first: 100000,
      where,
      orderBy: [MetadataUndersubjectOrderByInput.value_ASC],
    },
    notifyOnNetworkStatusChange: true,
    skip,
  });

  const metadatas = useMemo(
    () =>
      data?.metadataUndersubjectList
        ?.filter(({ value }) => !!value)
        .reduce((prev, photoMetadata) => {
          const index = prev.findIndex(
            ({ value }) => photoMetadata.value === value,
          );
          if (index > -1) return prev;
          return [...prev, { ...photoMetadata }];
        }, [] as GetUnderSubjects_metadataUndersubjectList[]),
    [data?.metadataUndersubjectList],
  );

  return useMemo(() => ({ metadatas, ...rest }), [metadatas, rest]);
};
