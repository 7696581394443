import { gql, useQuery } from '@apollo/client';
import { ARCHIVE_FRAGMENT } from 'apollo/fragments/archive.photo.graphql';
import { GetArchives, GetArchivesVariables } from '__generated__/queries-photo';

export const GET_ARCHIVES = gql`
  query GetArchives(
    $where: ArchiveWhereInput
    $first: Int!
    $orderBy: [ArchiveOrderByInput!]
    $skip: Int
  ) {
    archives(where: $where, first: $first, orderBy: $orderBy, skip: $skip) {
      ...Archive
    }
  }
  ${ARCHIVE_FRAGMENT}
`;

export const useGetArchives = (variables: GetArchivesVariables) => {
  return useQuery<GetArchives, GetArchivesVariables>(GET_ARCHIVES, {
    variables,
    fetchPolicy: 'cache-and-network',
  });
};
