import { SearchPhotos_searchPhotos } from '__generated__/queries-photo';

export enum PreviewSize {
  SM = 400,
  XL = 2400,
}

export const getPreview = (
  photo: Pick<SearchPhotos_searchPhotos, 'previews'> | undefined,
  size: PreviewSize = PreviewSize.SM,
) =>
  photo?.previews?.find((preview) => preview.size === size)?.href || undefined;
