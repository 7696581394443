import React, { createContext, FC, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useGetPrintHeadingById } from '../../apollo/queries/printHeading.topic.graphql';
import { AppToolbar } from '../../components/AppToolbar';
import { CircularLoader } from '../../components/Loader/Circular';
import { useStyles } from '../../pages/MultiSubjectAuto/styles';
import { paths, replaceParams } from '../../routing';
import {
  GetPrintHeadingById_printHeading_assignments,
  GetPrintHeadingById_printHeading_printTemplates,
  GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates,
} from '../../__generated__/queries-topic';
import { MultiSubjectAutoForm } from './Form';
import { MultiSubjectHeader } from './Header';

const localShowPreview = localStorage.getItem('showAutoPreview');

interface MatchParams {
  printHeadingId: string;
  originId?: string;
}

interface ContextProps {
  assignments: GetPrintHeadingById_printHeading_assignments[];
  setAssignments: React.Dispatch<
    React.SetStateAction<GetPrintHeadingById_printHeading_assignments[]>
  >;
  vacantAssignments: GetPrintHeadingById_printHeading_assignments[];
  setVacantAssignments: React.Dispatch<
    React.SetStateAction<GetPrintHeadingById_printHeading_assignments[]>
  >;
  printTemplates: GetPrintHeadingById_printHeading_printTemplates[];
  setPrintTemplates: React.Dispatch<
    React.SetStateAction<GetPrintHeadingById_printHeading_printTemplates[]>
  >;
  selectedPrintTemplateId: string;
  setSelectedPrintTemplateId: React.Dispatch<React.SetStateAction<string>>;
  articleTemplates: GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates[];
  setArticleTemplates: React.Dispatch<
    React.SetStateAction<
      GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates[]
    >
  >;
  hoveredArticleTopic: string;
  setHoveredArticleTopic: React.Dispatch<React.SetStateAction<string>>;
  showPreview: boolean;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  togglePreview: () => void;
  setPreviewResponseTime: React.Dispatch<React.SetStateAction<string>>;
  previewResponseTime: string;
  isPreviewLoading: boolean;
  setIsPreviewLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getAssignment: (
    id: string,
  ) => GetPrintHeadingById_printHeading_assignments | undefined;
  getArticleTemplate: (
    id: string,
  ) =>
    | GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates
    | undefined;
}

export const MultiSubjectAutoContext = createContext({} as ContextProps);

export const MultiSubjectAutoWrapper: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();
  const subjectSourceId = match.params.originId;
  const { printHeadingId } = match.params;
  const { loading, data, refetch } = useGetPrintHeadingById({
    id: printHeadingId,
  });
  const [assignments, setAssignments] = useState<
    GetPrintHeadingById_printHeading_assignments[]
  >([]);
  const [vacantAssignments, setVacantAssignments] = useState<
    GetPrintHeadingById_printHeading_assignments[]
  >([]);
  const [printTemplates, setPrintTemplates] = useState<
    GetPrintHeadingById_printHeading_printTemplates[]
  >([]);
  const [selectedPrintTemplateId, setSelectedPrintTemplateId] = useState('');
  const [articleTemplates, setArticleTemplates] = useState<
    GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates[]
  >([]);
  const [hoveredArticleTopic, setHoveredArticleTopic] = useState<string>('');
  const [showPreview, setShowPreview] = useState(
    !(localShowPreview === 'false'),
  );
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [previewResponseTime, setPreviewResponseTime] = useState('0');

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!loading) {
      setAssignments(data?.printHeading?.assignments || []);
      const templates = data?.printHeading?.printTemplates || [];
      if (!templates.length)
        return history.push({ pathname: paths.SUBJECT_LIST });
      const template = templates.find((item) => item.active);
      setPrintTemplates(templates);
      setSelectedPrintTemplateId(template?.id || templates[0].id);
    }
  }, [loading, data, history]);

  useEffect(() => {
    setArticleTemplates(
      printTemplates.find((item) => item.id === selectedPrintTemplateId)
        ?.printTemplate?.articleTemplates || [],
    );
  }, [selectedPrintTemplateId, printTemplates]);

  useEffect(() => {
    const articleTemplateIds = articleTemplates.map(
      (articleTemplate) => articleTemplate.id,
    );
    setVacantAssignments(
      (data?.printHeading?.assignments || []).filter(
        (assignment) =>
          !articleTemplateIds.includes(assignment.articleTemplate?.id || ''),
      ),
    );
  }, [articleTemplates, data]);

  const params: ContextProps = {
    assignments,
    setAssignments,
    vacantAssignments,
    setVacantAssignments,
    printTemplates,
    setPrintTemplates,
    selectedPrintTemplateId,
    setSelectedPrintTemplateId,
    articleTemplates,
    setArticleTemplates,
    hoveredArticleTopic,
    setHoveredArticleTopic,
    showPreview,
    setShowPreview,
    togglePreview: () => {
      setShowPreview(!showPreview);
      localStorage.setItem('showAutoPreview', String(!showPreview));
    },
    previewResponseTime,
    setPreviewResponseTime,
    isPreviewLoading,
    setIsPreviewLoading,
    getAssignment: (id: string) => assignments.find((item) => item.id === id),
    getArticleTemplate: (id: string) =>
      articleTemplates.find((item) => item.id === id),
  };

  return loading ? (
    <CircularLoader />
  ) : (
    <MultiSubjectAutoContext.Provider value={{ ...params }}>
      <AppToolbar
        childrenJustifyContent="flex-end"
        className={classes.appToolbarChildren}
        title={`Multi-Suject: ${data?.printHeading?.title}`}
        editedAt={new Date(data?.printHeading?.createdAt || '')}
        previousRoute={
          subjectSourceId
            ? {
                pathname: replaceParams(paths.SUBJECT_EDIT, {
                  id: subjectSourceId,
                }),
              }
            : { pathname: paths.SUBJECT_LIST }
        }
      />
      <MultiSubjectHeader />
      <MultiSubjectAutoForm />
    </MultiSubjectAutoContext.Provider>
  );
};
