import { Dialog } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { GetRawArticleMajorVersions_rawArticle_majorVersions } from '../../../__generated__/queries-topic';
import {
  AssignmentFormModel,
  getEmptyAssignment,
} from '../Form/dataTransformer';
import { DuplicateAssignmentList } from './DuplicateAssignmentList';
import { DuplicateNewAssignment } from './DuplicateNewAssignment';
import { DuplicateVersionList } from './DuplicateVersionList';
import { useStyles } from './styles';

interface DuplicateDialogProps {
  rawArticleId: string;
  assignmentOriginIndex: number;
  subjectId: string;
  assignments: AssignmentFormModel[];
  refetchSubject?: () => void;
  closeDialog: (e: React.SyntheticEvent) => void;
  handleNewAssignment: (e: React.SyntheticEvent) => void;
  newAssignmentIndex: number;
  notificateSuccess: () => void;
}

interface DuplicateDialogState {
  versionSelected: GetRawArticleMajorVersions_rawArticle_majorVersions | null;
  isDuplicatingForWeb: boolean;
  assignment: AssignmentFormModel;
  dialogStep: number;
  assignmentIndex: number;
}

export const DuplicateDialog: FC<DuplicateDialogProps> = ({
  rawArticleId,
  closeDialog,
  assignments,
  handleNewAssignment,
  refetchSubject,
  subjectId,
  notificateSuccess,
  assignmentOriginIndex,
  newAssignmentIndex,
}) => {
  const classes = useStyles();
  const [state, setState] = useState<DuplicateDialogState>({
    versionSelected: null,
    isDuplicatingForWeb: false,
    assignment: getEmptyAssignment(subjectId),
    dialogStep: 0,
    assignmentIndex: -1,
  });

  useEffect(() => {
    if (newAssignmentIndex !== state.assignmentIndex) {
      setState((prev) => ({
        ...prev,
        assignmentIndex: newAssignmentIndex,
        dialogStep: 2,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAssignmentIndex, setState]);

  return (
    <Dialog
      open
      fullWidth
      data-cy="subject-form-duplicate-dialog"
      maxWidth={state.dialogStep === 0 ? 'sm' : 'md'}
      onClose={closeDialog}
      classes={{ paperFullWidth: classes.dialog }}
    >
      {state.dialogStep === 0 && (
        <DuplicateVersionList
          rawArticleId={rawArticleId}
          versionSelected={state.versionSelected}
          handleClick={(versionSelected) =>
            setState((prev) => ({ ...prev, versionSelected }))
          }
          closeDialog={closeDialog}
          updateStep={() => {
            setState((prev) => ({ ...prev, dialogStep: 1 }));
          }}
        />
      )}
      {state.dialogStep === 1 && (
        <DuplicateAssignmentList
          assignmentOriginIndex={assignmentOriginIndex}
          assignments={assignments}
          selectedAssignment={state.assignment}
          refetchSubject={refetchSubject}
          closeDialog={closeDialog}
          handleNewAssignment={handleNewAssignment}
          subjectId={subjectId}
          versionSelected={state.versionSelected}
          onAssignmentClick={(assignment) => () =>
            setState((prev) => ({ ...prev, assignment }))}
          notificateSuccess={notificateSuccess}
        />
      )}
      {state.dialogStep === 2 && (
        <DuplicateNewAssignment
          assignmentOriginIndex={assignmentOriginIndex}
          refetchSubject={refetchSubject}
          closeDialog={closeDialog}
          subjectId={subjectId}
          versionSelected={state.versionSelected}
          rawArticleId={rawArticleId}
          assignmentIndex={state.assignmentIndex}
          notificateSuccess={notificateSuccess}
        />
      )}
    </Dialog>
  );
};
