import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/NoteAdd';
import React, { useState } from 'react';
import { GetPrintHeading_printHeading_printTemplates } from '../../../__generated__/queries-topic';
import { AddPrintTemplateDialog } from '../AddDialog';
import { EmptyView } from '../EmptyView';
import { ErrorView } from '../ErrorView';
import { TemplateItem } from '../TemplateItem';
import { ViewVariant } from '../utils';
import { useStyles } from './styles';

interface TemplateListProps {
  loading: boolean;
  printHeadingId: string;
  printHeadingPrintTemplates?:
    | GetPrintHeading_printHeading_printTemplates[]
    | null;
  count?: number;
  viewVariant?: ViewVariant;
}

export const TemplateList: React.FC<TemplateListProps> = ({
  loading,
  printHeadingId,
  printHeadingPrintTemplates,
  count,
  viewVariant = 'grid',
}) => {
  const classes = useStyles();
  const [isAddDialogOpen, setIsAddDialgOpen] = useState(false);

  if (!loading && !printHeadingPrintTemplates) {
    return (
      <ErrorView>
        Une erreur s'est produite lors du chargement de la liste des gabarits
      </ErrorView>
    );
  }

  const isListView = viewVariant === 'list';

  return (
    <>
      {printHeadingPrintTemplates && (
        <>
          {!loading && printHeadingPrintTemplates.length === 0 ? (
            <EmptyView>
              Aucun gabarit trouvé, pour en ajouter un, veuillez appuyer sur le
              bouton Ajouter et suivez les instructions
            </EmptyView>
          ) : (
            <Grid container spacing={2}>
              {printHeadingPrintTemplates.map((tmpl) => (
                <Grid
                  key={tmpl.id}
                  item
                  xs={12}
                  md={isListView ? 12 : 6}
                  lg={isListView ? 12 : 3}
                >
                  <TemplateItem
                    printHeadingPrintTemplate={tmpl}
                    viewVariant={viewVariant}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}

      <Fab
        color="primary"
        aria-label="add template"
        className={classes.addBtn}
        onClick={() => setIsAddDialgOpen(true)}
      >
        <AddIcon />
      </Fab>
      {isAddDialogOpen && (
        <AddPrintTemplateDialog
          onClose={() => setIsAddDialgOpen(false)}
          printHeadingId={printHeadingId}
          isFirstTemplate={!count}
        />
      )}
    </>
  );
};
