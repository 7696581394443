import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import React, { useState } from 'react';
import { useDetachPrintTemplate } from '../../../apollo/queries/printTemplate.topic.graphql';
import {
  CircularLoader,
  LoaderSizes,
} from '../../../components/Loader/Circular';
import { GetPrintHeading_printHeading_printTemplates_printTemplate } from '../../../__generated__/queries-topic';
import { useCommonStyles } from '../common.styles';
import { CustomDialog } from '../Dialog';

interface DetachPrintTemplateDialogProps {
  open?: boolean;
  onClose: () => void;
  printHeadingPrintTemplateId: string;
  printTemplate: GetPrintHeading_printHeading_printTemplates_printTemplate;
}

export const DetachPrintTemplateDialog: React.FC<DetachPrintTemplateDialogProps> = ({
  open = false,
  onClose,
  printHeadingPrintTemplateId,
  printTemplate: { label },
}) => {
  const commonClasses = useCommonStyles();
  const { pushNotification } = useNotification();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const detachPrintTemplate = useDetachPrintTemplate(
    printHeadingPrintTemplateId,
  );

  const handleDetachPrintTemplate = async () => {
    setIsSubmiting(true);
    const { errors } = await detachPrintTemplate();
    setIsSubmiting(false);
    if (errors?.length) {
      pushNotification({
        message: 'Un problème est survenu lors de la suppression du gabarit',
        type: NotificationTypeEnum.error,
      });
    } else {
      pushNotification({
        message: `Le gabarit ${label} a été supprimé avec succès.`,
        type: NotificationTypeEnum.success,
      });
      onClose();
    }
    setIsSubmiting(false);
  };

  return (
    <CustomDialog
      open={open}
      aria-labelledby="dialog-delete-printTemplate"
      maxWidth="md"
    >
      <DialogTitle id="dialog-delete-printTemplate">
        Supprimer Gabarit
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Êtes-vous sûr de vouloir supprimer le gabarit {label} ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Button
          className={commonClasses.buttonWithLeftIcon}
          onClick={handleDetachPrintTemplate}
          color="primary"
          autoFocus
        >
          {isSubmiting && <CircularLoader size={LoaderSizes.xsmall} />}Supprimer
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};
