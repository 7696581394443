import { GetAssignmentByRawArticleId } from '../../../__generated__/queries-topic';
import { AssignmentFormModel } from '../../SubjectForm/Form/dataTransformer';

type RawArticleAssignmentFormModel = Pick<
  AssignmentFormModel,
  'id' | 'supportId' | 'printIssueId' | 'printHeadingId'
>;

export const apiToForm = (
  assignmentData?: GetAssignmentByRawArticleId,
): RawArticleAssignmentFormModel | false => {
  if (!assignmentData || !assignmentData.assignment) {
    return false;
  }
  return {
    id: assignmentData.assignment.id,
    supportId:
      (assignmentData.assignment.printPublication &&
        `print:${assignmentData.assignment.printPublication.id}`) ||
      (assignmentData.assignment.printIssue &&
        `print:${assignmentData.assignment.printIssue.printPublication.id}`) ||
      (assignmentData.assignment.printHeading &&
        `print:${assignmentData.assignment.printHeading.printIssue.printPublication.id}`) ||
      '',
    printIssueId:
      (assignmentData.assignment.printIssue &&
        assignmentData.assignment.printIssue.id) ||
      (assignmentData.assignment.printHeading &&
        assignmentData.assignment.printHeading.printIssue.id) ||
      '',
    printHeadingId:
      (assignmentData.assignment.printHeading &&
        assignmentData.assignment.printHeading.id) ||
      '',
  };
};

export const formToApi = (rawArticleId: string, printHeadingId: string) => {
  return {
    variables: {
      id: rawArticleId,
      data: {
        printPublication: null,
        printHeading: {
          connect: { id: printHeadingId },
        },
        printIssue: null,
      },
    },
  };
};
