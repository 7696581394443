import { gql, useApolloClient } from '@apollo/client';
import { GetUsers, GetUsersVariables } from '../../__generated__/queries-auth';

export const GET_USERS = gql`
  query GetUsers($first: Int = 1000, $skip: Int = 0, $where: UserWhereInput) {
    users(first: $first, skip: $skip, where: $where) {
      id
      name
      createdAt
      email
      roles
      googleId
    }
    userCount(where: $where)
  }
`;

export const useUsersGetter = () => {
  const client = useApolloClient();

  return async (name: string) => {
    const { data, errors } = await client.query<GetUsers, GetUsersVariables>({
      query: GET_USERS,
      variables: { where: { name_contains: name } },
    });

    if (errors?.length) {
      throw errors[0];
    }
    return data?.users;
  };
};
