import { PlaylistAddCheck, Upload } from '@mui/icons-material';
import { Link } from '@mui/material';
import {
  AppBarAction,
  EditAppBar,
  SearchBar,
  SearchParams,
  useDialog,
  QueueStatus,
  useTaskQueueEvent,
  TaskQueueEventName,
  TaskQueue,
  TaskQueueButton,
} from '@prismamedia/one-components';
import { MediaStatusDialog } from 'components/MediaStatusDialog';
import { Dispatch, FC, SetStateAction, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { paths, replaceParams } from 'routing';
import { preserveSubjectListSearch } from 'utils/getUrlParams';
import { GetSubject_subject } from '__generated__/queries-topic';
import { ImportPhotosDialog } from '../ImportPhotosDialog';
import { ImagesTaskQueue } from '../../../queues';

interface AppBarProps {
  searchParams: SearchParams;
  setSearchParams: Dispatch<SetStateAction<SearchParams>>;
  subject: GetSubject_subject | undefined | null;
}

export const AppBar: FC<AppBarProps> = ({
  searchParams,
  setSearchParams,
  subject,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { openDialog } = useDialog();
  const [displayQueueProgress, setDisplayQueueProgress] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const onStatusChange = useTaskQueueEvent(ImagesTaskQueue);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const handleChange = (q: TaskQueue) => {
      clearTimeout(timer);

      if (q.Status === QueueStatus.IDLE && q.Stats.FAILED === 0) {
        timer = setTimeout(() => setDisplayQueueProgress(false), 2000);
      } else {
        setDisplayQueueProgress(true);
      }
    };

    const unsub = onStatusChange(handleChange, TaskQueueEventName.StatusChange);
    return () => {
      clearTimeout(timer);
      unsub();
    };
  }, [onStatusChange]);

  const togglePopover = () => {
    setPopoverOpen((open) => !open);
  };

  const appBarActions: AppBarAction[] = subject
    ? [
        {
          id: 'upload',
          label: 'Importer',
          icon: <Upload />,
          onClick: () => openDialog(<ImportPhotosDialog subject={subject} />),
        },
        {
          id: 'status',
          label: 'Statut',
          icon: <PlaylistAddCheck />,
          onClick: () =>
            openDialog(<MediaStatusDialog subjectId={subject?.id} />),
        },
      ]
    : [];

  return (
    <>
      <EditAppBar
        title={
          <Link
            variant="h6"
            onClick={() =>
              history.push({
                pathname: replaceParams(paths.SUBJECT_EDIT, {
                  id: subject?.id,
                }),
              })
            }
            color="inherit"
            component="button"
            align="left"
            underline="none"
          >
            {subject?.title}
          </Link>
        }
        onNavigateBack={() =>
          history.push({
            pathname: replaceParams(paths.SUBJECT_LIST, {}),
            search: preserveSubjectListSearch(location.search),
          })
        }
        searchBar={
          <SearchBar
            placeholder="Rechercher dans les images"
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        }
        additionalElement={
          displayQueueProgress || popoverOpen ? (
            <TaskQueueButton
              queue={ImagesTaskQueue}
              onClick={togglePopover}
              sx={{
                color: (t) => t.palette.background.default,
                '& .MuiCircularProgress-root': {
                  color: (t) => t.palette.background.default,
                },
                '& .MuiCircularProgress-root:first-of-type': {
                  filter: `brightness(40%)`,
                },
              }}
              popoverProps={{
                open: popoverOpen,
                onClose: () => setPopoverOpen(false),
              }}
            />
          ) : undefined
        }
        actions={appBarActions}
      />
    </>
  );
};
