/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EmailArticle
// ====================================================

export interface EmailArticle_emailRawArticle {
  __typename: "EmailRawArticleResultType";
  success: boolean;
}

export interface EmailArticle {
  emailRawArticle: EmailArticle_emailRawArticle;
}

export interface EmailArticleVariables {
  rawArticle: string;
  recipients: string[];
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EmailSubject
// ====================================================

export interface EmailSubject_emailSubject {
  __typename: "EmailSubjectResultType";
  success: boolean;
}

export interface EmailSubject {
  emailSubject: EmailSubject_emailSubject;
}

export interface EmailSubjectVariables {
  subject: string;
  recipients: string[];
  comment?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExportToCentshare
// ====================================================

export interface ExportToCentshare_exportToCentshare {
  __typename: "ExportToCenshareResultType";
  message: string;
}

export interface ExportToCentshare {
  /**
   * Photo photos to censhare
   */
  exportToCentshare: ExportToCentshare_exportToCentshare;
}

export interface ExportToCentshareVariables {
  assignment: string;
  issue: string;
  subject: string;
  brand: string;
  photos?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExportPrintIssueToCenshare
// ====================================================

export interface ExportPrintIssueToCenshare_exportPrintIssueToCenshare {
  __typename: "ExportPrintIssueToCenshareResultType";
  message: string;
}

export interface ExportPrintIssueToCenshare {
  /**
   * Photo print issue headings to censhare
   */
  exportPrintIssueToCenshare: ExportPrintIssueToCenshare_exportPrintIssueToCenshare;
}

export interface ExportPrintIssueToCenshareVariables {
  printIssueId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAssignmentPageNumber
// ====================================================

export interface updateAssignmentPageNumber_updateAssignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
}

export interface updateAssignmentPageNumber {
  /**
   * Updates one "Assignment", throws an error if it does not exists
   */
  updateAssignment: updateAssignmentPageNumber_updateAssignment;
}

export interface updateAssignmentPageNumberVariables {
  assignmentId: string;
  pageNumber: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAssignment
// ====================================================

export interface UpdateAssignment_updateAssignment_rawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface UpdateAssignment_updateAssignment_rawArticle_versions {
  __typename: "RawArticleVersion";
  allowedStatuses: RawArticleStatus[];
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  /**
   * An array of left over Draft content
   */
  leftOver: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: UpdateAssignment_updateAssignment_rawArticle_versions_editor | null;
  textIsReadOnly: boolean;
}

export interface UpdateAssignment_updateAssignment_rawArticle_versionList_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface UpdateAssignment_updateAssignment_rawArticle_versionList {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  status: RawArticleStatus;
  /**
   * The user assigned to the version
   */
  editorId: string;
  editor: UpdateAssignment_updateAssignment_rawArticle_versionList_editor | null;
}

export interface UpdateAssignment_updateAssignment_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: UpdateAssignment_updateAssignment_rawArticle_versions[];
  versionList: UpdateAssignment_updateAssignment_rawArticle_versionList[];
}

export interface UpdateAssignment_updateAssignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  rawArticle: UpdateAssignment_updateAssignment_rawArticle | null;
}

export interface UpdateAssignment {
  /**
   * Updates one "Assignment", throws an error if it does not exists
   */
  updateAssignment: UpdateAssignment_updateAssignment;
}

export interface UpdateAssignmentVariables {
  where: AssignmentUniqueFilterInput;
  data: AssignmentUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAssignment
// ====================================================

export interface GetAssignment_assignment_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetAssignment_assignment_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetAssignment_assignment_subject_unit {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetAssignment_assignment_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
  unit: GetAssignment_assignment_subject_unit;
}

export interface GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields_page_coordinates;
}

export interface GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  enabled: boolean | null;
  data: string;
  typeVariation: string;
  paragraphStyles: GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields_paragraphStyles[];
  characterStyles: GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields_characterStyles[];
  coordinates: GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields_coordinates;
  page: GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields_page;
}

export interface GetAssignment_assignment_printHeading_printTemplates_printTemplate_createdBy {
  __typename: "User";
  name: string;
}

export interface GetAssignment_assignment_printHeading_printTemplates_printTemplate_updatedBy {
  __typename: "User";
  name: string;
}

export interface GetAssignment_assignment_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * associated S3 object id
   */
  S3ObjectId: string;
  /**
   * template label
   */
  label: string;
  /**
   * InDesign file name
   */
  filename: string;
  /**
   * template type
   */
  type: TemplateType | null;
  /**
   * InDesign file size
   */
  filesize: number | null;
  /**
   * Template page count
   */
  pageCount: number | null;
  /**
   * KAMI extension version
   */
  extVersion: string | null;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (GetAssignment_assignment_printHeading_printTemplates_printTemplate_fields | null)[];
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  createdBy: GetAssignment_assignment_printHeading_printTemplates_printTemplate_createdBy | null;
  updatedBy: GetAssignment_assignment_printHeading_printTemplates_printTemplate_updatedBy | null;
}

export interface GetAssignment_assignment_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  printTemplate: GetAssignment_assignment_printHeading_printTemplates_printTemplate;
}

export interface GetAssignment_assignment_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetAssignment_assignment_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: GetAssignment_assignment_printHeading_printIssue_printPublication;
}

export interface GetAssignment_assignment_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  autoPrototype: boolean | null;
  printTemplates: GetAssignment_assignment_printHeading_printTemplates[];
  printIssue: GetAssignment_assignment_printHeading_printIssue;
}

export interface GetAssignment_assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printTemplate: GetAssignment_assignment_printTemplate | null;
  printPublication: GetAssignment_assignment_printPublication | null;
  subject: GetAssignment_assignment_subject;
  printHeading: GetAssignment_assignment_printHeading | null;
}

export interface GetAssignment {
  /**
   * Retrieves one "Assignment", throws an error if it does not exist
   */
  assignment: GetAssignment_assignment;
}

export interface GetAssignmentVariables {
  assignmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintHeadingByPrintIssue
// ====================================================

export interface GetPrintHeadingByPrintIssue_printIssue_printHeadings_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * template type
   */
  type: TemplateType | null;
}

export interface GetPrintHeadingByPrintIssue_printIssue_printHeadings_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  printTemplate: GetPrintHeadingByPrintIssue_printIssue_printHeadings_printTemplates_printTemplate;
}

export interface GetPrintHeadingByPrintIssue_printIssue_printHeadings {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  autoPrototype: boolean | null;
  printTemplates: GetPrintHeadingByPrintIssue_printIssue_printHeadings_printTemplates[];
}

export interface GetPrintHeadingByPrintIssue_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printHeadings: GetPrintHeadingByPrintIssue_printIssue_printHeadings[];
}

export interface GetPrintHeadingByPrintIssue {
  /**
   * Retrieves one "PrintIssue", throws an error if it does not exist
   */
  printIssue: GetPrintHeadingByPrintIssue_printIssue;
}

export interface GetPrintHeadingByPrintIssueVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintIssuesByPrintPublication
// ====================================================

export interface GetPrintIssuesByPrintPublication_printPublication_printIssues {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetPrintIssuesByPrintPublication_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printIssues: GetPrintIssuesByPrintPublication_printPublication_printIssues[];
}

export interface GetPrintIssuesByPrintPublication {
  /**
   * Retrieves one "PrintPublication", throws an error if it does not exist
   */
  printPublication: GetPrintIssuesByPrintPublication_printPublication;
}

export interface GetPrintIssuesByPrintPublicationVariables {
  id: string;
  excludeIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintPublicationByUnit
// ====================================================

export interface GetPrintPublicationByUnit_units_brands_printPublications {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetPrintPublicationByUnit_units_brands_websites {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
}

export interface GetPrintPublicationByUnit_units_brands {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublications: GetPrintPublicationByUnit_units_brands_printPublications[];
  websites: GetPrintPublicationByUnit_units_brands_websites[];
}

export interface GetPrintPublicationByUnit_units {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
  brands: GetPrintPublicationByUnit_units_brands[];
}

export interface GetPrintPublicationByUnit {
  /**
   * Retrieves a list of "Units"
   */
  units: GetPrintPublicationByUnit_units[];
}

export interface GetPrintPublicationByUnitVariables {
  unitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintHeadingById
// ====================================================

export interface GetPrintHeadingById_printHeading_assignments_articleTemplate {
  __typename: "ArticleTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetPrintHeadingById_printHeading_assignments_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetPrintHeadingById_printHeading_assignments_rawArticle_lastVersion {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  /**
   * An array of left over Draft content
   */
  leftOver: string | null;
  status: RawArticleStatus;
  textIsReadOnly: boolean;
}

export interface GetPrintHeadingById_printHeading_assignments_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  lastVersion: GetPrintHeadingById_printHeading_assignments_rawArticle_lastVersion | null;
}

export interface GetPrintHeadingById_printHeading_assignments {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  articleTemplate: GetPrintHeadingById_printHeading_assignments_articleTemplate | null;
  subject: GetPrintHeadingById_printHeading_assignments_subject;
  rawArticle: GetPrintHeadingById_printHeading_assignments_rawArticle | null;
}

export interface GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_fields_page_coordinates;
}

export interface GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  data: string;
  enabled: boolean | null;
  page: GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_fields_page;
}

export interface GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments_rawArticle_lastVersion {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  status: RawArticleStatus;
  body: string | null;
}

export interface GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  lastVersion: GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments_rawArticle_lastVersion | null;
}

export interface GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  subject: GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments_subject;
  rawArticle: GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments_rawArticle | null;
}

export interface GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates {
  __typename: "ArticleTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * The article template label
   */
  label: string | null;
  /**
   * The raw datas
   */
  block: string | null;
  fields: (GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_fields | null)[];
  assignments: GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates_assignments[];
}

export interface GetPrintHeadingById_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * template label
   */
  label: string;
  /**
   * template type
   */
  type: TemplateType | null;
  articleTemplates: GetPrintHeadingById_printHeading_printTemplates_printTemplate_articleTemplates[];
}

export interface GetPrintHeadingById_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  printTemplate: GetPrintHeadingById_printHeading_printTemplates_printTemplate;
}

export interface GetPrintHeadingById_printHeading {
  __typename: "PrintHeading";
  title: string;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  assignments: GetPrintHeadingById_printHeading_assignments[];
  printTemplates: GetPrintHeadingById_printHeading_printTemplates[];
}

export interface GetPrintHeadingById {
  /**
   * Retrieves one "PrintHeading", throws an error if it does not exist
   */
  printHeading: GetPrintHeadingById_printHeading;
}

export interface GetPrintHeadingByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePrintTemplate
// ====================================================

export interface CreatePrintTemplate_createPrintTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface CreatePrintTemplate_createPrintTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface CreatePrintTemplate_createPrintTemplate_fields_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface CreatePrintTemplate_createPrintTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface CreatePrintTemplate_createPrintTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: CreatePrintTemplate_createPrintTemplate_fields_page_coordinates;
}

export interface CreatePrintTemplate_createPrintTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  enabled: boolean | null;
  data: string;
  typeVariation: string;
  paragraphStyles: CreatePrintTemplate_createPrintTemplate_fields_paragraphStyles[];
  characterStyles: CreatePrintTemplate_createPrintTemplate_fields_characterStyles[];
  coordinates: CreatePrintTemplate_createPrintTemplate_fields_coordinates;
  page: CreatePrintTemplate_createPrintTemplate_fields_page;
}

export interface CreatePrintTemplate_createPrintTemplate_printHeadings_printHeading_printIssue_printPublication_brand {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface CreatePrintTemplate_createPrintTemplate_printHeadings_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
  brand: CreatePrintTemplate_createPrintTemplate_printHeadings_printHeading_printIssue_printPublication_brand;
}

export interface CreatePrintTemplate_createPrintTemplate_printHeadings_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: CreatePrintTemplate_createPrintTemplate_printHeadings_printHeading_printIssue_printPublication;
}

export interface CreatePrintTemplate_createPrintTemplate_printHeadings_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printIssue: CreatePrintTemplate_createPrintTemplate_printHeadings_printHeading_printIssue;
}

export interface CreatePrintTemplate_createPrintTemplate_printHeadings {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  printHeading: CreatePrintTemplate_createPrintTemplate_printHeadings_printHeading;
}

export interface CreatePrintTemplate_createPrintTemplate_createdBy {
  __typename: "User";
  name: string;
}

export interface CreatePrintTemplate_createPrintTemplate_updatedBy {
  __typename: "User";
  name: string;
}

export interface CreatePrintTemplate_createPrintTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * associated S3 object id
   */
  S3ObjectId: string;
  /**
   * template label
   */
  label: string;
  /**
   * InDesign file name
   */
  filename: string;
  /**
   * template type
   */
  type: TemplateType | null;
  /**
   * InDesign file size
   */
  filesize: number | null;
  /**
   * Template page count
   */
  pageCount: number | null;
  /**
   * KAMI extension version
   */
  extVersion: string | null;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (CreatePrintTemplate_createPrintTemplate_fields | null)[];
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  printHeadings: CreatePrintTemplate_createPrintTemplate_printHeadings[];
  createdBy: CreatePrintTemplate_createPrintTemplate_createdBy | null;
  updatedBy: CreatePrintTemplate_createPrintTemplate_updatedBy | null;
}

export interface CreatePrintTemplate {
  /**
   * Creates one "PrintTemplate", throws an error if it already exists
   */
  createPrintTemplate: CreatePrintTemplate_createPrintTemplate;
}

export interface CreatePrintTemplateVariables {
  data: PrintTemplateCreationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePrintTemplate
// ====================================================

export interface UpdatePrintTemplate_updatePrintTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface UpdatePrintTemplate_updatePrintTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface UpdatePrintTemplate_updatePrintTemplate_fields_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface UpdatePrintTemplate_updatePrintTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface UpdatePrintTemplate_updatePrintTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: UpdatePrintTemplate_updatePrintTemplate_fields_page_coordinates;
}

export interface UpdatePrintTemplate_updatePrintTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  enabled: boolean | null;
  data: string;
  typeVariation: string;
  paragraphStyles: UpdatePrintTemplate_updatePrintTemplate_fields_paragraphStyles[];
  characterStyles: UpdatePrintTemplate_updatePrintTemplate_fields_characterStyles[];
  coordinates: UpdatePrintTemplate_updatePrintTemplate_fields_coordinates;
  page: UpdatePrintTemplate_updatePrintTemplate_fields_page;
}

export interface UpdatePrintTemplate_updatePrintTemplate_printHeadings_printHeading_printIssue_printPublication_brand {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface UpdatePrintTemplate_updatePrintTemplate_printHeadings_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
  brand: UpdatePrintTemplate_updatePrintTemplate_printHeadings_printHeading_printIssue_printPublication_brand;
}

export interface UpdatePrintTemplate_updatePrintTemplate_printHeadings_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: UpdatePrintTemplate_updatePrintTemplate_printHeadings_printHeading_printIssue_printPublication;
}

export interface UpdatePrintTemplate_updatePrintTemplate_printHeadings_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printIssue: UpdatePrintTemplate_updatePrintTemplate_printHeadings_printHeading_printIssue;
}

export interface UpdatePrintTemplate_updatePrintTemplate_printHeadings {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  printHeading: UpdatePrintTemplate_updatePrintTemplate_printHeadings_printHeading;
}

export interface UpdatePrintTemplate_updatePrintTemplate_createdBy {
  __typename: "User";
  name: string;
}

export interface UpdatePrintTemplate_updatePrintTemplate_updatedBy {
  __typename: "User";
  name: string;
}

export interface UpdatePrintTemplate_updatePrintTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * associated S3 object id
   */
  S3ObjectId: string;
  /**
   * template label
   */
  label: string;
  /**
   * InDesign file name
   */
  filename: string;
  /**
   * template type
   */
  type: TemplateType | null;
  /**
   * InDesign file size
   */
  filesize: number | null;
  /**
   * Template page count
   */
  pageCount: number | null;
  /**
   * KAMI extension version
   */
  extVersion: string | null;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (UpdatePrintTemplate_updatePrintTemplate_fields | null)[];
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  printHeadings: UpdatePrintTemplate_updatePrintTemplate_printHeadings[];
  createdBy: UpdatePrintTemplate_updatePrintTemplate_createdBy | null;
  updatedBy: UpdatePrintTemplate_updatePrintTemplate_updatedBy | null;
}

export interface UpdatePrintTemplate {
  /**
   * Updates one "PrintTemplate", throws an error if it does not exists
   */
  updatePrintTemplate: UpdatePrintTemplate_updatePrintTemplate;
}

export interface UpdatePrintTemplateVariables {
  data: PrintTemplateUpdateInput;
  where: PrintTemplateUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DetachPrintTemplate
// ====================================================

export interface DetachPrintTemplate_deletePrintHeadingPrintTemplate {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface DetachPrintTemplate {
  /**
   * Deletes one "PrintHeadingPrintTemplate", throws an error if it does not exists
   */
  deletePrintHeadingPrintTemplate: DetachPrintTemplate_deletePrintHeadingPrintTemplate;
}

export interface DetachPrintTemplateVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePrintHeadingPrintTemplate
// ====================================================

export interface UpdatePrintHeadingPrintTemplate_updatePrintHeadingPrintTemplate {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
}

export interface UpdatePrintHeadingPrintTemplate {
  /**
   * Updates one "PrintHeadingPrintTemplate", throws an error if it does not exists
   */
  updatePrintHeadingPrintTemplate: UpdatePrintHeadingPrintTemplate_updatePrintHeadingPrintTemplate;
}

export interface UpdatePrintHeadingPrintTemplateVariables {
  data: PrintHeadingPrintTemplateUpdateInput;
  where: PrintHeadingPrintTemplateUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintHeading
// ====================================================

export interface GetPrintHeading_printHeading_printIssue_printPublication_brand_unit {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetPrintHeading_printHeading_printIssue_printPublication_brand {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
  title: string;
  unit: GetPrintHeading_printHeading_printIssue_printPublication_brand_unit;
}

export interface GetPrintHeading_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
  brand: GetPrintHeading_printHeading_printIssue_printPublication_brand;
}

export interface GetPrintHeading_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: GetPrintHeading_printHeading_printIssue_printPublication;
}

export interface GetPrintHeading_printHeading_printTemplates_printTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetPrintHeading_printHeading_printTemplates_printTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetPrintHeading_printHeading_printTemplates_printTemplate_fields_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface GetPrintHeading_printHeading_printTemplates_printTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface GetPrintHeading_printHeading_printTemplates_printTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: GetPrintHeading_printHeading_printTemplates_printTemplate_fields_page_coordinates;
}

export interface GetPrintHeading_printHeading_printTemplates_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  enabled: boolean | null;
  data: string;
  typeVariation: string;
  paragraphStyles: GetPrintHeading_printHeading_printTemplates_printTemplate_fields_paragraphStyles[];
  characterStyles: GetPrintHeading_printHeading_printTemplates_printTemplate_fields_characterStyles[];
  coordinates: GetPrintHeading_printHeading_printTemplates_printTemplate_fields_coordinates;
  page: GetPrintHeading_printHeading_printTemplates_printTemplate_fields_page;
}

export interface GetPrintHeading_printHeading_printTemplates_printTemplate_createdBy {
  __typename: "User";
  name: string;
}

export interface GetPrintHeading_printHeading_printTemplates_printTemplate_updatedBy {
  __typename: "User";
  name: string;
}

export interface GetPrintHeading_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * associated S3 object id
   */
  S3ObjectId: string;
  /**
   * template label
   */
  label: string;
  /**
   * InDesign file name
   */
  filename: string;
  /**
   * template type
   */
  type: TemplateType | null;
  /**
   * InDesign file size
   */
  filesize: number | null;
  /**
   * Template page count
   */
  pageCount: number | null;
  /**
   * KAMI extension version
   */
  extVersion: string | null;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (GetPrintHeading_printHeading_printTemplates_printTemplate_fields | null)[];
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  createdBy: GetPrintHeading_printHeading_printTemplates_printTemplate_createdBy | null;
  updatedBy: GetPrintHeading_printHeading_printTemplates_printTemplate_updatedBy | null;
}

export interface GetPrintHeading_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  printTemplate: GetPrintHeading_printHeading_printTemplates_printTemplate;
}

export interface GetPrintHeading_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  order: number;
  legacyId: number | null;
  hasAutomaticSubject: boolean;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  printIssue: GetPrintHeading_printHeading_printIssue;
  /**
   * Number of "printTemplates"
   */
  printTemplateCount: GraphQL$UnsignedInt;
  printTemplates: GetPrintHeading_printHeading_printTemplates[];
}

export interface GetPrintHeading {
  /**
   * Retrieves one "PrintHeading", throws an error if it does not exist
   */
  printHeading: GetPrintHeading_printHeading;
}

export interface GetPrintHeadingVariables {
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintTemplateDownloadUrl
// ====================================================

export interface GetPrintTemplateDownloadUrl_printTemplateDownload {
  __typename: "PrintTemplateDownloadResultType";
  id: string;
  url: string;
}

export interface GetPrintTemplateDownloadUrl {
  /**
   * print Template download signed url
   */
  printTemplateDownload: GetPrintTemplateDownloadUrl_printTemplateDownload;
}

export interface GetPrintTemplateDownloadUrlVariables {
  printTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintTemplateUploadUrl
// ====================================================

export interface GetPrintTemplateUploadUrl_printTemplateUpload {
  __typename: "PrintTemplateUploadResultType";
  id: string;
  url: string;
}

export interface GetPrintTemplateUploadUrl {
  /**
   * print Template upload signed url
   */
  printTemplateUpload: GetPrintTemplateUploadUrl_printTemplateUpload;
}

export interface GetPrintTemplateUploadUrlVariables {
  S3ObjectId: string;
  filename: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintTemplatePreviewUrls
// ====================================================

export interface GetPrintTemplatePreviewUrls {
  /**
   * print Template preview signed urls
   */
  printTemplatePreviewUrls: string[];
}

export interface GetPrintTemplatePreviewUrlsVariables {
  printTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRawArticle
// ====================================================

export interface GetRawArticle_rawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface GetRawArticle_rawArticle_versions {
  __typename: "RawArticleVersion";
  allowedStatuses: RawArticleStatus[];
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  /**
   * An array of left over Draft content
   */
  leftOver: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: GetRawArticle_rawArticle_versions_editor | null;
  textIsReadOnly: boolean;
}

export interface GetRawArticle_rawArticle_versionList_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface GetRawArticle_rawArticle_versionList {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  status: RawArticleStatus;
  /**
   * The user assigned to the version
   */
  editorId: string;
  editor: GetRawArticle_rawArticle_versionList_editor | null;
}

export interface GetRawArticle_rawArticle_assignment_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetRawArticle_rawArticle_assignment_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetRawArticle_rawArticle_assignment_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: GetRawArticle_rawArticle_assignment_printIssue_printPublication;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields_page_coordinates;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  enabled: boolean | null;
  data: string;
  typeVariation: string;
  paragraphStyles: GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields_paragraphStyles[];
  characterStyles: GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields_characterStyles[];
  coordinates: GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields_coordinates;
  page: GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields_page;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_createdBy {
  __typename: "User";
  name: string;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_updatedBy {
  __typename: "User";
  name: string;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * associated S3 object id
   */
  S3ObjectId: string;
  /**
   * template label
   */
  label: string;
  /**
   * InDesign file name
   */
  filename: string;
  /**
   * template type
   */
  type: TemplateType | null;
  /**
   * InDesign file size
   */
  filesize: number | null;
  /**
   * Template page count
   */
  pageCount: number | null;
  /**
   * KAMI extension version
   */
  extVersion: string | null;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_fields | null)[];
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  createdBy: GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_createdBy | null;
  updatedBy: GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate_updatedBy | null;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  printTemplate: GetRawArticle_rawArticle_assignment_printHeading_printTemplates_printTemplate;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetRawArticle_rawArticle_assignment_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: GetRawArticle_rawArticle_assignment_printHeading_printIssue_printPublication;
}

export interface GetRawArticle_rawArticle_assignment_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  autoPrototype: boolean | null;
  printTemplates: GetRawArticle_rawArticle_assignment_printHeading_printTemplates[];
  printIssue: GetRawArticle_rawArticle_assignment_printHeading_printIssue;
}

export interface GetRawArticle_rawArticle_assignment_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
}

export interface GetRawArticle_rawArticle_assignment_printTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  height: number | null;
  width: number | null;
  rotation: number | null;
}

export interface GetRawArticle_rawArticle_assignment_printTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  coordinates: GetRawArticle_rawArticle_assignment_printTemplate_fields_page_coordinates;
}

export interface GetRawArticle_rawArticle_assignment_printTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetRawArticle_rawArticle_assignment_printTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetRawArticle_rawArticle_assignment_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  page: GetRawArticle_rawArticle_assignment_printTemplate_fields_page;
  data: string;
  typeVariation: string;
  paragraphStyles: GetRawArticle_rawArticle_assignment_printTemplate_fields_paragraphStyles[];
  characterStyles: GetRawArticle_rawArticle_assignment_printTemplate_fields_characterStyles[];
}

export interface GetRawArticle_rawArticle_assignment_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * template label
   */
  label: string;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (GetRawArticle_rawArticle_assignment_printTemplate_fields | null)[];
}

export interface GetRawArticle_rawArticle_assignment_subject_unit {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetRawArticle_rawArticle_assignment_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
  synopsis: string | null;
  unit: GetRawArticle_rawArticle_assignment_subject_unit;
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_fields_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: GetRawArticle_rawArticle_assignment_articleTemplate_fields_page_coordinates;
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  enabled: boolean | null;
  data: string;
  typeVariation: string;
  paragraphStyles: GetRawArticle_rawArticle_assignment_articleTemplate_fields_paragraphStyles[];
  characterStyles: GetRawArticle_rawArticle_assignment_articleTemplate_fields_characterStyles[];
  coordinates: GetRawArticle_rawArticle_assignment_articleTemplate_fields_coordinates;
  page: GetRawArticle_rawArticle_assignment_articleTemplate_fields_page;
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  height: number | null;
  width: number | null;
  rotation: number | null;
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  coordinates: GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate_fields_page_coordinates;
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  page: GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate_fields_page;
  data: string;
  typeVariation: string;
  paragraphStyles: GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate_fields_paragraphStyles[];
  characterStyles: GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate_fields_characterStyles[];
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * template type
   */
  type: TemplateType | null;
  fields: (GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate_fields | null)[];
}

export interface GetRawArticle_rawArticle_assignment_articleTemplate {
  __typename: "ArticleTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * The article template label
   */
  label: string | null;
  /**
   * The raw datas
   */
  block: string | null;
  fields: (GetRawArticle_rawArticle_assignment_articleTemplate_fields | null)[];
  printTemplate: GetRawArticle_rawArticle_assignment_articleTemplate_printTemplate;
}

export interface GetRawArticle_rawArticle_assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printPublication: GetRawArticle_rawArticle_assignment_printPublication | null;
  printIssue: GetRawArticle_rawArticle_assignment_printIssue | null;
  printHeading: GetRawArticle_rawArticle_assignment_printHeading | null;
  website: GetRawArticle_rawArticle_assignment_website | null;
  printTemplate: GetRawArticle_rawArticle_assignment_printTemplate | null;
  subject: GetRawArticle_rawArticle_assignment_subject;
  articleTemplate: GetRawArticle_rawArticle_assignment_articleTemplate | null;
}

export interface GetRawArticle_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: GetRawArticle_rawArticle_versions[];
  versionList: GetRawArticle_rawArticle_versionList[];
  assignment: GetRawArticle_rawArticle_assignment | null;
}

export interface GetRawArticle {
  /**
   * Retrieves one "RawArticle", throws an error if it does not exist
   */
  rawArticle: GetRawArticle_rawArticle;
}

export interface GetRawArticleVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUnits
// ====================================================

export interface GetUnits_units {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetUnits {
  /**
   * Retrieves a list of "Units"
   */
  units: GetUnits_units[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDownloadUrlAttachment
// ====================================================

export interface getDownloadUrlAttachment_attachment {
  __typename: "Attachment";
  downloadUrl: string | null;
}

export interface getDownloadUrlAttachment {
  /**
   * Retrieves one "Attachment", throws an error if it does not exist
   */
  attachment: getDownloadUrlAttachment_attachment;
}

export interface getDownloadUrlAttachmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubjectForMediaStatusDialog
// ====================================================

export interface GetSubjectForMediaStatusDialog_subject_assignments_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_articleTemplate {
  __typename: "ArticleTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_rawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_rawArticle_versions {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: GetSubjectForMediaStatusDialog_subject_assignments_rawArticle_versions_editor | null;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: GetSubjectForMediaStatusDialog_subject_assignments_rawArticle_versions[];
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * template type
   */
  type: TemplateType | null;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  printTemplate: GetSubjectForMediaStatusDialog_subject_assignments_printHeading_printTemplates_printTemplate;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: GetSubjectForMediaStatusDialog_subject_assignments_printHeading_printIssue_printPublication;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  autoPrototype: boolean | null;
  printTemplates: GetSubjectForMediaStatusDialog_subject_assignments_printHeading_printTemplates[];
  printIssue: GetSubjectForMediaStatusDialog_subject_assignments_printHeading_printIssue;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: GetSubjectForMediaStatusDialog_subject_assignments_printIssue_printPublication;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
}

export interface GetSubjectForMediaStatusDialog_subject_assignments {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printTemplate: GetSubjectForMediaStatusDialog_subject_assignments_printTemplate | null;
  articleTemplate: GetSubjectForMediaStatusDialog_subject_assignments_articleTemplate | null;
  mediaSearchStatus: MediaSearchStatus;
  rawArticle: GetSubjectForMediaStatusDialog_subject_assignments_rawArticle | null;
  printHeading: GetSubjectForMediaStatusDialog_subject_assignments_printHeading | null;
  printIssue: GetSubjectForMediaStatusDialog_subject_assignments_printIssue | null;
  printPublication: GetSubjectForMediaStatusDialog_subject_assignments_printPublication | null;
  website: GetSubjectForMediaStatusDialog_subject_assignments_website | null;
  subject: GetSubjectForMediaStatusDialog_subject_assignments_subject;
}

export interface GetSubjectForMediaStatusDialog_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  assignments: GetSubjectForMediaStatusDialog_subject_assignments[];
}

export interface GetSubjectForMediaStatusDialog {
  /**
   * Retrieves one "Subject", throws an error if it does not exist
   */
  subject: GetSubjectForMediaStatusDialog_subject;
}

export interface GetSubjectForMediaStatusDialogVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAssignmentMediaSearchStatus
// ====================================================

export interface UpdateAssignmentMediaSearchStatus_updateAssignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  mediaSearchStatus: MediaSearchStatus;
}

export interface UpdateAssignmentMediaSearchStatus {
  /**
   * Updates one "Assignment", throws an error if it does not exists
   */
  updateAssignment: UpdateAssignmentMediaSearchStatus_updateAssignment;
}

export interface UpdateAssignmentMediaSearchStatusVariables {
  assignmentId: string;
  status: MediaSearchStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSubjectUser
// ====================================================

export interface DeleteSubjectUser_deleteSubjectUser {
  __typename: "SubjectUser";
  userId: string;
}

export interface DeleteSubjectUser {
  /**
   * Deletes one "SubjectUser", throws an error if it does not exists
   */
  deleteSubjectUser: DeleteSubjectUser_deleteSubjectUser;
}

export interface DeleteSubjectUserVariables {
  userId: string;
  subjectId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubjectUsers
// ====================================================

export interface GetSubjectUsers_subject_subjectUsers_user {
  __typename: "User";
  email: string;
  name: string;
  avatarUrl: string | null;
}

export interface GetSubjectUsers_subject_subjectUsers {
  __typename: "SubjectUser";
  userId: string;
  user: GetSubjectUsers_subject_subjectUsers_user | null;
}

export interface GetSubjectUsers_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  subjectUsers: GetSubjectUsers_subject_subjectUsers[];
}

export interface GetSubjectUsers {
  /**
   * Retrieves one "Subject", throws an error if it does not exist
   */
  subject: GetSubjectUsers_subject;
}

export interface GetSubjectUsersVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ShareSubject
// ====================================================

export interface ShareSubject_shareSubject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
}

export interface ShareSubject {
  shareSubject: ShareSubject_shareSubject | null;
}

export interface ShareSubjectVariables {
  subject: string;
  userIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UnitSpeedDial_GetUnits
// ====================================================

export interface UnitSpeedDial_GetUnits_units {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface UnitSpeedDial_GetUnits {
  /**
   * Retrieves a list of "Units"
   */
  units: UnitSpeedDial_GetUnits_units[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSubject
// ====================================================

export interface DeleteSubject_deleteSubject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
}

export interface DeleteSubject {
  /**
   * Deletes one "Subject", throws an error if it does not exists
   */
  deleteSubject: DeleteSubject_deleteSubject;
}

export interface DeleteSubjectVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintTemplateDraft
// ====================================================

export interface GetPrintTemplateDraft_printTemplateDrafts_artifacts {
  __typename: "PrintTemplateDraftArtifactType";
  page: number;
  url: string;
}

export interface GetPrintTemplateDraft_printTemplateDrafts {
  __typename: "PrintTemplateDraftsResultType";
  draftId: string;
  artifacts: GetPrintTemplateDraft_printTemplateDrafts_artifacts[];
}

export interface GetPrintTemplateDraft {
  /**
   * generate print Template drafts
   */
  printTemplateDrafts: GetPrintTemplateDraft_printTemplateDrafts;
}

export interface GetPrintTemplateDraftVariables {
  printHeadingId: string;
  printTemplateId?: string | null;
  format: PrintTemplateDraftFormatType;
  pages?: number[] | null;
  fieldsData?: (PrintTemplateDraftFieldsDataType | null)[] | null;
  embedRessources?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAssignmentByRawArticleId
// ====================================================

export interface GetAssignmentByRawArticleId_assignment_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetAssignmentByRawArticleId_assignment_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetAssignmentByRawArticleId_assignment_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: GetAssignmentByRawArticleId_assignment_printIssue_printPublication;
}

export interface GetAssignmentByRawArticleId_assignment_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetAssignmentByRawArticleId_assignment_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: GetAssignmentByRawArticleId_assignment_printHeading_printIssue_printPublication;
}

export interface GetAssignmentByRawArticleId_assignment_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printIssue: GetAssignmentByRawArticleId_assignment_printHeading_printIssue;
}

export interface GetAssignmentByRawArticleId_assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printPublication: GetAssignmentByRawArticleId_assignment_printPublication | null;
  printIssue: GetAssignmentByRawArticleId_assignment_printIssue | null;
  printHeading: GetAssignmentByRawArticleId_assignment_printHeading | null;
}

export interface GetAssignmentByRawArticleId {
  /**
   * Retrieves one "Assignment", throws an error if it does not exist
   */
  assignment: GetAssignmentByRawArticleId_assignment;
}

export interface GetAssignmentByRawArticleIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAssignmentByArticle
// ====================================================

export interface UpdateAssignmentByArticle_updateAssignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
}

export interface UpdateAssignmentByArticle {
  /**
   * Updates one "Assignment", throws an error if it does not exists
   */
  updateAssignment: UpdateAssignmentByArticle_updateAssignment;
}

export interface UpdateAssignmentByArticleVariables {
  id: string;
  data: AssignmentUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertRawArticle
// ====================================================

export interface UpsertRawArticle_upsertRawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface UpsertRawArticle_upsertRawArticle_versions {
  __typename: "RawArticleVersion";
  allowedStatuses: RawArticleStatus[];
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  /**
   * An array of left over Draft content
   */
  leftOver: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: UpsertRawArticle_upsertRawArticle_versions_editor | null;
  textIsReadOnly: boolean;
}

export interface UpsertRawArticle_upsertRawArticle_versionList_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface UpsertRawArticle_upsertRawArticle_versionList {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  status: RawArticleStatus;
  /**
   * The user assigned to the version
   */
  editorId: string;
  editor: UpsertRawArticle_upsertRawArticle_versionList_editor | null;
}

export interface UpsertRawArticle_upsertRawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: UpsertRawArticle_upsertRawArticle_versions[];
  versionList: UpsertRawArticle_upsertRawArticle_versionList[];
}

export interface UpsertRawArticle {
  /**
   * Updates an existing "RawArticle" or creates a new one
   */
  upsertRawArticle: UpsertRawArticle_upsertRawArticle;
}

export interface UpsertRawArticleVariables {
  create: RawArticleCreationInput;
  update: RawArticleUpdateInput;
  where: RawArticleUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRawArticleVersionEditorTransfer
// ====================================================

export interface CreateRawArticleVersionEditorTransfer_createRawArticleVersion_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface CreateRawArticleVersionEditorTransfer_createRawArticleVersion {
  __typename: "RawArticleVersion";
  allowedStatuses: RawArticleStatus[];
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  /**
   * An array of left over Draft content
   */
  leftOver: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: CreateRawArticleVersionEditorTransfer_createRawArticleVersion_editor | null;
  textIsReadOnly: boolean;
  /**
   * The user assigned to the version
   */
  editorId: string;
}

export interface CreateRawArticleVersionEditorTransfer {
  /**
   * Creates one "RawArticleVersion", throws an error if it already exists
   */
  createRawArticleVersion: CreateRawArticleVersionEditorTransfer_createRawArticleVersion;
}

export interface CreateRawArticleVersionEditorTransferVariables {
  data: RawArticleVersionCreationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserBySearch
// ====================================================

export interface GetUserBySearch_rawArticleVersion_allowedTransfers {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetUserBySearch_rawArticleVersion {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  /**
   * Returns a list of users for whom the transfer is possible
   */
  allowedTransfers: GetUserBySearch_rawArticleVersion_allowedTransfers[];
}

export interface GetUserBySearch {
  /**
   * Retrieves one "RawArticleVersion", throws an error if it does not exist
   */
  rawArticleVersion: GetUserBySearch_rawArticleVersion;
}

export interface GetUserBySearchVariables {
  id: string;
  search: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRawArticleVersionArticleHistory
// ====================================================

export interface GetRawArticleVersionArticleHistory_rawArticleVersion_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface GetRawArticleVersionArticleHistory_rawArticleVersion_rawArticle_lastVersion {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
}

export interface GetRawArticleVersionArticleHistory_rawArticleVersion_rawArticle_assignment_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
}

export interface GetRawArticleVersionArticleHistory_rawArticleVersion_rawArticle_assignment_printTemplate {
  __typename: "PrintTemplate";
  fields: (GetRawArticleVersionArticleHistory_rawArticleVersion_rawArticle_assignment_printTemplate_fields | null)[];
}

export interface GetRawArticleVersionArticleHistory_rawArticleVersion_rawArticle_assignment {
  __typename: "Assignment";
  printTemplate: GetRawArticleVersionArticleHistory_rawArticleVersion_rawArticle_assignment_printTemplate | null;
}

export interface GetRawArticleVersionArticleHistory_rawArticleVersion_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  lastVersion: GetRawArticleVersionArticleHistory_rawArticleVersion_rawArticle_lastVersion | null;
  assignment: GetRawArticleVersionArticleHistory_rawArticleVersion_rawArticle_assignment | null;
}

export interface GetRawArticleVersionArticleHistory_rawArticleVersion {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  status: RawArticleStatus;
  editor: GetRawArticleVersionArticleHistory_rawArticleVersion_editor | null;
  rawArticle: GetRawArticleVersionArticleHistory_rawArticleVersion_rawArticle;
}

export interface GetRawArticleVersionArticleHistory {
  /**
   * Retrieves one "RawArticleVersion", throws an error if it does not exist
   */
  rawArticleVersion: GetRawArticleVersionArticleHistory_rawArticleVersion;
}

export interface GetRawArticleVersionArticleHistoryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRawArticleMajorVersions
// ====================================================

export interface GetRawArticleMajorVersions_rawArticle_majorVersions_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface GetRawArticleMajorVersions_rawArticle_majorVersions_previousMinorVersions_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface GetRawArticleMajorVersions_rawArticle_majorVersions_previousMinorVersions {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  editor: GetRawArticleMajorVersions_rawArticle_majorVersions_previousMinorVersions_editor | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
}

export interface GetRawArticleMajorVersions_rawArticle_majorVersions {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  status: RawArticleStatus;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  /**
   * An array of left over Draft content
   */
  leftOver: string | null;
  /**
   * The user assigned to the version
   */
  editorId: string;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: GetRawArticleMajorVersions_rawArticle_majorVersions_editor | null;
  previousMinorVersions: GetRawArticleMajorVersions_rawArticle_majorVersions_previousMinorVersions[];
}

export interface GetRawArticleMajorVersions_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  majorVersions: GetRawArticleMajorVersions_rawArticle_majorVersions[];
}

export interface GetRawArticleMajorVersions {
  /**
   * Retrieves one "RawArticle", throws an error if it does not exist
   */
  rawArticle: GetRawArticleMajorVersions_rawArticle;
}

export interface GetRawArticleMajorVersionsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePrintHeading
// ====================================================

export interface UpdatePrintHeading_updatePrintHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface UpdatePrintHeading_updatePrintHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  printTemplate: UpdatePrintHeading_updatePrintHeading_printTemplates_printTemplate;
}

export interface UpdatePrintHeading_updatePrintHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  order: number;
  hasAutomaticSubject: boolean;
  autoPrototype: boolean | null;
  /**
   * Number of "printTemplates"
   */
  printTemplateCount: GraphQL$UnsignedInt;
  /**
   * Number of "assignments"
   */
  assignmentCount: GraphQL$UnsignedInt;
  printTemplates: UpdatePrintHeading_updatePrintHeading_printTemplates[];
}

export interface UpdatePrintHeading {
  /**
   * Updates one "PrintHeading", throws an error if it does not exists
   */
  updatePrintHeading: UpdatePrintHeading_updatePrintHeading;
}

export interface UpdatePrintHeadingVariables {
  data: PrintHeadingUpdateInput;
  where: PrintHeadingUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocationPage_GetSubjectsFromPrintHeading
// ====================================================

export interface LocationPage_GetSubjectsFromPrintHeading_subjects_subjectCategory_parent {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface LocationPage_GetSubjectsFromPrintHeading_subjects_subjectCategory {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
  parent: LocationPage_GetSubjectsFromPrintHeading_subjects_subjectCategory_parent | null;
}

export interface LocationPage_GetSubjectsFromPrintHeading_subjects_flatAssignments_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface LocationPage_GetSubjectsFromPrintHeading_subjects_flatAssignments {
  __typename: "FlatAssignment";
  printHeading: LocationPage_GetSubjectsFromPrintHeading_subjects_flatAssignments_printHeading | null;
}

export interface LocationPage_GetSubjectsFromPrintHeading_subjects {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
  isAuto: boolean;
  subjectCategory: LocationPage_GetSubjectsFromPrintHeading_subjects_subjectCategory | null;
  flatAssignments: LocationPage_GetSubjectsFromPrintHeading_subjects_flatAssignments[];
}

export interface LocationPage_GetSubjectsFromPrintHeading {
  /**
   * Retrieves a list of "Subjects"
   */
  subjects: LocationPage_GetSubjectsFromPrintHeading_subjects[];
}

export interface LocationPage_GetSubjectsFromPrintHeadingVariables {
  where?: SubjectFilterInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LocationPage_DeletePrintHeading
// ====================================================

export interface LocationPage_DeletePrintHeading_deletePrintHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
}

export interface LocationPage_DeletePrintHeading {
  /**
   * Deletes one "PrintHeading", throws an error if it does not exists
   */
  deletePrintHeading: LocationPage_DeletePrintHeading_deletePrintHeading;
}

export interface LocationPage_DeletePrintHeadingVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocationPage_GetPrintHeadingDeleteInformation
// ====================================================

export interface LocationPage_GetPrintHeadingDeleteInformation_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  title: string;
}

export interface LocationPage_GetPrintHeadingDeleteInformation_printHeading_printIssue {
  __typename: "PrintIssue";
  title: string;
  printPublication: LocationPage_GetPrintHeadingDeleteInformation_printHeading_printIssue_printPublication;
}

export interface LocationPage_GetPrintHeadingDeleteInformation_printHeading_assignments_subject {
  __typename: "Subject";
  title: string;
}

export interface LocationPage_GetPrintHeadingDeleteInformation_printHeading_assignments {
  __typename: "Assignment";
  subject: LocationPage_GetPrintHeadingDeleteInformation_printHeading_assignments_subject;
}

export interface LocationPage_GetPrintHeadingDeleteInformation_printHeading {
  __typename: "PrintHeading";
  title: string;
  printIssue: LocationPage_GetPrintHeadingDeleteInformation_printHeading_printIssue;
  assignments: LocationPage_GetPrintHeadingDeleteInformation_printHeading_assignments[];
}

export interface LocationPage_GetPrintHeadingDeleteInformation {
  /**
   * Retrieves one "PrintHeading", throws an error if it does not exist
   */
  printHeading: LocationPage_GetPrintHeadingDeleteInformation_printHeading;
}

export interface LocationPage_GetPrintHeadingDeleteInformationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocationPage_GetPrintHeadingByPrintIssue
// ====================================================

export interface LocationPage_GetPrintHeadingByPrintIssue_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  key: string;
}

export interface LocationPage_GetPrintHeadingByPrintIssue_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: LocationPage_GetPrintHeadingByPrintIssue_printIssue_printPublication;
}

export interface LocationPage_GetPrintHeadingByPrintIssue_printHeadings_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface LocationPage_GetPrintHeadingByPrintIssue_printHeadings_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  printTemplate: LocationPage_GetPrintHeadingByPrintIssue_printHeadings_printTemplates_printTemplate;
}

export interface LocationPage_GetPrintHeadingByPrintIssue_printHeadings {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  order: number;
  hasAutomaticSubject: boolean;
  autoPrototype: boolean | null;
  /**
   * Number of "printTemplates"
   */
  printTemplateCount: GraphQL$UnsignedInt;
  /**
   * Number of "assignments"
   */
  assignmentCount: GraphQL$UnsignedInt;
  printTemplates: LocationPage_GetPrintHeadingByPrintIssue_printHeadings_printTemplates[];
}

export interface LocationPage_GetPrintHeadingByPrintIssue {
  /**
   * Retrieves one "PrintIssue", throws an error if it does not exist
   */
  printIssue: LocationPage_GetPrintHeadingByPrintIssue_printIssue;
  /**
   * Retrieves a list of "PrintHeadings"
   */
  printHeadings: LocationPage_GetPrintHeadingByPrintIssue_printHeadings[];
}

export interface LocationPage_GetPrintHeadingByPrintIssueVariables {
  printIssueId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LocationPage_UpsertPrintHeading
// ====================================================

export interface LocationPage_UpsertPrintHeading_upsertPrintHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface LocationPage_UpsertPrintHeading_upsertPrintHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  printTemplate: LocationPage_UpsertPrintHeading_upsertPrintHeading_printTemplates_printTemplate;
}

export interface LocationPage_UpsertPrintHeading_upsertPrintHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  order: number;
  hasAutomaticSubject: boolean;
  autoPrototype: boolean | null;
  /**
   * Number of "printTemplates"
   */
  printTemplateCount: GraphQL$UnsignedInt;
  /**
   * Number of "assignments"
   */
  assignmentCount: GraphQL$UnsignedInt;
  printTemplates: LocationPage_UpsertPrintHeading_upsertPrintHeading_printTemplates[];
}

export interface LocationPage_UpsertPrintHeading {
  /**
   * Updates an existing "PrintHeading" or creates a new one
   */
  upsertPrintHeading: LocationPage_UpsertPrintHeading_upsertPrintHeading;
}

export interface LocationPage_UpsertPrintHeadingVariables {
  create: PrintHeadingCreationInput;
  update: PrintHeadingUpdateInput;
  where: PrintHeadingUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LocationPage_DeletePrintIssue
// ====================================================

export interface LocationPage_DeletePrintIssue_deletePrintIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
}

export interface LocationPage_DeletePrintIssue {
  /**
   * Deletes one "PrintIssue", throws an error if it does not exists
   */
  deletePrintIssue: LocationPage_DeletePrintIssue_deletePrintIssue;
}

export interface LocationPage_DeletePrintIssueVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocationPage_GetDeletePrintIssueInformation
// ====================================================

export interface LocationPage_GetDeletePrintIssueInformation_printIssue_printHeadings {
  __typename: "PrintHeading";
  title: string;
  /**
   * Number of "assignments"
   */
  assignmentCount: GraphQL$UnsignedInt;
}

export interface LocationPage_GetDeletePrintIssueInformation_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface LocationPage_GetDeletePrintIssueInformation_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  /**
   * Number of "assignments"
   */
  assignmentCount: GraphQL$UnsignedInt;
  title: string;
  printHeadings: LocationPage_GetDeletePrintIssueInformation_printIssue_printHeadings[];
  printPublication: LocationPage_GetDeletePrintIssueInformation_printIssue_printPublication;
}

export interface LocationPage_GetDeletePrintIssueInformation {
  /**
   * Retrieves one "PrintIssue", throws an error if it does not exist
   */
  printIssue: LocationPage_GetDeletePrintIssueInformation_printIssue;
}

export interface LocationPage_GetDeletePrintIssueInformationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocationPage_GetPrintIssuesByPrintPublication
// ====================================================

export interface LocationPage_GetPrintIssuesByPrintPublication_printIssues {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface LocationPage_GetPrintIssuesByPrintPublication_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface LocationPage_GetPrintIssuesByPrintPublication {
  /**
   * Retrieves a list of "PrintIssues"
   */
  printIssues: LocationPage_GetPrintIssuesByPrintPublication_printIssues[];
  /**
   * Retrieves one "PrintPublication", throws an error if it does not exist
   */
  printPublication: LocationPage_GetPrintIssuesByPrintPublication_printPublication;
}

export interface LocationPage_GetPrintIssuesByPrintPublicationVariables {
  printPublicationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocationPage_GetPrintPublicationKey
// ====================================================

export interface LocationPage_GetPrintPublicationKey_printPublication {
  __typename: "PrintPublication";
  key: string;
}

export interface LocationPage_GetPrintPublicationKey {
  /**
   * Retrieves one "PrintPublication", throws an error if it does not exist
   */
  printPublication: LocationPage_GetPrintPublicationKey_printPublication;
}

export interface LocationPage_GetPrintPublicationKeyVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LocationPage_UpsertPrintIssue
// ====================================================

export interface LocationPage_UpsertPrintIssue_upsertPrintIssue_printHeadings {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  order: number;
  hasAutomaticSubject: boolean;
}

export interface LocationPage_UpsertPrintIssue_upsertPrintIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printHeadings: LocationPage_UpsertPrintIssue_upsertPrintIssue_printHeadings[];
}

export interface LocationPage_UpsertPrintIssue {
  /**
   * Updates an existing "PrintIssue" or creates a new one
   */
  upsertPrintIssue: LocationPage_UpsertPrintIssue_upsertPrintIssue;
}

export interface LocationPage_UpsertPrintIssueVariables {
  create: PrintIssueCreationInput;
  update: PrintIssueUpdateInput;
  where: PrintIssueUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocationPage_GetPrintPublicationsByUnit
// ====================================================

export interface LocationPage_GetPrintPublicationsByUnit_unit_brands_printPublications_brand {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
}

export interface LocationPage_GetPrintPublicationsByUnit_unit_brands_printPublications {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
  brand: LocationPage_GetPrintPublicationsByUnit_unit_brands_printPublications_brand;
}

export interface LocationPage_GetPrintPublicationsByUnit_unit_brands {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublications: LocationPage_GetPrintPublicationsByUnit_unit_brands_printPublications[];
}

export interface LocationPage_GetPrintPublicationsByUnit_unit {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
  brands: LocationPage_GetPrintPublicationsByUnit_unit_brands[];
}

export interface LocationPage_GetPrintPublicationsByUnit {
  /**
   * Retrieves one "Unit", throws an error if it does not exist
   */
  unit: LocationPage_GetPrintPublicationsByUnit_unit;
}

export interface LocationPage_GetPrintPublicationsByUnitVariables {
  unitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LocationPage_UpsertPrintPublication
// ====================================================

export interface LocationPage_UpsertPrintPublication_upsertPrintPublication_brand {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
}

export interface LocationPage_UpsertPrintPublication_upsertPrintPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
  brand: LocationPage_UpsertPrintPublication_upsertPrintPublication_brand;
}

export interface LocationPage_UpsertPrintPublication {
  /**
   * Updates an existing "PrintPublication" or creates a new one
   */
  upsertPrintPublication: LocationPage_UpsertPrintPublication_upsertPrintPublication;
}

export interface LocationPage_UpsertPrintPublicationVariables {
  create: PrintPublicationCreationInput;
  update: PrintPublicationUpdateInput;
  where: PrintPublicationUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LocationPage_UpdatePrintHeadingOrder
// ====================================================

export interface LocationPage_UpdatePrintHeadingOrder_updatePrintIssue_printHeadings {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  order: number;
  hasAutomaticSubject: boolean;
}

export interface LocationPage_UpdatePrintHeadingOrder_updatePrintIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printHeadings: LocationPage_UpdatePrintHeadingOrder_updatePrintIssue_printHeadings[];
}

export interface LocationPage_UpdatePrintHeadingOrder {
  /**
   * Updates one "PrintIssue", throws an error if it does not exists
   */
  updatePrintIssue: LocationPage_UpdatePrintHeadingOrder_updatePrintIssue;
}

export interface LocationPage_UpdatePrintHeadingOrderVariables {
  id: string;
  data: PrintIssueUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubjectForm_DeleteAssignment
// ====================================================

export interface SubjectForm_DeleteAssignment_deleteAssignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
}

export interface SubjectForm_DeleteAssignment {
  /**
   * Deletes one "Assignment", throws an error if it does not exists
   */
  deleteAssignment: SubjectForm_DeleteAssignment_deleteAssignment;
}

export interface SubjectForm_DeleteAssignmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAttachment
// ====================================================

export interface CreateAttachment_createAttachment {
  __typename: "Attachment";
  /**
   * The public ID
   */
  id: string;
  /**
   * get presigned url from S3
   */
  uploadUrl: string | null;
}

export interface CreateAttachment {
  /**
   * Creates one "Attachment", throws an error if it already exists
   */
  createAttachment: CreateAttachment_createAttachment;
}

export interface CreateAttachmentVariables {
  data: AttachmentCreationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAttachment
// ====================================================

export interface DeleteAttachment_deleteAttachment {
  __typename: "Attachment";
  /**
   * The public ID
   */
  id: string;
}

export interface DeleteAttachment {
  /**
   * Deletes one "Attachment", throws an error if it does not exists
   */
  deleteAttachment: DeleteAttachment_deleteAttachment;
}

export interface DeleteAttachmentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAttachment
// ====================================================

export interface UpdateAttachment_updateAttachment_createdBy {
  __typename: "User";
  name: string;
}

export interface UpdateAttachment_updateAttachment {
  __typename: "Attachment";
  /**
   * The public ID
   */
  id: string;
  /**
   * sent from the front
   */
  filename: string;
  /**
   * update from S3
   */
  filesize: number | null;
  /**
   * update from S3
   */
  contentType: string | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  createdBy: UpdateAttachment_updateAttachment_createdBy | null;
}

export interface UpdateAttachment {
  /**
   * Updates one "Attachment", throws an error if it does not exists
   */
  updateAttachment: UpdateAttachment_updateAttachment;
}

export interface UpdateAttachmentVariables {
  data: AttachmentUpdateInput;
  where: AttachmentUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRawArticle
// ====================================================

export interface CreateRawArticle_createRawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
}

export interface CreateRawArticle {
  /**
   * Creates one "RawArticle", throws an error if it already exists
   */
  createRawArticle: CreateRawArticle_createRawArticle;
}

export interface CreateRawArticleVariables {
  data: RawArticleCreationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpsertSubject
// ====================================================

export interface UpsertSubject_upsertSubject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
  comment: string | null;
  synopsis: string | null;
  mediaRequest: string | null;
}

export interface UpsertSubject {
  /**
   * Updates an existing "Subject" or creates a new one
   */
  upsertSubject: UpsertSubject_upsertSubject;
}

export interface UpsertSubjectVariables {
  create: SubjectCreationInput;
  update: SubjectUpdateInput;
  where: SubjectUniqueFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubject
// ====================================================

export interface GetSubject_subject_createdBy {
  __typename: "User";
  name: string;
}

export interface GetSubject_subject_unit {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
  photoArchiveId: number | null;
}

export interface GetSubject_subject_subjectCategory_parent {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
}

export interface GetSubject_subject_subjectCategory {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  parent: GetSubject_subject_subjectCategory_parent | null;
}

export interface GetSubject_subject_assignments_assignment_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetSubject_subject_assignments_assignment_articleTemplate {
  __typename: "ArticleTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetSubject_subject_assignments_assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printTemplate: GetSubject_subject_assignments_assignment_printTemplate | null;
  articleTemplate: GetSubject_subject_assignments_assignment_articleTemplate | null;
  mediaSearchStatus: MediaSearchStatus;
  pageNumber: number | null;
}

export interface GetSubject_subject_assignments_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  key: string;
  title: string;
}

export interface GetSubject_subject_assignments_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetSubject_subject_assignments_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * template type
   */
  type: TemplateType | null;
}

export interface GetSubject_subject_assignments_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  printTemplate: GetSubject_subject_assignments_printHeading_printTemplates_printTemplate;
}

export interface GetSubject_subject_assignments_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  autoPrototype: boolean | null;
  printTemplates: GetSubject_subject_assignments_printHeading_printTemplates[];
}

export interface GetSubject_subject_assignments_rawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetSubject_subject_assignments_rawArticle_versions {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  status: RawArticleStatus;
  title: string | null;
  editor: GetSubject_subject_assignments_rawArticle_versions_editor | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
}

export interface GetSubject_subject_assignments_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: GetSubject_subject_assignments_rawArticle_versions[];
}

export interface GetSubject_subject_assignments_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  key: string;
  title: string;
}

export interface GetSubject_subject_assignments {
  __typename: "FlatAssignment";
  assignment: GetSubject_subject_assignments_assignment;
  printPublication: GetSubject_subject_assignments_printPublication | null;
  printIssue: GetSubject_subject_assignments_printIssue | null;
  printHeading: GetSubject_subject_assignments_printHeading | null;
  rawArticle: GetSubject_subject_assignments_rawArticle | null;
  website: GetSubject_subject_assignments_website | null;
}

export interface GetSubject_subject_subjectUsers_user {
  __typename: "User";
  email: string;
  name: string;
  avatarUrl: string | null;
}

export interface GetSubject_subject_subjectUsers {
  __typename: "SubjectUser";
  userId: string;
  user: GetSubject_subject_subjectUsers_user | null;
}

export interface GetSubject_subject_attachments_createdBy {
  __typename: "User";
  name: string;
}

export interface GetSubject_subject_attachments {
  __typename: "Attachment";
  /**
   * The public ID
   */
  id: string;
  /**
   * sent from the front
   */
  filename: string;
  /**
   * update from S3
   */
  filesize: number | null;
  /**
   * update from S3
   */
  contentType: string | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  createdBy: GetSubject_subject_attachments_createdBy | null;
}

export interface GetSubject_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
  synopsis: string | null;
  comment: string | null;
  mediaRequest: string | null;
  createdBy: GetSubject_subject_createdBy | null;
  unit: GetSubject_subject_unit;
  subjectCategory: GetSubject_subject_subjectCategory | null;
  assignments: GetSubject_subject_assignments[];
  isClassified: boolean;
  subjectUsers: GetSubject_subject_subjectUsers[];
  attachments: GetSubject_subject_attachments[];
  /**
   * Indicate if title is writable if none of article version has "Mockup" status
   */
  isTitleWritable: boolean;
}

export interface GetSubject {
  /**
   * Retrieves one "Subject", throws an error if it does not exist
   */
  subject: GetSubject_subject;
}

export interface GetSubjectVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPrintSubject
// ====================================================

export interface GetPrintSubject_subject_subjectCategory_parent {
  __typename: "SubjectCategory";
  title: string;
}

export interface GetPrintSubject_subject_subjectCategory {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
  parent: GetPrintSubject_subject_subjectCategory_parent | null;
}

export interface GetPrintSubject_subject_assignments_assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  mediaSearchStatus: MediaSearchStatus;
}

export interface GetPrintSubject_subject_assignments_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetPrintSubject_subject_assignments_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetPrintSubject_subject_assignments_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetPrintSubject_subject_assignments_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  key: string;
  title: string;
}

export interface GetPrintSubject_subject_assignments {
  __typename: "FlatAssignment";
  assignment: GetPrintSubject_subject_assignments_assignment;
  printPublication: GetPrintSubject_subject_assignments_printPublication | null;
  printIssue: GetPrintSubject_subject_assignments_printIssue | null;
  printHeading: GetPrintSubject_subject_assignments_printHeading | null;
  website: GetPrintSubject_subject_assignments_website | null;
}

export interface GetPrintSubject_subject {
  __typename: "Subject";
  title: string;
  synopsis: string | null;
  comment: string | null;
  mediaRequest: string | null;
  subjectCategory: GetPrintSubject_subject_subjectCategory | null;
  assignments: GetPrintSubject_subject_assignments[];
}

export interface GetPrintSubject {
  /**
   * Retrieves one "Subject", throws an error if it does not exist
   */
  subject: GetPrintSubject_subject;
}

export interface GetPrintSubjectVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubjectCategory
// ====================================================

export interface GetSubjectCategory_subjectCategories_parent {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
}

export interface GetSubjectCategory_subjectCategories_children {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetSubjectCategory_subjectCategories {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
  parent: GetSubjectCategory_subjectCategories_parent | null;
  /**
   * This category's children
   */
  children: GetSubjectCategory_subjectCategories_children[];
}

export interface GetSubjectCategory {
  /**
   * Retrieves a list of "SubjectCategories"
   */
  subjectCategories: GetSubjectCategory_subjectCategories[];
}

export interface GetSubjectCategoryVariables {
  unitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SubjectListPage_GetCategoryTree
// ====================================================

export interface SubjectListPage_GetCategoryTree_subjectCategories_children {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface SubjectListPage_GetCategoryTree_subjectCategories {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
  /**
   * This category's children
   */
  children: SubjectListPage_GetCategoryTree_subjectCategories_children[];
}

export interface SubjectListPage_GetCategoryTree {
  /**
   * Retrieves a list of "SubjectCategories"
   */
  subjectCategories: SubjectListPage_GetCategoryTree_subjectCategories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SubjectListPage_GetFilteredCategoryTree
// ====================================================

export interface SubjectListPage_GetFilteredCategoryTree_subjectCategories_children {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface SubjectListPage_GetFilteredCategoryTree_subjectCategories {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
  /**
   * This category's children
   */
  children: SubjectListPage_GetFilteredCategoryTree_subjectCategories_children[];
}

export interface SubjectListPage_GetFilteredCategoryTree {
  /**
   * Retrieves a list of "SubjectCategories"
   */
  subjectCategories: SubjectListPage_GetFilteredCategoryTree_subjectCategories[];
}

export interface SubjectListPage_GetFilteredCategoryTreeVariables {
  where: SubjectCategoryFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SubjectListPage_GetFilteredLocationTree
// ====================================================

export interface SubjectListPage_GetFilteredLocationTree_brands_printPublications_printIssues {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface SubjectListPage_GetFilteredLocationTree_brands_printPublications {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printIssues: SubjectListPage_GetFilteredLocationTree_brands_printPublications_printIssues[];
}

export interface SubjectListPage_GetFilteredLocationTree_brands_websites {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
}

export interface SubjectListPage_GetFilteredLocationTree_brands {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublications: SubjectListPage_GetFilteredLocationTree_brands_printPublications[];
  websites: SubjectListPage_GetFilteredLocationTree_brands_websites[];
}

export interface SubjectListPage_GetFilteredLocationTree {
  /**
   * Retrieves a list of "Brands"
   */
  brands: SubjectListPage_GetFilteredLocationTree_brands[];
}

export interface SubjectListPage_GetFilteredLocationTreeVariables {
  searchString: GraphQL$NonEmptyString;
  where: BrandFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SubjectListPage_GetLocationTree
// ====================================================

export interface SubjectListPage_GetLocationTree_brands_printPublications_printIssues {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface SubjectListPage_GetLocationTree_brands_printPublications {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printIssues: SubjectListPage_GetLocationTree_brands_printPublications_printIssues[];
}

export interface SubjectListPage_GetLocationTree_brands_websites {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
}

export interface SubjectListPage_GetLocationTree_brands {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublications: SubjectListPage_GetLocationTree_brands_printPublications[];
  websites: SubjectListPage_GetLocationTree_brands_websites[];
}

export interface SubjectListPage_GetLocationTree {
  /**
   * Retrieves a list of "Brands"
   */
  brands: SubjectListPage_GetLocationTree_brands[];
}

export interface SubjectListPage_GetLocationTreeVariables {
  where: BrandFilterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSubjects
// ====================================================

export interface GetSubjects_subjects_assignments_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetSubjects_subjects_assignments_articleTemplate {
  __typename: "ArticleTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetSubjects_subjects_assignments_rawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetSubjects_subjects_assignments_rawArticle_versions {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: GetSubjects_subjects_assignments_rawArticle_versions_editor | null;
}

export interface GetSubjects_subjects_assignments_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: GetSubjects_subjects_assignments_rawArticle_versions[];
}

export interface GetSubjects_subjects_assignments_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * template type
   */
  type: TemplateType | null;
}

export interface GetSubjects_subjects_assignments_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  printTemplate: GetSubjects_subjects_assignments_printHeading_printTemplates_printTemplate;
}

export interface GetSubjects_subjects_assignments_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetSubjects_subjects_assignments_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: GetSubjects_subjects_assignments_printHeading_printIssue_printPublication;
}

export interface GetSubjects_subjects_assignments_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  autoPrototype: boolean | null;
  printTemplates: GetSubjects_subjects_assignments_printHeading_printTemplates[];
  printIssue: GetSubjects_subjects_assignments_printHeading_printIssue;
}

export interface GetSubjects_subjects_assignments_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetSubjects_subjects_assignments_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: GetSubjects_subjects_assignments_printIssue_printPublication;
}

export interface GetSubjects_subjects_assignments_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetSubjects_subjects_assignments_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
}

export interface GetSubjects_subjects_assignments_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
}

export interface GetSubjects_subjects_assignments {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printTemplate: GetSubjects_subjects_assignments_printTemplate | null;
  articleTemplate: GetSubjects_subjects_assignments_articleTemplate | null;
  mediaSearchStatus: MediaSearchStatus;
  rawArticle: GetSubjects_subjects_assignments_rawArticle | null;
  printHeading: GetSubjects_subjects_assignments_printHeading | null;
  printIssue: GetSubjects_subjects_assignments_printIssue | null;
  printPublication: GetSubjects_subjects_assignments_printPublication | null;
  website: GetSubjects_subjects_assignments_website | null;
  subject: GetSubjects_subjects_assignments_subject;
}

export interface GetSubjects_subjects_subjectCategory_parent {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetSubjects_subjects_subjectCategory {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
  parent: GetSubjects_subjects_subjectCategory_parent | null;
}

export interface GetSubjects_subjects_unit_brands {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetSubjects_subjects_unit {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
  photoArchiveId: number | null;
  brands: GetSubjects_subjects_unit_brands[];
}

export interface GetSubjects_subjects {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
  comment: string | null;
  assignments: GetSubjects_subjects_assignments[];
  /**
   * Number of "assignments"
   */
  assignmentCount: GraphQL$UnsignedInt;
  subjectCategory: GetSubjects_subjects_subjectCategory | null;
  unit: GetSubjects_subjects_unit;
  isClassified: boolean;
}

export interface GetSubjects {
  /**
   * Retrieves a list of "Subjects"
   */
  subjects: GetSubjects_subjects[];
}

export interface GetSubjectsVariables {
  where: SubjectFilterInput;
  skip: GraphQL$UnsignedInt;
  first?: GraphQL$UnsignedInt | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUnfilteredSubjectListByIssue
// ====================================================

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * template type
   */
  type: TemplateType | null;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  printTemplate: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_printTemplates_printTemplate;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_subjectCategory_parent {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_subjectCategory {
  __typename: "SubjectCategory";
  /**
   * The public ID
   */
  id: string;
  title: string;
  parent: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_subjectCategory_parent | null;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_assignment_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_assignment_articleTemplate {
  __typename: "ArticleTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printTemplate: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_assignment_printTemplate | null;
  articleTemplate: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_assignment_articleTemplate | null;
  mediaSearchStatus: MediaSearchStatus;
  pageNumber: number | null;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  key: string;
  title: string;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * template type
   */
  type: TemplateType | null;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  printTemplate: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_printHeading_printTemplates_printTemplate;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  autoPrototype: boolean | null;
  printTemplates: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_printHeading_printTemplates[];
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_rawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_rawArticle_versions {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  status: RawArticleStatus;
  title: string | null;
  editor: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_rawArticle_versions_editor | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_rawArticle_versions[];
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  key: string;
  title: string;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments {
  __typename: "FlatAssignment";
  assignment: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_assignment;
  printPublication: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_printPublication | null;
  printIssue: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_printIssue | null;
  printHeading: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_printHeading | null;
  rawArticle: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_rawArticle | null;
  website: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments_website | null;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
  comment: string | null;
  subjectCategory: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_subjectCategory | null;
  isClassified: boolean;
  assignments: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject_assignments[];
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_rawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_rawArticle_versions {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  status: RawArticleStatus;
  title: string | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_rawArticle_versions_editor | null;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_rawArticle_versions[];
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_articleTemplate {
  __typename: "ArticleTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printTemplate: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_printTemplate | null;
  mediaSearchStatus: MediaSearchStatus;
  pageNumber: number | null;
  subject: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_subject;
  website: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_website | null;
  rawArticle: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_rawArticle | null;
  articleTemplate: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments_articleTemplate | null;
}

export interface GetUnfilteredSubjectListByIssue_printIssue_printHeadings {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  autoPrototype: boolean | null;
  printTemplates: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_printTemplates[];
  assignments: GetUnfilteredSubjectListByIssue_printIssue_printHeadings_assignments[];
}

export interface GetUnfilteredSubjectListByIssue_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printHeadings: GetUnfilteredSubjectListByIssue_printIssue_printHeadings[];
}

export interface GetUnfilteredSubjectListByIssue {
  /**
   * Retrieves one "PrintIssue", throws an error if it does not exist
   */
  printIssue: GetUnfilteredSubjectListByIssue_printIssue;
}

export interface GetUnfilteredSubjectListByIssueVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SubjectListPage_GetPrintPublicationsByUnit
// ====================================================

export interface SubjectListPage_GetPrintPublicationsByUnit_units_brands_printPublications {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface SubjectListPage_GetPrintPublicationsByUnit_units_brands {
  __typename: "Brand";
  title: string;
  printPublications: SubjectListPage_GetPrintPublicationsByUnit_units_brands_printPublications[];
}

export interface SubjectListPage_GetPrintPublicationsByUnit_units {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
  brands: SubjectListPage_GetPrintPublicationsByUnit_units_brands[];
}

export interface SubjectListPage_GetPrintPublicationsByUnit {
  /**
   * Retrieves a list of "Units"
   */
  units: SubjectListPage_GetPrintPublicationsByUnit_units[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: printTemplate
// ====================================================

export interface printTemplate_printTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface printTemplate_printTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface printTemplate_printTemplate_fields_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface printTemplate_printTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface printTemplate_printTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: printTemplate_printTemplate_fields_page_coordinates;
}

export interface printTemplate_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  enabled: boolean | null;
  data: string;
  typeVariation: string;
  paragraphStyles: printTemplate_printTemplate_fields_paragraphStyles[];
  characterStyles: printTemplate_printTemplate_fields_characterStyles[];
  coordinates: printTemplate_printTemplate_fields_coordinates;
  page: printTemplate_printTemplate_fields_page;
}

export interface printTemplate_printTemplate_createdBy {
  __typename: "User";
  name: string;
}

export interface printTemplate_printTemplate_updatedBy {
  __typename: "User";
  name: string;
}

export interface printTemplate_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * associated S3 object id
   */
  S3ObjectId: string;
  /**
   * template label
   */
  label: string;
  /**
   * InDesign file name
   */
  filename: string;
  /**
   * template type
   */
  type: TemplateType | null;
  /**
   * InDesign file size
   */
  filesize: number | null;
  /**
   * Template page count
   */
  pageCount: number | null;
  /**
   * KAMI extension version
   */
  extVersion: string | null;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (printTemplate_printTemplate_fields | null)[];
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  createdBy: printTemplate_printTemplate_createdBy | null;
  updatedBy: printTemplate_printTemplate_updatedBy | null;
}

export interface printTemplate {
  __typename: "PrintHeadingPrintTemplate";
  printTemplate: printTemplate_printTemplate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PrintTemplate
// ====================================================

export interface PrintTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface PrintTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface PrintTemplate_fields_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface PrintTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface PrintTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: PrintTemplate_fields_page_coordinates;
}

export interface PrintTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  enabled: boolean | null;
  data: string;
  typeVariation: string;
  paragraphStyles: PrintTemplate_fields_paragraphStyles[];
  characterStyles: PrintTemplate_fields_characterStyles[];
  coordinates: PrintTemplate_fields_coordinates;
  page: PrintTemplate_fields_page;
}

export interface PrintTemplate_printHeadings_printHeading_printIssue_printPublication_brand {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface PrintTemplate_printHeadings_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
  brand: PrintTemplate_printHeadings_printHeading_printIssue_printPublication_brand;
}

export interface PrintTemplate_printHeadings_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: PrintTemplate_printHeadings_printHeading_printIssue_printPublication;
}

export interface PrintTemplate_printHeadings_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printIssue: PrintTemplate_printHeadings_printHeading_printIssue;
}

export interface PrintTemplate_printHeadings {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  printHeading: PrintTemplate_printHeadings_printHeading;
}

export interface PrintTemplate_createdBy {
  __typename: "User";
  name: string;
}

export interface PrintTemplate_updatedBy {
  __typename: "User";
  name: string;
}

export interface PrintTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * associated S3 object id
   */
  S3ObjectId: string;
  /**
   * template label
   */
  label: string;
  /**
   * InDesign file name
   */
  filename: string;
  /**
   * template type
   */
  type: TemplateType | null;
  /**
   * InDesign file size
   */
  filesize: number | null;
  /**
   * Template page count
   */
  pageCount: number | null;
  /**
   * KAMI extension version
   */
  extVersion: string | null;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (PrintTemplate_fields | null)[];
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  printHeadings: PrintTemplate_printHeadings[];
  createdBy: PrintTemplate_createdBy | null;
  updatedBy: PrintTemplate_updatedBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PrintTemplateAssignment
// ====================================================

export interface PrintTemplateAssignment_printTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  height: number | null;
  width: number | null;
  rotation: number | null;
}

export interface PrintTemplateAssignment_printTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  coordinates: PrintTemplateAssignment_printTemplate_fields_page_coordinates;
}

export interface PrintTemplateAssignment_printTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface PrintTemplateAssignment_printTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface PrintTemplateAssignment_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  page: PrintTemplateAssignment_printTemplate_fields_page;
  data: string;
  typeVariation: string;
  paragraphStyles: PrintTemplateAssignment_printTemplate_fields_paragraphStyles[];
  characterStyles: PrintTemplateAssignment_printTemplate_fields_characterStyles[];
}

export interface PrintTemplateAssignment_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * template label
   */
  label: string;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (PrintTemplateAssignment_printTemplate_fields | null)[];
}

export interface PrintTemplateAssignment {
  __typename: "Assignment";
  printTemplate: PrintTemplateAssignment_printTemplate | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ArticleForm_Assignment
// ====================================================

export interface ArticleForm_Assignment_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface ArticleForm_Assignment_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface ArticleForm_Assignment_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: ArticleForm_Assignment_printIssue_printPublication;
}

export interface ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields_page_coordinates;
}

export interface ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  enabled: boolean | null;
  data: string;
  typeVariation: string;
  paragraphStyles: ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields_paragraphStyles[];
  characterStyles: ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields_characterStyles[];
  coordinates: ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields_coordinates;
  page: ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields_page;
}

export interface ArticleForm_Assignment_printHeading_printTemplates_printTemplate_createdBy {
  __typename: "User";
  name: string;
}

export interface ArticleForm_Assignment_printHeading_printTemplates_printTemplate_updatedBy {
  __typename: "User";
  name: string;
}

export interface ArticleForm_Assignment_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * associated S3 object id
   */
  S3ObjectId: string;
  /**
   * template label
   */
  label: string;
  /**
   * InDesign file name
   */
  filename: string;
  /**
   * template type
   */
  type: TemplateType | null;
  /**
   * InDesign file size
   */
  filesize: number | null;
  /**
   * Template page count
   */
  pageCount: number | null;
  /**
   * KAMI extension version
   */
  extVersion: string | null;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (ArticleForm_Assignment_printHeading_printTemplates_printTemplate_fields | null)[];
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  createdBy: ArticleForm_Assignment_printHeading_printTemplates_printTemplate_createdBy | null;
  updatedBy: ArticleForm_Assignment_printHeading_printTemplates_printTemplate_updatedBy | null;
}

export interface ArticleForm_Assignment_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  printTemplate: ArticleForm_Assignment_printHeading_printTemplates_printTemplate;
}

export interface ArticleForm_Assignment_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface ArticleForm_Assignment_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: ArticleForm_Assignment_printHeading_printIssue_printPublication;
}

export interface ArticleForm_Assignment_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  autoPrototype: boolean | null;
  printTemplates: ArticleForm_Assignment_printHeading_printTemplates[];
  printIssue: ArticleForm_Assignment_printHeading_printIssue;
}

export interface ArticleForm_Assignment_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
}

export interface ArticleForm_Assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printPublication: ArticleForm_Assignment_printPublication | null;
  printIssue: ArticleForm_Assignment_printIssue | null;
  printHeading: ArticleForm_Assignment_printHeading | null;
  website: ArticleForm_Assignment_website | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ArticleForm_RawArticle
// ====================================================

export interface ArticleForm_RawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface ArticleForm_RawArticle_versions {
  __typename: "RawArticleVersion";
  allowedStatuses: RawArticleStatus[];
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  /**
   * An array of left over Draft content
   */
  leftOver: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: ArticleForm_RawArticle_versions_editor | null;
  textIsReadOnly: boolean;
}

export interface ArticleForm_RawArticle_versionList_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface ArticleForm_RawArticle_versionList {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  status: RawArticleStatus;
  /**
   * The user assigned to the version
   */
  editorId: string;
  editor: ArticleForm_RawArticle_versionList_editor | null;
}

export interface ArticleForm_RawArticle_assignment_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface ArticleForm_RawArticle_assignment_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface ArticleForm_RawArticle_assignment_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: ArticleForm_RawArticle_assignment_printIssue_printPublication;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields_page_coordinates;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  enabled: boolean | null;
  data: string;
  typeVariation: string;
  paragraphStyles: ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields_paragraphStyles[];
  characterStyles: ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields_characterStyles[];
  coordinates: ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields_coordinates;
  page: ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields_page;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_createdBy {
  __typename: "User";
  name: string;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_updatedBy {
  __typename: "User";
  name: string;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * associated S3 object id
   */
  S3ObjectId: string;
  /**
   * template label
   */
  label: string;
  /**
   * InDesign file name
   */
  filename: string;
  /**
   * template type
   */
  type: TemplateType | null;
  /**
   * InDesign file size
   */
  filesize: number | null;
  /**
   * Template page count
   */
  pageCount: number | null;
  /**
   * KAMI extension version
   */
  extVersion: string | null;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_fields | null)[];
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  createdBy: ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_createdBy | null;
  updatedBy: ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate_updatedBy | null;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  printTemplate: ArticleForm_RawArticle_assignment_printHeading_printTemplates_printTemplate;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface ArticleForm_RawArticle_assignment_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: ArticleForm_RawArticle_assignment_printHeading_printIssue_printPublication;
}

export interface ArticleForm_RawArticle_assignment_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  autoPrototype: boolean | null;
  printTemplates: ArticleForm_RawArticle_assignment_printHeading_printTemplates[];
  printIssue: ArticleForm_RawArticle_assignment_printHeading_printIssue;
}

export interface ArticleForm_RawArticle_assignment_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
}

export interface ArticleForm_RawArticle_assignment_printTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  height: number | null;
  width: number | null;
  rotation: number | null;
}

export interface ArticleForm_RawArticle_assignment_printTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  coordinates: ArticleForm_RawArticle_assignment_printTemplate_fields_page_coordinates;
}

export interface ArticleForm_RawArticle_assignment_printTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface ArticleForm_RawArticle_assignment_printTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface ArticleForm_RawArticle_assignment_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  page: ArticleForm_RawArticle_assignment_printTemplate_fields_page;
  data: string;
  typeVariation: string;
  paragraphStyles: ArticleForm_RawArticle_assignment_printTemplate_fields_paragraphStyles[];
  characterStyles: ArticleForm_RawArticle_assignment_printTemplate_fields_characterStyles[];
}

export interface ArticleForm_RawArticle_assignment_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * template label
   */
  label: string;
  /**
   * KAMI extension data
   */
  data: string;
  fields: (ArticleForm_RawArticle_assignment_printTemplate_fields | null)[];
}

export interface ArticleForm_RawArticle_assignment_subject_unit {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface ArticleForm_RawArticle_assignment_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
  title: string;
  synopsis: string | null;
  unit: ArticleForm_RawArticle_assignment_subject_unit;
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_fields_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  width: number | null;
  height: number | null;
  rotation: number | null;
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  number: string;
  coordinates: ArticleForm_RawArticle_assignment_articleTemplate_fields_page_coordinates;
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  enabled: boolean | null;
  data: string;
  typeVariation: string;
  paragraphStyles: ArticleForm_RawArticle_assignment_articleTemplate_fields_paragraphStyles[];
  characterStyles: ArticleForm_RawArticle_assignment_articleTemplate_fields_characterStyles[];
  coordinates: ArticleForm_RawArticle_assignment_articleTemplate_fields_coordinates;
  page: ArticleForm_RawArticle_assignment_articleTemplate_fields_page;
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_printTemplate_fields_page_coordinates {
  __typename: "PrintTemplateObjectCoords";
  x: number | null;
  y: number | null;
  height: number | null;
  width: number | null;
  rotation: number | null;
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_printTemplate_fields_page {
  __typename: "PrintTemplatePage";
  offset: number;
  coordinates: ArticleForm_RawArticle_assignment_articleTemplate_printTemplate_fields_page_coordinates;
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_printTemplate_fields_paragraphStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_printTemplate_fields_characterStyles {
  __typename: "PrintTemplateFieldStyle";
  id: string;
  name: string;
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_printTemplate_fields {
  __typename: "PrintTemplateField";
  id: number;
  name: string;
  type: PrintTemplateFieldType;
  page: ArticleForm_RawArticle_assignment_articleTemplate_printTemplate_fields_page;
  data: string;
  typeVariation: string;
  paragraphStyles: ArticleForm_RawArticle_assignment_articleTemplate_printTemplate_fields_paragraphStyles[];
  characterStyles: ArticleForm_RawArticle_assignment_articleTemplate_printTemplate_fields_characterStyles[];
}

export interface ArticleForm_RawArticle_assignment_articleTemplate_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * template type
   */
  type: TemplateType | null;
  fields: (ArticleForm_RawArticle_assignment_articleTemplate_printTemplate_fields | null)[];
}

export interface ArticleForm_RawArticle_assignment_articleTemplate {
  __typename: "ArticleTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * The article template label
   */
  label: string | null;
  /**
   * The raw datas
   */
  block: string | null;
  fields: (ArticleForm_RawArticle_assignment_articleTemplate_fields | null)[];
  printTemplate: ArticleForm_RawArticle_assignment_articleTemplate_printTemplate;
}

export interface ArticleForm_RawArticle_assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printPublication: ArticleForm_RawArticle_assignment_printPublication | null;
  printIssue: ArticleForm_RawArticle_assignment_printIssue | null;
  printHeading: ArticleForm_RawArticle_assignment_printHeading | null;
  website: ArticleForm_RawArticle_assignment_website | null;
  printTemplate: ArticleForm_RawArticle_assignment_printTemplate | null;
  subject: ArticleForm_RawArticle_assignment_subject;
  articleTemplate: ArticleForm_RawArticle_assignment_articleTemplate | null;
}

export interface ArticleForm_RawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: ArticleForm_RawArticle_versions[];
  versionList: ArticleForm_RawArticle_versionList[];
  assignment: ArticleForm_RawArticle_assignment | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RawArticleItem
// ====================================================

export interface RawArticleItem_versions_editor {
  __typename: "User";
  id: string;
  name: string;
}

export interface RawArticleItem_versions {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: RawArticleItem_versions_editor | null;
}

export interface RawArticleItem {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: RawArticleItem_versions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AssignmentItem
// ====================================================

export interface AssignmentItem_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface AssignmentItem_articleTemplate {
  __typename: "ArticleTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface AssignmentItem_rawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
}

export interface AssignmentItem_rawArticle_versions {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: AssignmentItem_rawArticle_versions_editor | null;
}

export interface AssignmentItem_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: AssignmentItem_rawArticle_versions[];
}

export interface AssignmentItem_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * template type
   */
  type: TemplateType | null;
}

export interface AssignmentItem_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  printTemplate: AssignmentItem_printHeading_printTemplates_printTemplate;
}

export interface AssignmentItem_printHeading_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface AssignmentItem_printHeading_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: AssignmentItem_printHeading_printIssue_printPublication;
}

export interface AssignmentItem_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  autoPrototype: boolean | null;
  printTemplates: AssignmentItem_printHeading_printTemplates[];
  printIssue: AssignmentItem_printHeading_printIssue;
}

export interface AssignmentItem_printIssue_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface AssignmentItem_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printPublication: AssignmentItem_printIssue_printPublication;
}

export interface AssignmentItem_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface AssignmentItem_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
}

export interface AssignmentItem_subject {
  __typename: "Subject";
  /**
   * The public ID
   */
  id: string;
}

export interface AssignmentItem {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printTemplate: AssignmentItem_printTemplate | null;
  articleTemplate: AssignmentItem_articleTemplate | null;
  mediaSearchStatus: MediaSearchStatus;
  rawArticle: AssignmentItem_rawArticle | null;
  printHeading: AssignmentItem_printHeading | null;
  printIssue: AssignmentItem_printIssue | null;
  printPublication: AssignmentItem_printPublication | null;
  website: AssignmentItem_website | null;
  subject: AssignmentItem_subject;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SubjectUser
// ====================================================

export interface SubjectUser_user {
  __typename: "User";
  email: string;
  name: string;
  avatarUrl: string | null;
}

export interface SubjectUser {
  __typename: "SubjectUser";
  userId: string;
  user: SubjectUser_user | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UnitSpeedDial_Fragment
// ====================================================

export interface UnitSpeedDial_Fragment {
  __typename: "Unit";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ArticleForm_RawArticleVersionFragment
// ====================================================

export interface ArticleForm_RawArticleVersionFragment_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface ArticleForm_RawArticleVersionFragment {
  __typename: "RawArticleVersion";
  allowedStatuses: RawArticleStatus[];
  /**
   * The public ID
   */
  id: string;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  /**
   * An array of left over Draft content
   */
  leftOver: string | null;
  status: RawArticleStatus;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: ArticleForm_RawArticleVersionFragment_editor | null;
  textIsReadOnly: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ArticleHistory_MajorVersion
// ====================================================

export interface ArticleHistory_MajorVersion_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface ArticleHistory_MajorVersion_previousMinorVersions_editor {
  __typename: "User";
  id: string;
  name: string;
  avatarUrl: string | null;
}

export interface ArticleHistory_MajorVersion_previousMinorVersions {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  editor: ArticleHistory_MajorVersion_previousMinorVersions_editor | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
}

export interface ArticleHistory_MajorVersion {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  status: RawArticleStatus;
  title: string | null;
  lead: string | null;
  body: string | null;
  signature: string | null;
  /**
   * An array of left over Draft content
   */
  leftOver: string | null;
  /**
   * The user assigned to the version
   */
  editorId: string;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  editor: ArticleHistory_MajorVersion_editor | null;
  previousMinorVersions: ArticleHistory_MajorVersion_previousMinorVersions[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PrintHeadingFragment
// ====================================================

export interface PrintHeadingFragment_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface PrintHeadingFragment_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  /**
   * The public ID
   */
  id: string;
  active: boolean;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  /**
   * The date and time of the document's last update
   */
  updatedAt: GraphQL$DateTime;
  printTemplate: PrintHeadingFragment_printTemplates_printTemplate;
}

export interface PrintHeadingFragment {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  order: number;
  hasAutomaticSubject: boolean;
  autoPrototype: boolean | null;
  /**
   * Number of "printTemplates"
   */
  printTemplateCount: GraphQL$UnsignedInt;
  /**
   * Number of "assignments"
   */
  assignmentCount: GraphQL$UnsignedInt;
  printTemplates: PrintHeadingFragment_printTemplates[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PrintIssueFragment
// ====================================================

export interface PrintIssueFragment_printHeadings {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  order: number;
  hasAutomaticSubject: boolean;
}

export interface PrintIssueFragment {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
  printHeadings: PrintIssueFragment_printHeadings[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PrintPublicationFragment
// ====================================================

export interface PrintPublicationFragment_brand {
  __typename: "Brand";
  /**
   * The public ID
   */
  id: string;
}

export interface PrintPublicationFragment {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
  key: string;
  brand: PrintPublicationFragment_brand;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AttachmentFragment
// ====================================================

export interface AttachmentFragment_createdBy {
  __typename: "User";
  name: string;
}

export interface AttachmentFragment {
  __typename: "Attachment";
  /**
   * The public ID
   */
  id: string;
  /**
   * sent from the front
   */
  filename: string;
  /**
   * update from S3
   */
  filesize: number | null;
  /**
   * update from S3
   */
  contentType: string | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
  createdBy: AttachmentFragment_createdBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CreateAttachmentFragment
// ====================================================

export interface CreateAttachmentFragment {
  __typename: "Attachment";
  /**
   * The public ID
   */
  id: string;
  /**
   * get presigned url from S3
   */
  uploadUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DownloadAttachmentFragment
// ====================================================

export interface DownloadAttachmentFragment {
  __typename: "Attachment";
  downloadUrl: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SubjectForm_FlatAssignment
// ====================================================

export interface SubjectForm_FlatAssignment_assignment_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface SubjectForm_FlatAssignment_assignment_articleTemplate {
  __typename: "ArticleTemplate";
  /**
   * The public ID
   */
  id: string;
}

export interface SubjectForm_FlatAssignment_assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  printTemplate: SubjectForm_FlatAssignment_assignment_printTemplate | null;
  articleTemplate: SubjectForm_FlatAssignment_assignment_articleTemplate | null;
  mediaSearchStatus: MediaSearchStatus;
  pageNumber: number | null;
}

export interface SubjectForm_FlatAssignment_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  key: string;
  title: string;
}

export interface SubjectForm_FlatAssignment_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface SubjectForm_FlatAssignment_printHeading_printTemplates_printTemplate {
  __typename: "PrintTemplate";
  /**
   * The public ID
   */
  id: string;
  /**
   * template type
   */
  type: TemplateType | null;
}

export interface SubjectForm_FlatAssignment_printHeading_printTemplates {
  __typename: "PrintHeadingPrintTemplate";
  printTemplate: SubjectForm_FlatAssignment_printHeading_printTemplates_printTemplate;
}

export interface SubjectForm_FlatAssignment_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
  autoPrototype: boolean | null;
  printTemplates: SubjectForm_FlatAssignment_printHeading_printTemplates[];
}

export interface SubjectForm_FlatAssignment_rawArticle_versions_editor {
  __typename: "User";
  id: string;
  name: string;
}

export interface SubjectForm_FlatAssignment_rawArticle_versions {
  __typename: "RawArticleVersion";
  /**
   * The public ID
   */
  id: string;
  status: RawArticleStatus;
  title: string | null;
  editor: SubjectForm_FlatAssignment_rawArticle_versions_editor | null;
  /**
   * The date and time, fixed, of the document's creation
   */
  createdAt: GraphQL$DateTime;
}

export interface SubjectForm_FlatAssignment_rawArticle {
  __typename: "RawArticle";
  /**
   * The public ID
   */
  id: string;
  versions: SubjectForm_FlatAssignment_rawArticle_versions[];
}

export interface SubjectForm_FlatAssignment_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  key: string;
  title: string;
}

export interface SubjectForm_FlatAssignment {
  __typename: "FlatAssignment";
  assignment: SubjectForm_FlatAssignment_assignment;
  printPublication: SubjectForm_FlatAssignment_printPublication | null;
  printIssue: SubjectForm_FlatAssignment_printIssue | null;
  printHeading: SubjectForm_FlatAssignment_printHeading | null;
  rawArticle: SubjectForm_FlatAssignment_rawArticle | null;
  website: SubjectForm_FlatAssignment_website | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PrintSubject_FlatAssignment
// ====================================================

export interface PrintSubject_FlatAssignment_assignment {
  __typename: "Assignment";
  /**
   * The public ID
   */
  id: string;
  mediaSearchStatus: MediaSearchStatus;
}

export interface PrintSubject_FlatAssignment_printPublication {
  __typename: "PrintPublication";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface PrintSubject_FlatAssignment_printIssue {
  __typename: "PrintIssue";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface PrintSubject_FlatAssignment_printHeading {
  __typename: "PrintHeading";
  /**
   * The public ID
   */
  id: string;
  title: string;
}

export interface PrintSubject_FlatAssignment_website {
  __typename: "Website";
  /**
   * The public ID
   */
  id: string;
  key: string;
  title: string;
}

export interface PrintSubject_FlatAssignment {
  __typename: "FlatAssignment";
  assignment: PrintSubject_FlatAssignment_assignment;
  printPublication: PrintSubject_FlatAssignment_printPublication | null;
  printIssue: PrintSubject_FlatAssignment_printIssue | null;
  printHeading: PrintSubject_FlatAssignment_printHeading | null;
  website: PrintSubject_FlatAssignment_website | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum MediaSearchStatus {
  Ready = "Ready",
  Sent = "Sent",
  Started = "Started",
  Waiting = "Waiting",
}

export enum PrintTemplateDraftFormatType {
  INDD = "INDD",
  PDF = "PDF",
  PNG = "PNG",
}

export enum PrintTemplateFieldType {
  GRAPHIC_TYPE = "GRAPHIC_TYPE",
  TEXT_TYPE = "TEXT_TYPE",
  UNASSIGNED = "UNASSIGNED",
}

export enum RawArticleStatus {
  Correcting = "Correcting",
  Correction = "Correction",
  Editing = "Editing",
  Mockup = "Mockup",
  Validating = "Validating",
  Validation = "Validation",
}

export enum TemplateType {
  ARTICLE_TEMPLATE = "ARTICLE_TEMPLATE",
  GAME_TEMPLATE = "GAME_TEMPLATE",
  MULTIPLE_ARTICLE_TEMPLATE = "MULTIPLE_ARTICLE_TEMPLATE",
}

export interface ArticleTemplateCreationAssignmentsInput {
  create?: AssignmentCreationWithoutArticleTemplateInput[] | null;
}

/**
 * The "ArticleTemplate" node's creation
 */
export interface ArticleTemplateCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  printTemplate: ArticleTemplateCreationPrintTemplateInput;
  label?: string | null;
  block?: string | null;
  assignments?: ArticleTemplateCreationAssignmentsInput | null;
}

export interface ArticleTemplateCreationPrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface ArticleTemplateCreationPrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: ArticleTemplateCreationPrintTemplateCreateIfNotExistsInput | null;
}

/**
 * The "ArticleTemplate" node's creation
 */
export interface ArticleTemplateCreationWithoutPrintTemplateInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  label?: string | null;
  block?: string | null;
  assignments?: ArticleTemplateCreationAssignmentsInput | null;
}

/**
 * The "ArticleTemplate" nodes' filter
 */
export interface ArticleTemplateFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  printTemplate?: PrintTemplateFilterInput | null;
  printTemplate_not?: PrintTemplateFilterInput | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  label?: string | null;
  label_not?: string | null;
  label_in?: (string | null)[] | null;
  label_not_in?: (string | null)[] | null;
  label_is_null?: boolean | null;
  label_contains?: GraphQL$NonEmptyString | null;
  label_not_contains?: GraphQL$NonEmptyString | null;
  label_starts_with?: GraphQL$NonEmptyString | null;
  label_not_starts_with?: GraphQL$NonEmptyString | null;
  label_ends_with?: GraphQL$NonEmptyString | null;
  label_not_ends_with?: GraphQL$NonEmptyString | null;
  block?: string | null;
  block_not?: string | null;
  block_in?: (string | null)[] | null;
  block_not_in?: (string | null)[] | null;
  block_is_null?: boolean | null;
  block_contains?: GraphQL$NonEmptyString | null;
  block_not_contains?: GraphQL$NonEmptyString | null;
  block_starts_with?: GraphQL$NonEmptyString | null;
  block_not_starts_with?: GraphQL$NonEmptyString | null;
  block_ends_with?: GraphQL$NonEmptyString | null;
  block_not_ends_with?: GraphQL$NonEmptyString | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (ArticleTemplateFilterInput | null)[] | null;
  OR?: (ArticleTemplateFilterInput | null)[] | null;
  NOT?: ArticleTemplateFilterInput | null;
}

/**
 * Identifies exactly one "ArticleTemplate" with one of the following combination of components' value:
 * - _id
 * - id
 */
export interface ArticleTemplateUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
}

export interface ArticleTemplateUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutArticleTemplateInput[] | null;
  updateAll?: AssignmentUpdateWithoutArticleTemplateInput | null;
  updateMany?: ArticleTemplateUpdateAssignmentsUpdateManyInput | null;
}

export interface ArticleTemplateUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutArticleTemplateInput | null;
}

/**
 * The "ArticleTemplate" node's update
 */
export interface ArticleTemplateUpdateInput {
  printTemplate?: ArticleTemplateUpdatePrintTemplateInput | null;
  label?: string | null;
  block?: string | null;
  assignments?: ArticleTemplateUpdateAssignmentsInput | null;
}

export interface ArticleTemplateUpdatePrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface ArticleTemplateUpdatePrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: ArticleTemplateUpdatePrintTemplateCreateIfNotExistsInput | null;
  update?: PrintTemplateUpdateInput | null;
}

/**
 * The "ArticleTemplate" node's update
 */
export interface ArticleTemplateUpdateWithoutPrintTemplateInput {
  label?: string | null;
  block?: string | null;
  assignments?: ArticleTemplateUpdateAssignmentsInput | null;
}

export interface AssignmentCreationArticleTemplateCreateIfNotExistsInput {
  where: ArticleTemplateUniqueFilterInput;
  data: ArticleTemplateCreationInput;
}

export interface AssignmentCreationArticleTemplateInput {
  connect?: ArticleTemplateUniqueFilterInput | null;
  connectIfExists?: ArticleTemplateUniqueFilterInput | null;
  create?: ArticleTemplateCreationInput | null;
  createIfNotExists?: AssignmentCreationArticleTemplateCreateIfNotExistsInput | null;
}

export interface AssignmentCreationPrintHeadingCreateIfNotExistsInput {
  where: PrintHeadingUniqueFilterInput;
  data: PrintHeadingCreationInput;
}

export interface AssignmentCreationPrintHeadingInput {
  connect?: PrintHeadingUniqueFilterInput | null;
  connectIfExists?: PrintHeadingUniqueFilterInput | null;
  create?: PrintHeadingCreationInput | null;
  createIfNotExists?: AssignmentCreationPrintHeadingCreateIfNotExistsInput | null;
}

export interface AssignmentCreationPrintIssueCreateIfNotExistsInput {
  where: PrintIssueUniqueFilterInput;
  data: PrintIssueCreationInput;
}

export interface AssignmentCreationPrintIssueInput {
  connect?: PrintIssueUniqueFilterInput | null;
  connectIfExists?: PrintIssueUniqueFilterInput | null;
  create?: PrintIssueCreationInput | null;
  createIfNotExists?: AssignmentCreationPrintIssueCreateIfNotExistsInput | null;
}

export interface AssignmentCreationPrintPublicationCreateIfNotExistsInput {
  where: PrintPublicationUniqueFilterInput;
  data: PrintPublicationCreationInput;
}

export interface AssignmentCreationPrintPublicationInput {
  connect?: PrintPublicationUniqueFilterInput | null;
  connectIfExists?: PrintPublicationUniqueFilterInput | null;
  create?: PrintPublicationCreationInput | null;
  createIfNotExists?: AssignmentCreationPrintPublicationCreateIfNotExistsInput | null;
}

export interface AssignmentCreationPrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface AssignmentCreationPrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  connectIfExists?: PrintTemplateUniqueFilterInput | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: AssignmentCreationPrintTemplateCreateIfNotExistsInput | null;
}

export interface AssignmentCreationRawArticleCreateIfNotExistsInput {
  where: RawArticleUniqueFilterInput;
  data: RawArticleCreationInput;
}

export interface AssignmentCreationRawArticleInput {
  connect?: RawArticleUniqueFilterInput | null;
  connectIfExists?: RawArticleUniqueFilterInput | null;
  create?: RawArticleCreationInput | null;
  createIfNotExists?: AssignmentCreationRawArticleCreateIfNotExistsInput | null;
}

export interface AssignmentCreationSubjectCreateIfNotExistsInput {
  where: SubjectUniqueFilterInput;
  data: SubjectCreationInput;
}

export interface AssignmentCreationSubjectInput {
  connect?: SubjectUniqueFilterInput | null;
  create?: SubjectCreationInput | null;
  createIfNotExists?: AssignmentCreationSubjectCreateIfNotExistsInput | null;
}

export interface AssignmentCreationWebsiteCreateIfNotExistsInput {
  where: WebsiteUniqueFilterInput;
  data: WebsiteCreationInput;
}

export interface AssignmentCreationWebsiteInput {
  connect?: WebsiteUniqueFilterInput | null;
  connectIfExists?: WebsiteUniqueFilterInput | null;
  create?: WebsiteCreationInput | null;
  createIfNotExists?: AssignmentCreationWebsiteCreateIfNotExistsInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutArticleTemplateInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutPrintHeadingInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutPrintIssueInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutPrintPublicationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutPrintTemplateInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutRawArticleInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutSubjectInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  website?: AssignmentCreationWebsiteInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" node's creation
 */
export interface AssignmentCreationWithoutWebsiteInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentCreationPrintPublicationInput | null;
  printIssue?: AssignmentCreationPrintIssueInput | null;
  printHeading?: AssignmentCreationPrintHeadingInput | null;
  rawArticle?: AssignmentCreationRawArticleInput | null;
  subject: AssignmentCreationSubjectInput;
  articleTemplate?: AssignmentCreationArticleTemplateInput | null;
  printTemplate?: AssignmentCreationPrintTemplateInput | null;
}

/**
 * The "Assignment" nodes' filter
 */
export interface AssignmentFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  mediaSearchStatus?: MediaSearchStatus | null;
  mediaSearchStatus_not?: MediaSearchStatus | null;
  mediaSearchStatus_in?: MediaSearchStatus[] | null;
  mediaSearchStatus_not_in?: MediaSearchStatus[] | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  pageNumber?: number | null;
  pageNumber_not?: number | null;
  pageNumber_in?: (number | null)[] | null;
  pageNumber_not_in?: (number | null)[] | null;
  pageNumber_gt?: number | null;
  pageNumber_gte?: number | null;
  pageNumber_lt?: number | null;
  pageNumber_lte?: number | null;
  pageNumber_is_null?: boolean | null;
  printPublication?: PrintPublicationFilterInput | null;
  printPublication_not?: PrintPublicationFilterInput | null;
  printPublication_is_null?: boolean | null;
  printIssue?: PrintIssueFilterInput | null;
  printIssue_not?: PrintIssueFilterInput | null;
  printIssue_is_null?: boolean | null;
  printHeading?: PrintHeadingFilterInput | null;
  printHeading_not?: PrintHeadingFilterInput | null;
  printHeading_is_null?: boolean | null;
  website?: WebsiteFilterInput | null;
  website_not?: WebsiteFilterInput | null;
  website_is_null?: boolean | null;
  rawArticle?: RawArticleFilterInput | null;
  rawArticle_not?: RawArticleFilterInput | null;
  rawArticle_is_null?: boolean | null;
  subject?: SubjectFilterInput | null;
  subject_not?: SubjectFilterInput | null;
  articleTemplate?: ArticleTemplateFilterInput | null;
  articleTemplate_not?: ArticleTemplateFilterInput | null;
  articleTemplate_is_null?: boolean | null;
  printTemplate?: PrintTemplateFilterInput | null;
  printTemplate_not?: PrintTemplateFilterInput | null;
  printTemplate_is_null?: boolean | null;
  flatAssignment?: FlatAssignmentFilterInput | null;
  flatAssignment_not?: FlatAssignmentFilterInput | null;
  flatAssignment_is_null?: boolean | null;
  AND?: (AssignmentFilterInput | null)[] | null;
  OR?: (AssignmentFilterInput | null)[] | null;
  NOT?: AssignmentFilterInput | null;
}

/**
 * Identifies exactly one "Assignment" with one of the following combination of components' value:
 * - _id
 * - id
 * - subject / legacyId
 * - rawArticle
 */
export interface AssignmentUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  subject?: SubjectUniqueFilterInput | null;
  legacyId?: number | null;
  rawArticle?: RawArticleUniqueFilterInput | null;
}

/**
 * Given a "subject", identifies exactly one "Assignment" with one of the following combination of components' value:
 * - (subject) / legacyId
 */
export interface AssignmentUniqueFilterWithoutSubjectInput {
  legacyId?: number | null;
}

export interface AssignmentUpdateArticleTemplateCreateIfNotExistsInput {
  where: ArticleTemplateUniqueFilterInput;
  data: ArticleTemplateCreationInput;
}

export interface AssignmentUpdateArticleTemplateInput {
  connect?: ArticleTemplateUniqueFilterInput | null;
  connectIfExists?: ArticleTemplateUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: ArticleTemplateCreationInput | null;
  createIfNotExists?: AssignmentUpdateArticleTemplateCreateIfNotExistsInput | null;
  update?: ArticleTemplateUpdateInput | null;
  updateIfExists?: ArticleTemplateUpdateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

export interface AssignmentUpdatePrintHeadingCreateIfNotExistsInput {
  where: PrintHeadingUniqueFilterInput;
  data: PrintHeadingCreationInput;
}

export interface AssignmentUpdatePrintHeadingInput {
  connect?: PrintHeadingUniqueFilterInput | null;
  connectIfExists?: PrintHeadingUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: PrintHeadingCreationInput | null;
  createIfNotExists?: AssignmentUpdatePrintHeadingCreateIfNotExistsInput | null;
  update?: PrintHeadingUpdateInput | null;
  updateIfExists?: PrintHeadingUpdateInput | null;
}

export interface AssignmentUpdatePrintIssueCreateIfNotExistsInput {
  where: PrintIssueUniqueFilterInput;
  data: PrintIssueCreationInput;
}

export interface AssignmentUpdatePrintIssueInput {
  connect?: PrintIssueUniqueFilterInput | null;
  connectIfExists?: PrintIssueUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: PrintIssueCreationInput | null;
  createIfNotExists?: AssignmentUpdatePrintIssueCreateIfNotExistsInput | null;
  update?: PrintIssueUpdateInput | null;
  updateIfExists?: PrintIssueUpdateInput | null;
}

export interface AssignmentUpdatePrintPublicationCreateIfNotExistsInput {
  where: PrintPublicationUniqueFilterInput;
  data: PrintPublicationCreationInput;
}

export interface AssignmentUpdatePrintPublicationInput {
  connect?: PrintPublicationUniqueFilterInput | null;
  connectIfExists?: PrintPublicationUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: PrintPublicationCreationInput | null;
  createIfNotExists?: AssignmentUpdatePrintPublicationCreateIfNotExistsInput | null;
  update?: PrintPublicationUpdateInput | null;
  updateIfExists?: PrintPublicationUpdateInput | null;
}

export interface AssignmentUpdatePrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface AssignmentUpdatePrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  connectIfExists?: PrintTemplateUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: AssignmentUpdatePrintTemplateCreateIfNotExistsInput | null;
  update?: PrintTemplateUpdateInput | null;
  updateIfExists?: PrintTemplateUpdateInput | null;
}

export interface AssignmentUpdateRawArticleCreateIfNotExistsInput {
  where: RawArticleUniqueFilterInput;
  data: RawArticleCreationInput;
}

export interface AssignmentUpdateRawArticleInput {
  connect?: RawArticleUniqueFilterInput | null;
  connectIfExists?: RawArticleUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: RawArticleCreationInput | null;
  createIfNotExists?: AssignmentUpdateRawArticleCreateIfNotExistsInput | null;
  update?: RawArticleUpdateInput | null;
  updateIfExists?: RawArticleUpdateInput | null;
}

export interface AssignmentUpdateSubjectCreateIfNotExistsInput {
  where: SubjectUniqueFilterInput;
  data: SubjectCreationInput;
}

export interface AssignmentUpdateSubjectInput {
  connect?: SubjectUniqueFilterInput | null;
  create?: SubjectCreationInput | null;
  createIfNotExists?: AssignmentUpdateSubjectCreateIfNotExistsInput | null;
  update?: SubjectUpdateInput | null;
}

export interface AssignmentUpdateWebsiteCreateIfNotExistsInput {
  where: WebsiteUniqueFilterInput;
  data: WebsiteCreationInput;
}

export interface AssignmentUpdateWebsiteInput {
  connect?: WebsiteUniqueFilterInput | null;
  connectIfExists?: WebsiteUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: WebsiteCreationInput | null;
  createIfNotExists?: AssignmentUpdateWebsiteCreateIfNotExistsInput | null;
  update?: WebsiteUpdateInput | null;
  updateIfExists?: WebsiteUpdateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutArticleTemplateInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutPrintHeadingInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutPrintIssueInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutPrintPublicationInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutPrintTemplateInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutRawArticleInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutSubjectInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  website?: AssignmentUpdateWebsiteInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Assignment" node's update
 */
export interface AssignmentUpdateWithoutWebsiteInput {
  mediaSearchStatus?: MediaSearchStatus | null;
  legacyId?: number | null;
  pageNumber?: number | null;
  printPublication?: AssignmentUpdatePrintPublicationInput | null;
  printIssue?: AssignmentUpdatePrintIssueInput | null;
  printHeading?: AssignmentUpdatePrintHeadingInput | null;
  rawArticle?: AssignmentUpdateRawArticleInput | null;
  subject?: AssignmentUpdateSubjectInput | null;
  articleTemplate?: AssignmentUpdateArticleTemplateInput | null;
  printTemplate?: AssignmentUpdatePrintTemplateInput | null;
}

/**
 * The "Attachment" node's creation
 */
export interface AttachmentCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  filename: string;
  filesize?: number | null;
  versionId?: string | null;
  contentType?: string | null;
  etag?: string | null;
  isUploaded?: boolean | null;
  subject: AttachmentCreationSubjectInput;
}

export interface AttachmentCreationSubjectCreateIfNotExistsInput {
  where: SubjectUniqueFilterInput;
  data: SubjectCreationInput;
}

export interface AttachmentCreationSubjectInput {
  connect?: SubjectUniqueFilterInput | null;
  create?: SubjectCreationInput | null;
  createIfNotExists?: AttachmentCreationSubjectCreateIfNotExistsInput | null;
}

/**
 * The "Attachment" node's creation
 */
export interface AttachmentCreationWithoutSubjectInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  filename: string;
  filesize?: number | null;
  versionId?: string | null;
  contentType?: string | null;
  etag?: string | null;
  isUploaded?: boolean | null;
}

/**
 * The "Attachment" nodes' filter
 */
export interface AttachmentFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  filename?: string | null;
  filename_not?: string | null;
  filename_in?: string[] | null;
  filename_not_in?: string[] | null;
  filename_contains?: GraphQL$NonEmptyString | null;
  filename_not_contains?: GraphQL$NonEmptyString | null;
  filename_starts_with?: GraphQL$NonEmptyString | null;
  filename_not_starts_with?: GraphQL$NonEmptyString | null;
  filename_ends_with?: GraphQL$NonEmptyString | null;
  filename_not_ends_with?: GraphQL$NonEmptyString | null;
  filesize?: number | null;
  filesize_not?: number | null;
  filesize_in?: (number | null)[] | null;
  filesize_not_in?: (number | null)[] | null;
  filesize_gt?: number | null;
  filesize_gte?: number | null;
  filesize_lt?: number | null;
  filesize_lte?: number | null;
  filesize_is_null?: boolean | null;
  versionId?: string | null;
  versionId_not?: string | null;
  versionId_in?: (string | null)[] | null;
  versionId_not_in?: (string | null)[] | null;
  versionId_is_null?: boolean | null;
  versionId_contains?: GraphQL$NonEmptyString | null;
  versionId_not_contains?: GraphQL$NonEmptyString | null;
  versionId_starts_with?: GraphQL$NonEmptyString | null;
  versionId_not_starts_with?: GraphQL$NonEmptyString | null;
  versionId_ends_with?: GraphQL$NonEmptyString | null;
  versionId_not_ends_with?: GraphQL$NonEmptyString | null;
  contentType?: string | null;
  contentType_not?: string | null;
  contentType_in?: (string | null)[] | null;
  contentType_not_in?: (string | null)[] | null;
  contentType_is_null?: boolean | null;
  contentType_contains?: GraphQL$NonEmptyString | null;
  contentType_not_contains?: GraphQL$NonEmptyString | null;
  contentType_starts_with?: GraphQL$NonEmptyString | null;
  contentType_not_starts_with?: GraphQL$NonEmptyString | null;
  contentType_ends_with?: GraphQL$NonEmptyString | null;
  contentType_not_ends_with?: GraphQL$NonEmptyString | null;
  etag?: string | null;
  etag_not?: string | null;
  etag_in?: (string | null)[] | null;
  etag_not_in?: (string | null)[] | null;
  etag_is_null?: boolean | null;
  etag_contains?: GraphQL$NonEmptyString | null;
  etag_not_contains?: GraphQL$NonEmptyString | null;
  etag_starts_with?: GraphQL$NonEmptyString | null;
  etag_not_starts_with?: GraphQL$NonEmptyString | null;
  etag_ends_with?: GraphQL$NonEmptyString | null;
  etag_not_ends_with?: GraphQL$NonEmptyString | null;
  isUploaded?: boolean | null;
  isUploaded_not?: boolean | null;
  isUploaded_in?: (boolean | null)[] | null;
  isUploaded_not_in?: (boolean | null)[] | null;
  isUploaded_is_null?: boolean | null;
  subject?: SubjectFilterInput | null;
  subject_not?: SubjectFilterInput | null;
  AND?: (AttachmentFilterInput | null)[] | null;
  OR?: (AttachmentFilterInput | null)[] | null;
  NOT?: AttachmentFilterInput | null;
}

/**
 * Identifies exactly one "Attachment" with one of the following combination of components' value:
 * - _id
 * - id
 */
export interface AttachmentUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
}

/**
 * The "Attachment" node's update
 */
export interface AttachmentUpdateInput {
  filename?: string | null;
  filesize?: number | null;
  versionId?: string | null;
  contentType?: string | null;
  etag?: string | null;
  isUploaded?: boolean | null;
  subject?: AttachmentUpdateSubjectInput | null;
}

export interface AttachmentUpdateSubjectCreateIfNotExistsInput {
  where: SubjectUniqueFilterInput;
  data: SubjectCreationInput;
}

export interface AttachmentUpdateSubjectInput {
  connect?: SubjectUniqueFilterInput | null;
  create?: SubjectCreationInput | null;
  createIfNotExists?: AttachmentUpdateSubjectCreateIfNotExistsInput | null;
  update?: SubjectUpdateInput | null;
}

/**
 * The "Attachment" node's update
 */
export interface AttachmentUpdateWithoutSubjectInput {
  filename?: string | null;
  filesize?: number | null;
  versionId?: string | null;
  contentType?: string | null;
  etag?: string | null;
  isUploaded?: boolean | null;
}

/**
 * The "Brand" node's creation
 */
export interface BrandCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  unit: BrandCreationUnitInput;
  printPublications?: BrandCreationPrintPublicationsInput | null;
  websites?: BrandCreationWebsitesInput | null;
}

export interface BrandCreationPrintPublicationsInput {
  create?: PrintPublicationCreationWithoutBrandInput[] | null;
}

export interface BrandCreationUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface BrandCreationUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: BrandCreationUnitCreateIfNotExistsInput | null;
}

export interface BrandCreationWebsitesInput {
  create?: WebsiteCreationWithoutBrandInput[] | null;
}

/**
 * The "Brand" node's creation
 */
export interface BrandCreationWithoutUnitInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  printPublications?: BrandCreationPrintPublicationsInput | null;
  websites?: BrandCreationWebsitesInput | null;
}

/**
 * The "Brand" nodes' filter
 */
export interface BrandFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_gt?: string | null;
  title_gte?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  unit?: UnitFilterInput | null;
  unit_not?: UnitFilterInput | null;
  printPublications_every?: PrintPublicationFilterInput | null;
  printPublications_some?: PrintPublicationFilterInput | null;
  printPublications_none?: PrintPublicationFilterInput | null;
  printPublicationCount?: GraphQL$UnsignedInt | null;
  printPublicationCount_not?: GraphQL$UnsignedInt | null;
  printPublicationCount_gt?: GraphQL$UnsignedInt | null;
  printPublicationCount_gte?: GraphQL$UnsignedInt | null;
  printPublicationCount_lt?: GraphQL$UnsignedInt | null;
  printPublicationCount_lte?: GraphQL$UnsignedInt | null;
  websites_every?: WebsiteFilterInput | null;
  websites_some?: WebsiteFilterInput | null;
  websites_none?: WebsiteFilterInput | null;
  websiteCount?: GraphQL$UnsignedInt | null;
  websiteCount_not?: GraphQL$UnsignedInt | null;
  websiteCount_gt?: GraphQL$UnsignedInt | null;
  websiteCount_gte?: GraphQL$UnsignedInt | null;
  websiteCount_lt?: GraphQL$UnsignedInt | null;
  websiteCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (BrandFilterInput | null)[] | null;
  OR?: (BrandFilterInput | null)[] | null;
  NOT?: BrandFilterInput | null;
}

/**
 * Identifies exactly one "Brand" with one of the following combination of components' value:
 * - _id
 * - id
 * - title
 */
export interface BrandUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title?: string | null;
}

/**
 * The "Brand" node's update
 */
export interface BrandUpdateInput {
  title?: string | null;
  unit?: BrandUpdateUnitInput | null;
  printPublications?: BrandUpdatePrintPublicationsInput | null;
  websites?: BrandUpdateWebsitesInput | null;
}

export interface BrandUpdatePrintPublicationsCreateIfNotExistsInput {
  where: PrintPublicationUniqueFilterWithoutBrandInput;
  data: PrintPublicationCreationWithoutBrandInput;
}

export interface BrandUpdatePrintPublicationsInput {
  deleteAll?: boolean | null;
  deleteMany?: PrintPublicationFilterInput | null;
  delete?: PrintPublicationUniqueFilterWithoutBrandInput[] | null;
  deleteIfExists?: PrintPublicationUniqueFilterWithoutBrandInput[] | null;
  create?: PrintPublicationCreationWithoutBrandInput[] | null;
  createIfNotExists?: BrandUpdatePrintPublicationsCreateIfNotExistsInput[] | null;
  updateAll?: PrintPublicationUpdateWithoutBrandInput | null;
  updateMany?: BrandUpdatePrintPublicationsUpdateManyInput | null;
  update?: BrandUpdatePrintPublicationsUpdateInput[] | null;
  updateIfExists?: BrandUpdatePrintPublicationsUpdateIfExistsInput[] | null;
  upsert?: BrandUpdatePrintPublicationsUpsertInput[] | null;
}

export interface BrandUpdatePrintPublicationsUpdateIfExistsInput {
  where: PrintPublicationUniqueFilterWithoutBrandInput;
  data?: PrintPublicationUpdateWithoutBrandInput | null;
}

export interface BrandUpdatePrintPublicationsUpdateInput {
  where: PrintPublicationUniqueFilterWithoutBrandInput;
  data?: PrintPublicationUpdateWithoutBrandInput | null;
}

export interface BrandUpdatePrintPublicationsUpdateManyInput {
  where?: PrintPublicationFilterInput | null;
  data?: PrintPublicationUpdateWithoutBrandInput | null;
}

export interface BrandUpdatePrintPublicationsUpsertInput {
  where: PrintPublicationUniqueFilterWithoutBrandInput;
  create: PrintPublicationCreationWithoutBrandInput;
  update?: PrintPublicationUpdateWithoutBrandInput | null;
}

export interface BrandUpdateUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface BrandUpdateUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: BrandUpdateUnitCreateIfNotExistsInput | null;
  update?: UnitUpdateInput | null;
}

export interface BrandUpdateWebsitesCreateIfNotExistsInput {
  where: WebsiteUniqueFilterWithoutBrandInput;
  data: WebsiteCreationWithoutBrandInput;
}

export interface BrandUpdateWebsitesInput {
  deleteAll?: boolean | null;
  deleteMany?: WebsiteFilterInput | null;
  delete?: WebsiteUniqueFilterWithoutBrandInput[] | null;
  deleteIfExists?: WebsiteUniqueFilterWithoutBrandInput[] | null;
  create?: WebsiteCreationWithoutBrandInput[] | null;
  createIfNotExists?: BrandUpdateWebsitesCreateIfNotExistsInput[] | null;
  updateAll?: WebsiteUpdateWithoutBrandInput | null;
  updateMany?: BrandUpdateWebsitesUpdateManyInput | null;
  update?: BrandUpdateWebsitesUpdateInput[] | null;
  updateIfExists?: BrandUpdateWebsitesUpdateIfExistsInput[] | null;
  upsert?: BrandUpdateWebsitesUpsertInput[] | null;
}

export interface BrandUpdateWebsitesUpdateIfExistsInput {
  where: WebsiteUniqueFilterWithoutBrandInput;
  data?: WebsiteUpdateWithoutBrandInput | null;
}

export interface BrandUpdateWebsitesUpdateInput {
  where: WebsiteUniqueFilterWithoutBrandInput;
  data?: WebsiteUpdateWithoutBrandInput | null;
}

export interface BrandUpdateWebsitesUpdateManyInput {
  where?: WebsiteFilterInput | null;
  data?: WebsiteUpdateWithoutBrandInput | null;
}

export interface BrandUpdateWebsitesUpsertInput {
  where: WebsiteUniqueFilterWithoutBrandInput;
  create: WebsiteCreationWithoutBrandInput;
  update?: WebsiteUpdateWithoutBrandInput | null;
}

/**
 * The "Brand" node's update
 */
export interface BrandUpdateWithoutUnitInput {
  title?: string | null;
  printPublications?: BrandUpdatePrintPublicationsInput | null;
  websites?: BrandUpdateWebsitesInput | null;
}

/**
 * The "FlatAssignment" nodes' filter
 */
export interface FlatAssignmentFilterInput {
  location?: string | null;
  location_not?: string | null;
  location_in?: string[] | null;
  location_not_in?: string[] | null;
  location_contains?: GraphQL$NonEmptyString | null;
  location_not_contains?: GraphQL$NonEmptyString | null;
  location_starts_with?: GraphQL$NonEmptyString | null;
  location_not_starts_with?: GraphQL$NonEmptyString | null;
  location_ends_with?: GraphQL$NonEmptyString | null;
  location_not_ends_with?: GraphQL$NonEmptyString | null;
  assignmentPageNumber?: number | null;
  assignmentPageNumber_not?: number | null;
  assignmentPageNumber_in?: (number | null)[] | null;
  assignmentPageNumber_not_in?: (number | null)[] | null;
  assignmentPageNumber_gt?: number | null;
  assignmentPageNumber_gte?: number | null;
  assignmentPageNumber_lt?: number | null;
  assignmentPageNumber_lte?: number | null;
  assignmentPageNumber_is_null?: boolean | null;
  assignmentMediaSearchStatus?: MediaSearchStatus | null;
  assignmentMediaSearchStatus_not?: MediaSearchStatus | null;
  assignmentMediaSearchStatus_in?: MediaSearchStatus[] | null;
  assignmentMediaSearchStatus_not_in?: MediaSearchStatus[] | null;
  assignment?: AssignmentFilterInput | null;
  assignment_not?: AssignmentFilterInput | null;
  subject?: SubjectFilterInput | null;
  subject_not?: SubjectFilterInput | null;
  subject_is_null?: boolean | null;
  rawArticle?: RawArticleFilterInput | null;
  rawArticle_not?: RawArticleFilterInput | null;
  rawArticle_is_null?: boolean | null;
  printHeading?: PrintHeadingFilterInput | null;
  printHeading_not?: PrintHeadingFilterInput | null;
  printHeading_is_null?: boolean | null;
  printIssue?: PrintIssueFilterInput | null;
  printIssue_not?: PrintIssueFilterInput | null;
  printIssue_is_null?: boolean | null;
  printPublication?: PrintPublicationFilterInput | null;
  printPublication_not?: PrintPublicationFilterInput | null;
  printPublication_is_null?: boolean | null;
  printTemplate?: PrintTemplateFilterInput | null;
  printTemplate_not?: PrintTemplateFilterInput | null;
  printTemplate_is_null?: boolean | null;
  website?: WebsiteFilterInput | null;
  website_not?: WebsiteFilterInput | null;
  website_is_null?: boolean | null;
  brand?: BrandFilterInput | null;
  brand_not?: BrandFilterInput | null;
  brand_is_null?: boolean | null;
  AND?: (FlatAssignmentFilterInput | null)[] | null;
  OR?: (FlatAssignmentFilterInput | null)[] | null;
  NOT?: FlatAssignmentFilterInput | null;
}

export interface PrintHeadingCreationAssignmentsInput {
  create?: AssignmentCreationWithoutPrintHeadingInput[] | null;
}

/**
 * The "PrintHeading" node's creation
 */
export interface PrintHeadingCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  order: number;
  legacyId?: number | null;
  hasAutomaticSubject: boolean;
  autoPrototype?: boolean | null;
  printIssue: PrintHeadingCreationPrintIssueInput;
  printTemplates?: PrintHeadingCreationPrintTemplatesInput | null;
  assignments?: PrintHeadingCreationAssignmentsInput | null;
}

export interface PrintHeadingCreationPrintIssueCreateIfNotExistsInput {
  where: PrintIssueUniqueFilterInput;
  data: PrintIssueCreationInput;
}

export interface PrintHeadingCreationPrintIssueInput {
  connect?: PrintIssueUniqueFilterInput | null;
  create?: PrintIssueCreationInput | null;
  createIfNotExists?: PrintHeadingCreationPrintIssueCreateIfNotExistsInput | null;
}

export interface PrintHeadingCreationPrintTemplatesInput {
  create?: PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput[] | null;
}

/**
 * The "PrintHeading" node's creation
 */
export interface PrintHeadingCreationWithoutPrintIssueInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  order: number;
  legacyId?: number | null;
  hasAutomaticSubject: boolean;
  autoPrototype?: boolean | null;
  printTemplates?: PrintHeadingCreationPrintTemplatesInput | null;
  assignments?: PrintHeadingCreationAssignmentsInput | null;
}

/**
 * The "PrintHeading" nodes' filter
 */
export interface PrintHeadingFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  order?: number | null;
  order_not?: number | null;
  order_in?: number[] | null;
  order_not_in?: number[] | null;
  order_gt?: number | null;
  order_gte?: number | null;
  order_lt?: number | null;
  order_lte?: number | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  hasAutomaticSubject?: boolean | null;
  hasAutomaticSubject_not?: boolean | null;
  autoPrototype?: boolean | null;
  autoPrototype_not?: boolean | null;
  autoPrototype_in?: (boolean | null)[] | null;
  autoPrototype_not_in?: (boolean | null)[] | null;
  autoPrototype_is_null?: boolean | null;
  printIssue?: PrintIssueFilterInput | null;
  printIssue_not?: PrintIssueFilterInput | null;
  printTemplates_every?: PrintHeadingPrintTemplateFilterInput | null;
  printTemplates_some?: PrintHeadingPrintTemplateFilterInput | null;
  printTemplates_none?: PrintHeadingPrintTemplateFilterInput | null;
  printTemplateCount?: GraphQL$UnsignedInt | null;
  printTemplateCount_not?: GraphQL$UnsignedInt | null;
  printTemplateCount_gt?: GraphQL$UnsignedInt | null;
  printTemplateCount_gte?: GraphQL$UnsignedInt | null;
  printTemplateCount_lt?: GraphQL$UnsignedInt | null;
  printTemplateCount_lte?: GraphQL$UnsignedInt | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (PrintHeadingFilterInput | null)[] | null;
  OR?: (PrintHeadingFilterInput | null)[] | null;
  NOT?: PrintHeadingFilterInput | null;
}

export interface PrintHeadingPrintTemplateCreationPrintHeadingCreateIfNotExistsInput {
  where: PrintHeadingUniqueFilterInput;
  data: PrintHeadingCreationInput;
}

export interface PrintHeadingPrintTemplateCreationPrintHeadingInput {
  connect?: PrintHeadingUniqueFilterInput | null;
  create?: PrintHeadingCreationInput | null;
  createIfNotExists?: PrintHeadingPrintTemplateCreationPrintHeadingCreateIfNotExistsInput | null;
}

export interface PrintHeadingPrintTemplateCreationPrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface PrintHeadingPrintTemplateCreationPrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: PrintHeadingPrintTemplateCreationPrintTemplateCreateIfNotExistsInput | null;
}

/**
 * The "PrintHeadingPrintTemplate" node's creation
 */
export interface PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  active: boolean;
  printTemplate: PrintHeadingPrintTemplateCreationPrintTemplateInput;
}

/**
 * The "PrintHeadingPrintTemplate" node's creation
 */
export interface PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  active: boolean;
  printHeading: PrintHeadingPrintTemplateCreationPrintHeadingInput;
}

/**
 * The "PrintHeadingPrintTemplate" nodes' filter
 */
export interface PrintHeadingPrintTemplateFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  active?: boolean | null;
  active_not?: boolean | null;
  printHeading?: PrintHeadingFilterInput | null;
  printHeading_not?: PrintHeadingFilterInput | null;
  printTemplate?: PrintTemplateFilterInput | null;
  printTemplate_not?: PrintTemplateFilterInput | null;
  AND?: (PrintHeadingPrintTemplateFilterInput | null)[] | null;
  OR?: (PrintHeadingPrintTemplateFilterInput | null)[] | null;
  NOT?: PrintHeadingPrintTemplateFilterInput | null;
}

/**
 * Identifies exactly one "PrintHeadingPrintTemplate" with one of the following combination of components' value:
 * - _id
 * - id
 * - printHeading / printTemplate
 */
export interface PrintHeadingPrintTemplateUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  printHeading?: PrintHeadingUniqueFilterInput | null;
  printTemplate?: PrintTemplateUniqueFilterInput | null;
}

/**
 * Given a "printHeading", identifies exactly one "PrintHeadingPrintTemplate" with one of the following combination of components' value:
 * - (printHeading) / printTemplate
 */
export interface PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput {
  printTemplate: PrintTemplateUniqueFilterInput;
}

/**
 * Given a "printTemplate", identifies exactly one "PrintHeadingPrintTemplate" with one of the following combination of components' value:
 * - printHeading / (printTemplate)
 */
export interface PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput {
  printHeading: PrintHeadingUniqueFilterInput;
}

/**
 * The "PrintHeadingPrintTemplate" node's update
 */
export interface PrintHeadingPrintTemplateUpdateInput {
  active?: boolean | null;
  printHeading?: PrintHeadingPrintTemplateUpdatePrintHeadingInput | null;
  printTemplate?: PrintHeadingPrintTemplateUpdatePrintTemplateInput | null;
}

export interface PrintHeadingPrintTemplateUpdatePrintHeadingCreateIfNotExistsInput {
  where: PrintHeadingUniqueFilterInput;
  data: PrintHeadingCreationInput;
}

export interface PrintHeadingPrintTemplateUpdatePrintHeadingInput {
  connect?: PrintHeadingUniqueFilterInput | null;
  create?: PrintHeadingCreationInput | null;
  createIfNotExists?: PrintHeadingPrintTemplateUpdatePrintHeadingCreateIfNotExistsInput | null;
  update?: PrintHeadingUpdateInput | null;
}

export interface PrintHeadingPrintTemplateUpdatePrintTemplateCreateIfNotExistsInput {
  where: PrintTemplateUniqueFilterInput;
  data: PrintTemplateCreationInput;
}

export interface PrintHeadingPrintTemplateUpdatePrintTemplateInput {
  connect?: PrintTemplateUniqueFilterInput | null;
  create?: PrintTemplateCreationInput | null;
  createIfNotExists?: PrintHeadingPrintTemplateUpdatePrintTemplateCreateIfNotExistsInput | null;
  update?: PrintTemplateUpdateInput | null;
}

/**
 * The "PrintHeadingPrintTemplate" node's update
 */
export interface PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput {
  active?: boolean | null;
  printTemplate?: PrintHeadingPrintTemplateUpdatePrintTemplateInput | null;
}

/**
 * The "PrintHeadingPrintTemplate" node's update
 */
export interface PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput {
  active?: boolean | null;
  printHeading?: PrintHeadingPrintTemplateUpdatePrintHeadingInput | null;
}

/**
 * Identifies exactly one "PrintHeading" with one of the following combination of components' value:
 * - _id
 * - id
 * - legacyId / printIssue
 * - title / printIssue
 */
export interface PrintHeadingUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  legacyId?: number | null;
  printIssue?: PrintIssueUniqueFilterInput | null;
  title?: string | null;
}

/**
 * Given a "printIssue", identifies exactly one "PrintHeading" with one of the following combination of components' value:
 * - legacyId / (printIssue)
 * - title / (printIssue)
 */
export interface PrintHeadingUniqueFilterWithoutPrintIssueInput {
  legacyId?: number | null;
  title?: string | null;
}

export interface PrintHeadingUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutPrintHeadingInput[] | null;
  updateAll?: AssignmentUpdateWithoutPrintHeadingInput | null;
  updateMany?: PrintHeadingUpdateAssignmentsUpdateManyInput | null;
}

export interface PrintHeadingUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutPrintHeadingInput | null;
}

/**
 * The "PrintHeading" node's update
 */
export interface PrintHeadingUpdateInput {
  title?: string | null;
  order?: number | null;
  legacyId?: number | null;
  hasAutomaticSubject?: boolean | null;
  autoPrototype?: boolean | null;
  printIssue?: PrintHeadingUpdatePrintIssueInput | null;
  printTemplates?: PrintHeadingUpdatePrintTemplatesInput | null;
  assignments?: PrintHeadingUpdateAssignmentsInput | null;
}

export interface PrintHeadingUpdatePrintIssueCreateIfNotExistsInput {
  where: PrintIssueUniqueFilterInput;
  data: PrintIssueCreationInput;
}

export interface PrintHeadingUpdatePrintIssueInput {
  connect?: PrintIssueUniqueFilterInput | null;
  create?: PrintIssueCreationInput | null;
  createIfNotExists?: PrintHeadingUpdatePrintIssueCreateIfNotExistsInput | null;
  update?: PrintIssueUpdateInput | null;
}

export interface PrintHeadingUpdatePrintTemplatesCreateIfNotExistsInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
  data: PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput;
}

export interface PrintHeadingUpdatePrintTemplatesInput {
  deleteAll?: boolean | null;
  deleteMany?: PrintHeadingPrintTemplateFilterInput | null;
  delete?: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput[] | null;
  deleteIfExists?: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput[] | null;
  create?: PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput[] | null;
  createIfNotExists?: PrintHeadingUpdatePrintTemplatesCreateIfNotExistsInput[] | null;
  updateAll?: PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput | null;
  updateMany?: PrintHeadingUpdatePrintTemplatesUpdateManyInput | null;
  update?: PrintHeadingUpdatePrintTemplatesUpdateInput[] | null;
  updateIfExists?: PrintHeadingUpdatePrintTemplatesUpdateIfExistsInput[] | null;
  upsert?: PrintHeadingUpdatePrintTemplatesUpsertInput[] | null;
}

export interface PrintHeadingUpdatePrintTemplatesUpdateIfExistsInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput | null;
}

export interface PrintHeadingUpdatePrintTemplatesUpdateInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput | null;
}

export interface PrintHeadingUpdatePrintTemplatesUpdateManyInput {
  where?: PrintHeadingPrintTemplateFilterInput | null;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput | null;
}

export interface PrintHeadingUpdatePrintTemplatesUpsertInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintHeadingInput;
  create: PrintHeadingPrintTemplateCreationWithoutPrintHeadingInput;
  update?: PrintHeadingPrintTemplateUpdateWithoutPrintHeadingInput | null;
}

/**
 * The "PrintHeading" node's update
 */
export interface PrintHeadingUpdateWithoutPrintIssueInput {
  title?: string | null;
  order?: number | null;
  legacyId?: number | null;
  hasAutomaticSubject?: boolean | null;
  autoPrototype?: boolean | null;
  printTemplates?: PrintHeadingUpdatePrintTemplatesInput | null;
  assignments?: PrintHeadingUpdateAssignmentsInput | null;
}

export interface PrintIssueCreationAssignmentsInput {
  create?: AssignmentCreationWithoutPrintIssueInput[] | null;
}

/**
 * The "PrintIssue" node's creation
 */
export interface PrintIssueCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  legacyId?: number | null;
  printPublication: PrintIssueCreationPrintPublicationInput;
  printHeadings?: PrintIssueCreationPrintHeadingsInput | null;
  assignments?: PrintIssueCreationAssignmentsInput | null;
}

export interface PrintIssueCreationPrintHeadingsInput {
  create?: PrintHeadingCreationWithoutPrintIssueInput[] | null;
}

export interface PrintIssueCreationPrintPublicationCreateIfNotExistsInput {
  where: PrintPublicationUniqueFilterInput;
  data: PrintPublicationCreationInput;
}

export interface PrintIssueCreationPrintPublicationInput {
  connect?: PrintPublicationUniqueFilterInput | null;
  create?: PrintPublicationCreationInput | null;
  createIfNotExists?: PrintIssueCreationPrintPublicationCreateIfNotExistsInput | null;
}

/**
 * The "PrintIssue" node's creation
 */
export interface PrintIssueCreationWithoutPrintPublicationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  legacyId?: number | null;
  printHeadings?: PrintIssueCreationPrintHeadingsInput | null;
  assignments?: PrintIssueCreationAssignmentsInput | null;
}

/**
 * The "PrintIssue" nodes' filter
 */
export interface PrintIssueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_gt?: string | null;
  title_gte?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  printPublication?: PrintPublicationFilterInput | null;
  printPublication_not?: PrintPublicationFilterInput | null;
  printHeadings_every?: PrintHeadingFilterInput | null;
  printHeadings_some?: PrintHeadingFilterInput | null;
  printHeadings_none?: PrintHeadingFilterInput | null;
  printHeadingCount?: GraphQL$UnsignedInt | null;
  printHeadingCount_not?: GraphQL$UnsignedInt | null;
  printHeadingCount_gt?: GraphQL$UnsignedInt | null;
  printHeadingCount_gte?: GraphQL$UnsignedInt | null;
  printHeadingCount_lt?: GraphQL$UnsignedInt | null;
  printHeadingCount_lte?: GraphQL$UnsignedInt | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (PrintIssueFilterInput | null)[] | null;
  OR?: (PrintIssueFilterInput | null)[] | null;
  NOT?: PrintIssueFilterInput | null;
}

/**
 * Identifies exactly one "PrintIssue" with one of the following combination of components' value:
 * - _id
 * - id
 * - legacyId / printPublication
 * - title / printPublication
 */
export interface PrintIssueUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  legacyId?: number | null;
  printPublication?: PrintPublicationUniqueFilterInput | null;
  title?: string | null;
}

/**
 * Given a "printPublication", identifies exactly one "PrintIssue" with one of the following combination of components' value:
 * - legacyId / (printPublication)
 * - title / (printPublication)
 */
export interface PrintIssueUniqueFilterWithoutPrintPublicationInput {
  legacyId?: number | null;
  title?: string | null;
}

export interface PrintIssueUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutPrintIssueInput[] | null;
  updateAll?: AssignmentUpdateWithoutPrintIssueInput | null;
  updateMany?: PrintIssueUpdateAssignmentsUpdateManyInput | null;
}

export interface PrintIssueUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutPrintIssueInput | null;
}

/**
 * The "PrintIssue" node's update
 */
export interface PrintIssueUpdateInput {
  title?: string | null;
  legacyId?: number | null;
  printPublication?: PrintIssueUpdatePrintPublicationInput | null;
  printHeadings?: PrintIssueUpdatePrintHeadingsInput | null;
  assignments?: PrintIssueUpdateAssignmentsInput | null;
}

export interface PrintIssueUpdatePrintHeadingsCreateIfNotExistsInput {
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
  data: PrintHeadingCreationWithoutPrintIssueInput;
}

export interface PrintIssueUpdatePrintHeadingsInput {
  deleteAll?: boolean | null;
  deleteMany?: PrintHeadingFilterInput | null;
  delete?: PrintHeadingUniqueFilterWithoutPrintIssueInput[] | null;
  deleteIfExists?: PrintHeadingUniqueFilterWithoutPrintIssueInput[] | null;
  create?: PrintHeadingCreationWithoutPrintIssueInput[] | null;
  createIfNotExists?: PrintIssueUpdatePrintHeadingsCreateIfNotExistsInput[] | null;
  updateAll?: PrintHeadingUpdateWithoutPrintIssueInput | null;
  updateMany?: PrintIssueUpdatePrintHeadingsUpdateManyInput | null;
  update?: PrintIssueUpdatePrintHeadingsUpdateInput[] | null;
  updateIfExists?: PrintIssueUpdatePrintHeadingsUpdateIfExistsInput[] | null;
  upsert?: PrintIssueUpdatePrintHeadingsUpsertInput[] | null;
}

export interface PrintIssueUpdatePrintHeadingsUpdateIfExistsInput {
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
  data?: PrintHeadingUpdateWithoutPrintIssueInput | null;
}

export interface PrintIssueUpdatePrintHeadingsUpdateInput {
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
  data?: PrintHeadingUpdateWithoutPrintIssueInput | null;
}

export interface PrintIssueUpdatePrintHeadingsUpdateManyInput {
  where?: PrintHeadingFilterInput | null;
  data?: PrintHeadingUpdateWithoutPrintIssueInput | null;
}

export interface PrintIssueUpdatePrintHeadingsUpsertInput {
  where: PrintHeadingUniqueFilterWithoutPrintIssueInput;
  create: PrintHeadingCreationWithoutPrintIssueInput;
  update?: PrintHeadingUpdateWithoutPrintIssueInput | null;
}

export interface PrintIssueUpdatePrintPublicationCreateIfNotExistsInput {
  where: PrintPublicationUniqueFilterInput;
  data: PrintPublicationCreationInput;
}

export interface PrintIssueUpdatePrintPublicationInput {
  connect?: PrintPublicationUniqueFilterInput | null;
  create?: PrintPublicationCreationInput | null;
  createIfNotExists?: PrintIssueUpdatePrintPublicationCreateIfNotExistsInput | null;
  update?: PrintPublicationUpdateInput | null;
}

/**
 * The "PrintIssue" node's update
 */
export interface PrintIssueUpdateWithoutPrintPublicationInput {
  title?: string | null;
  legacyId?: number | null;
  printHeadings?: PrintIssueUpdatePrintHeadingsInput | null;
  assignments?: PrintIssueUpdateAssignmentsInput | null;
}

export interface PrintPublicationCreationAssignmentsInput {
  create?: AssignmentCreationWithoutPrintPublicationInput[] | null;
}

export interface PrintPublicationCreationBrandCreateIfNotExistsInput {
  where: BrandUniqueFilterInput;
  data: BrandCreationInput;
}

export interface PrintPublicationCreationBrandInput {
  connect?: BrandUniqueFilterInput | null;
  create?: BrandCreationInput | null;
  createIfNotExists?: PrintPublicationCreationBrandCreateIfNotExistsInput | null;
}

/**
 * The "PrintPublication" node's creation
 */
export interface PrintPublicationCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  key: string;
  legacyId?: number | null;
  brand: PrintPublicationCreationBrandInput;
  printIssues?: PrintPublicationCreationPrintIssuesInput | null;
  assignments?: PrintPublicationCreationAssignmentsInput | null;
}

export interface PrintPublicationCreationPrintIssuesInput {
  create?: PrintIssueCreationWithoutPrintPublicationInput[] | null;
}

/**
 * The "PrintPublication" node's creation
 */
export interface PrintPublicationCreationWithoutBrandInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  key: string;
  legacyId?: number | null;
  printIssues?: PrintPublicationCreationPrintIssuesInput | null;
  assignments?: PrintPublicationCreationAssignmentsInput | null;
}

/**
 * The "PrintPublication" nodes' filter
 */
export interface PrintPublicationFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  key?: string | null;
  key_not?: string | null;
  key_in?: string[] | null;
  key_not_in?: string[] | null;
  key_contains?: GraphQL$NonEmptyString | null;
  key_not_contains?: GraphQL$NonEmptyString | null;
  key_starts_with?: GraphQL$NonEmptyString | null;
  key_not_starts_with?: GraphQL$NonEmptyString | null;
  key_ends_with?: GraphQL$NonEmptyString | null;
  key_not_ends_with?: GraphQL$NonEmptyString | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  brand?: BrandFilterInput | null;
  brand_not?: BrandFilterInput | null;
  printIssues_every?: PrintIssueFilterInput | null;
  printIssues_some?: PrintIssueFilterInput | null;
  printIssues_none?: PrintIssueFilterInput | null;
  printIssueCount?: GraphQL$UnsignedInt | null;
  printIssueCount_not?: GraphQL$UnsignedInt | null;
  printIssueCount_gt?: GraphQL$UnsignedInt | null;
  printIssueCount_gte?: GraphQL$UnsignedInt | null;
  printIssueCount_lt?: GraphQL$UnsignedInt | null;
  printIssueCount_lte?: GraphQL$UnsignedInt | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (PrintPublicationFilterInput | null)[] | null;
  OR?: (PrintPublicationFilterInput | null)[] | null;
  NOT?: PrintPublicationFilterInput | null;
}

/**
 * Identifies exactly one "PrintPublication" with one of the following combination of components' value:
 * - _id
 * - id
 * - key
 * - legacyId / brand
 * - title / brand
 */
export interface PrintPublicationUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  key?: string | null;
  legacyId?: number | null;
  brand?: BrandUniqueFilterInput | null;
  title?: string | null;
}

/**
 * Given a "brand", identifies exactly one "PrintPublication" with one of the following combination of components' value:
 * - legacyId / (brand)
 * - title / (brand)
 */
export interface PrintPublicationUniqueFilterWithoutBrandInput {
  legacyId?: number | null;
  title?: string | null;
}

export interface PrintPublicationUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutPrintPublicationInput[] | null;
  updateAll?: AssignmentUpdateWithoutPrintPublicationInput | null;
  updateMany?: PrintPublicationUpdateAssignmentsUpdateManyInput | null;
}

export interface PrintPublicationUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutPrintPublicationInput | null;
}

export interface PrintPublicationUpdateBrandCreateIfNotExistsInput {
  where: BrandUniqueFilterInput;
  data: BrandCreationInput;
}

export interface PrintPublicationUpdateBrandInput {
  connect?: BrandUniqueFilterInput | null;
  create?: BrandCreationInput | null;
  createIfNotExists?: PrintPublicationUpdateBrandCreateIfNotExistsInput | null;
  update?: BrandUpdateInput | null;
}

/**
 * The "PrintPublication" node's update
 */
export interface PrintPublicationUpdateInput {
  title?: string | null;
  key?: string | null;
  legacyId?: number | null;
  brand?: PrintPublicationUpdateBrandInput | null;
  printIssues?: PrintPublicationUpdatePrintIssuesInput | null;
  assignments?: PrintPublicationUpdateAssignmentsInput | null;
}

export interface PrintPublicationUpdatePrintIssuesCreateIfNotExistsInput {
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
  data: PrintIssueCreationWithoutPrintPublicationInput;
}

export interface PrintPublicationUpdatePrintIssuesInput {
  deleteAll?: boolean | null;
  deleteMany?: PrintIssueFilterInput | null;
  delete?: PrintIssueUniqueFilterWithoutPrintPublicationInput[] | null;
  deleteIfExists?: PrintIssueUniqueFilterWithoutPrintPublicationInput[] | null;
  create?: PrintIssueCreationWithoutPrintPublicationInput[] | null;
  createIfNotExists?: PrintPublicationUpdatePrintIssuesCreateIfNotExistsInput[] | null;
  updateAll?: PrintIssueUpdateWithoutPrintPublicationInput | null;
  updateMany?: PrintPublicationUpdatePrintIssuesUpdateManyInput | null;
  update?: PrintPublicationUpdatePrintIssuesUpdateInput[] | null;
  updateIfExists?: PrintPublicationUpdatePrintIssuesUpdateIfExistsInput[] | null;
  upsert?: PrintPublicationUpdatePrintIssuesUpsertInput[] | null;
}

export interface PrintPublicationUpdatePrintIssuesUpdateIfExistsInput {
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
  data?: PrintIssueUpdateWithoutPrintPublicationInput | null;
}

export interface PrintPublicationUpdatePrintIssuesUpdateInput {
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
  data?: PrintIssueUpdateWithoutPrintPublicationInput | null;
}

export interface PrintPublicationUpdatePrintIssuesUpdateManyInput {
  where?: PrintIssueFilterInput | null;
  data?: PrintIssueUpdateWithoutPrintPublicationInput | null;
}

export interface PrintPublicationUpdatePrintIssuesUpsertInput {
  where: PrintIssueUniqueFilterWithoutPrintPublicationInput;
  create: PrintIssueCreationWithoutPrintPublicationInput;
  update?: PrintIssueUpdateWithoutPrintPublicationInput | null;
}

/**
 * The "PrintPublication" node's update
 */
export interface PrintPublicationUpdateWithoutBrandInput {
  title?: string | null;
  key?: string | null;
  legacyId?: number | null;
  printIssues?: PrintPublicationUpdatePrintIssuesInput | null;
  assignments?: PrintPublicationUpdateAssignmentsInput | null;
}

export interface PrintTemplateCreationArticleTemplatesInput {
  create?: ArticleTemplateCreationWithoutPrintTemplateInput[] | null;
}

export interface PrintTemplateCreationAssignmentsInput {
  create?: AssignmentCreationWithoutPrintTemplateInput[] | null;
}

/**
 * The "PrintTemplate" node's creation
 */
export interface PrintTemplateCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  S3ObjectId: string;
  label: string;
  filename: string;
  filesize?: number | null;
  type?: TemplateType | null;
  pageCount?: number | null;
  extVersion?: string | null;
  data?: string | null;
  articleTemplates?: PrintTemplateCreationArticleTemplatesInput | null;
  printHeadings?: PrintTemplateCreationPrintHeadingsInput | null;
  assignments?: PrintTemplateCreationAssignmentsInput | null;
}

export interface PrintTemplateCreationPrintHeadingsInput {
  create?: PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput[] | null;
}

export interface PrintTemplateDraftFieldsDataType {
  id: number;
  value: string;
}

/**
 * The "PrintTemplate" nodes' filter
 */
export interface PrintTemplateFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  S3ObjectId?: string | null;
  S3ObjectId_not?: string | null;
  S3ObjectId_in?: string[] | null;
  S3ObjectId_not_in?: string[] | null;
  S3ObjectId_contains?: GraphQL$NonEmptyString | null;
  S3ObjectId_not_contains?: GraphQL$NonEmptyString | null;
  S3ObjectId_starts_with?: GraphQL$NonEmptyString | null;
  S3ObjectId_not_starts_with?: GraphQL$NonEmptyString | null;
  S3ObjectId_ends_with?: GraphQL$NonEmptyString | null;
  S3ObjectId_not_ends_with?: GraphQL$NonEmptyString | null;
  label?: string | null;
  label_not?: string | null;
  label_in?: string[] | null;
  label_not_in?: string[] | null;
  label_contains?: GraphQL$NonEmptyString | null;
  label_not_contains?: GraphQL$NonEmptyString | null;
  label_starts_with?: GraphQL$NonEmptyString | null;
  label_not_starts_with?: GraphQL$NonEmptyString | null;
  label_ends_with?: GraphQL$NonEmptyString | null;
  label_not_ends_with?: GraphQL$NonEmptyString | null;
  filename?: string | null;
  filename_not?: string | null;
  filename_in?: string[] | null;
  filename_not_in?: string[] | null;
  filename_contains?: GraphQL$NonEmptyString | null;
  filename_not_contains?: GraphQL$NonEmptyString | null;
  filename_starts_with?: GraphQL$NonEmptyString | null;
  filename_not_starts_with?: GraphQL$NonEmptyString | null;
  filename_ends_with?: GraphQL$NonEmptyString | null;
  filename_not_ends_with?: GraphQL$NonEmptyString | null;
  filesize?: number | null;
  filesize_not?: number | null;
  filesize_in?: (number | null)[] | null;
  filesize_not_in?: (number | null)[] | null;
  filesize_gt?: number | null;
  filesize_gte?: number | null;
  filesize_lt?: number | null;
  filesize_lte?: number | null;
  filesize_is_null?: boolean | null;
  type?: TemplateType | null;
  type_not?: TemplateType | null;
  type_in?: (TemplateType | null)[] | null;
  type_not_in?: (TemplateType | null)[] | null;
  type_is_null?: boolean | null;
  pageCount?: number | null;
  pageCount_not?: number | null;
  pageCount_in?: (number | null)[] | null;
  pageCount_not_in?: (number | null)[] | null;
  pageCount_gt?: number | null;
  pageCount_gte?: number | null;
  pageCount_lt?: number | null;
  pageCount_lte?: number | null;
  pageCount_is_null?: boolean | null;
  extVersion?: string | null;
  extVersion_not?: string | null;
  extVersion_in?: (string | null)[] | null;
  extVersion_not_in?: (string | null)[] | null;
  extVersion_is_null?: boolean | null;
  extVersion_contains?: GraphQL$NonEmptyString | null;
  extVersion_not_contains?: GraphQL$NonEmptyString | null;
  extVersion_starts_with?: GraphQL$NonEmptyString | null;
  extVersion_not_starts_with?: GraphQL$NonEmptyString | null;
  extVersion_ends_with?: GraphQL$NonEmptyString | null;
  extVersion_not_ends_with?: GraphQL$NonEmptyString | null;
  data?: string | null;
  data_not?: string | null;
  data_in?: string[] | null;
  data_not_in?: string[] | null;
  data_contains?: GraphQL$NonEmptyString | null;
  data_not_contains?: GraphQL$NonEmptyString | null;
  data_starts_with?: GraphQL$NonEmptyString | null;
  data_not_starts_with?: GraphQL$NonEmptyString | null;
  data_ends_with?: GraphQL$NonEmptyString | null;
  data_not_ends_with?: GraphQL$NonEmptyString | null;
  articleTemplates_every?: ArticleTemplateFilterInput | null;
  articleTemplates_some?: ArticleTemplateFilterInput | null;
  articleTemplates_none?: ArticleTemplateFilterInput | null;
  articleTemplateCount?: GraphQL$UnsignedInt | null;
  articleTemplateCount_not?: GraphQL$UnsignedInt | null;
  articleTemplateCount_gt?: GraphQL$UnsignedInt | null;
  articleTemplateCount_gte?: GraphQL$UnsignedInt | null;
  articleTemplateCount_lt?: GraphQL$UnsignedInt | null;
  articleTemplateCount_lte?: GraphQL$UnsignedInt | null;
  printHeadings_every?: PrintHeadingPrintTemplateFilterInput | null;
  printHeadings_some?: PrintHeadingPrintTemplateFilterInput | null;
  printHeadings_none?: PrintHeadingPrintTemplateFilterInput | null;
  printHeadingCount?: GraphQL$UnsignedInt | null;
  printHeadingCount_not?: GraphQL$UnsignedInt | null;
  printHeadingCount_gt?: GraphQL$UnsignedInt | null;
  printHeadingCount_gte?: GraphQL$UnsignedInt | null;
  printHeadingCount_lt?: GraphQL$UnsignedInt | null;
  printHeadingCount_lte?: GraphQL$UnsignedInt | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (PrintTemplateFilterInput | null)[] | null;
  OR?: (PrintTemplateFilterInput | null)[] | null;
  NOT?: PrintTemplateFilterInput | null;
}

/**
 * Identifies exactly one "PrintTemplate" with one of the following combination of components' value:
 * - _id
 * - id
 * - S3ObjectId
 */
export interface PrintTemplateUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  S3ObjectId?: string | null;
}

export interface PrintTemplateUpdateArticleTemplatesInput {
  deleteAll?: boolean | null;
  deleteMany?: ArticleTemplateFilterInput | null;
  create?: ArticleTemplateCreationWithoutPrintTemplateInput[] | null;
  updateAll?: ArticleTemplateUpdateWithoutPrintTemplateInput | null;
  updateMany?: PrintTemplateUpdateArticleTemplatesUpdateManyInput | null;
}

export interface PrintTemplateUpdateArticleTemplatesUpdateManyInput {
  where?: ArticleTemplateFilterInput | null;
  data?: ArticleTemplateUpdateWithoutPrintTemplateInput | null;
}

export interface PrintTemplateUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutPrintTemplateInput[] | null;
  updateAll?: AssignmentUpdateWithoutPrintTemplateInput | null;
  updateMany?: PrintTemplateUpdateAssignmentsUpdateManyInput | null;
}

export interface PrintTemplateUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutPrintTemplateInput | null;
}

/**
 * The "PrintTemplate" node's update
 */
export interface PrintTemplateUpdateInput {
  S3ObjectId?: string | null;
  label?: string | null;
  filename?: string | null;
  filesize?: number | null;
  type?: TemplateType | null;
  articleTemplates?: PrintTemplateUpdateArticleTemplatesInput | null;
  printHeadings?: PrintTemplateUpdatePrintHeadingsInput | null;
  assignments?: PrintTemplateUpdateAssignmentsInput | null;
}

export interface PrintTemplateUpdatePrintHeadingsCreateIfNotExistsInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
  data: PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput;
}

export interface PrintTemplateUpdatePrintHeadingsInput {
  deleteAll?: boolean | null;
  deleteMany?: PrintHeadingPrintTemplateFilterInput | null;
  delete?: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput[] | null;
  deleteIfExists?: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput[] | null;
  create?: PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput[] | null;
  createIfNotExists?: PrintTemplateUpdatePrintHeadingsCreateIfNotExistsInput[] | null;
  updateAll?: PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput | null;
  updateMany?: PrintTemplateUpdatePrintHeadingsUpdateManyInput | null;
  update?: PrintTemplateUpdatePrintHeadingsUpdateInput[] | null;
  updateIfExists?: PrintTemplateUpdatePrintHeadingsUpdateIfExistsInput[] | null;
  upsert?: PrintTemplateUpdatePrintHeadingsUpsertInput[] | null;
}

export interface PrintTemplateUpdatePrintHeadingsUpdateIfExistsInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput | null;
}

export interface PrintTemplateUpdatePrintHeadingsUpdateInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput | null;
}

export interface PrintTemplateUpdatePrintHeadingsUpdateManyInput {
  where?: PrintHeadingPrintTemplateFilterInput | null;
  data?: PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput | null;
}

export interface PrintTemplateUpdatePrintHeadingsUpsertInput {
  where: PrintHeadingPrintTemplateUniqueFilterWithoutPrintTemplateInput;
  create: PrintHeadingPrintTemplateCreationWithoutPrintTemplateInput;
  update?: PrintHeadingPrintTemplateUpdateWithoutPrintTemplateInput | null;
}

export interface RawArticleCreationAssignmentInput {
  create?: AssignmentCreationWithoutRawArticleInput | null;
}

/**
 * The "RawArticle" node's creation
 */
export interface RawArticleCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  legacyId?: number | null;
  versions?: RawArticleCreationVersionsInput | null;
  assignment?: RawArticleCreationAssignmentInput | null;
}

export interface RawArticleCreationVersionsInput {
  create?: RawArticleVersionCreationWithoutRawArticleInput[] | null;
}

/**
 * The "RawArticle" nodes' filter
 */
export interface RawArticleFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  versions_every?: RawArticleVersionFilterInput | null;
  versions_some?: RawArticleVersionFilterInput | null;
  versions_none?: RawArticleVersionFilterInput | null;
  versionCount?: GraphQL$UnsignedInt | null;
  versionCount_not?: GraphQL$UnsignedInt | null;
  versionCount_gt?: GraphQL$UnsignedInt | null;
  versionCount_gte?: GraphQL$UnsignedInt | null;
  versionCount_lt?: GraphQL$UnsignedInt | null;
  versionCount_lte?: GraphQL$UnsignedInt | null;
  assignment?: AssignmentFilterInput | null;
  assignment_not?: AssignmentFilterInput | null;
  assignment_is_null?: boolean | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (RawArticleFilterInput | null)[] | null;
  OR?: (RawArticleFilterInput | null)[] | null;
  NOT?: RawArticleFilterInput | null;
}

/**
 * Identifies exactly one "RawArticle" with one of the following combination of components' value:
 * - _id
 * - id
 */
export interface RawArticleUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
}

export interface RawArticleUpdateAssignmentInput {
  delete?: boolean | null;
  deleteIfExists?: boolean | null;
  create?: AssignmentCreationWithoutRawArticleInput | null;
  createIfNotExists?: AssignmentCreationWithoutRawArticleInput | null;
  update?: AssignmentUpdateWithoutRawArticleInput | null;
  updateIfExists?: AssignmentUpdateWithoutRawArticleInput | null;
  upsert?: RawArticleUpdateAssignmentUpsertInput | null;
}

export interface RawArticleUpdateAssignmentUpsertInput {
  create: AssignmentCreationWithoutRawArticleInput;
  update?: AssignmentUpdateWithoutRawArticleInput | null;
}

/**
 * The "RawArticle" node's update
 */
export interface RawArticleUpdateInput {
  legacyId?: number | null;
  versions?: RawArticleUpdateVersionsInput | null;
  assignment?: RawArticleUpdateAssignmentInput | null;
}

export interface RawArticleUpdateVersionsInput {
  deleteAll?: boolean | null;
  deleteMany?: RawArticleVersionFilterInput | null;
  create?: RawArticleVersionCreationWithoutRawArticleInput[] | null;
  updateAll?: RawArticleVersionUpdateWithoutRawArticleInput | null;
  updateMany?: RawArticleUpdateVersionsUpdateManyInput | null;
}

export interface RawArticleUpdateVersionsUpdateManyInput {
  where?: RawArticleVersionFilterInput | null;
  data?: RawArticleVersionUpdateWithoutRawArticleInput | null;
}

export interface RawArticleVersionCreationForkChilrensInput {
  create?: RawArticleVersionCreationWithoutForkOfInput[] | null;
}

export interface RawArticleVersionCreationForkOfCreateIfNotExistsInput {
  where: RawArticleVersionUniqueFilterInput;
  data: RawArticleVersionCreationInput;
}

export interface RawArticleVersionCreationForkOfInput {
  connect?: RawArticleVersionUniqueFilterInput | null;
  connectIfExists?: RawArticleVersionUniqueFilterInput | null;
  create?: RawArticleVersionCreationInput | null;
  createIfNotExists?: RawArticleVersionCreationForkOfCreateIfNotExistsInput | null;
}

/**
 * The "RawArticleVersion" node's creation
 */
export interface RawArticleVersionCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle: RawArticleVersionCreationRawArticleInput;
  previousVersion?: RawArticleVersionCreationPreviousVersionInput | null;
  forkOf?: RawArticleVersionCreationForkOfInput | null;
  nextVersion?: RawArticleVersionCreationNextVersionInput | null;
  forkChilrens?: RawArticleVersionCreationForkChilrensInput | null;
}

export interface RawArticleVersionCreationNextVersionInput {
  create?: RawArticleVersionCreationWithoutPreviousVersionInput | null;
}

export interface RawArticleVersionCreationPreviousVersionCreateIfNotExistsInput {
  where: RawArticleVersionUniqueFilterInput;
  data: RawArticleVersionCreationInput;
}

export interface RawArticleVersionCreationPreviousVersionInput {
  connect?: RawArticleVersionUniqueFilterInput | null;
  connectIfExists?: RawArticleVersionUniqueFilterInput | null;
  create?: RawArticleVersionCreationInput | null;
  createIfNotExists?: RawArticleVersionCreationPreviousVersionCreateIfNotExistsInput | null;
}

export interface RawArticleVersionCreationRawArticleCreateIfNotExistsInput {
  where: RawArticleUniqueFilterInput;
  data: RawArticleCreationInput;
}

export interface RawArticleVersionCreationRawArticleInput {
  connect?: RawArticleUniqueFilterInput | null;
  create?: RawArticleCreationInput | null;
  createIfNotExists?: RawArticleVersionCreationRawArticleCreateIfNotExistsInput | null;
}

/**
 * The "RawArticleVersion" node's creation
 */
export interface RawArticleVersionCreationWithoutForkOfInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle: RawArticleVersionCreationRawArticleInput;
  previousVersion?: RawArticleVersionCreationPreviousVersionInput | null;
  nextVersion?: RawArticleVersionCreationNextVersionInput | null;
  forkChilrens?: RawArticleVersionCreationForkChilrensInput | null;
}

/**
 * The "RawArticleVersion" node's creation
 */
export interface RawArticleVersionCreationWithoutPreviousVersionInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle: RawArticleVersionCreationRawArticleInput;
  forkOf?: RawArticleVersionCreationForkOfInput | null;
  nextVersion?: RawArticleVersionCreationNextVersionInput | null;
  forkChilrens?: RawArticleVersionCreationForkChilrensInput | null;
}

/**
 * The "RawArticleVersion" node's creation
 */
export interface RawArticleVersionCreationWithoutRawArticleInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  previousVersion?: RawArticleVersionCreationPreviousVersionInput | null;
  forkOf?: RawArticleVersionCreationForkOfInput | null;
  nextVersion?: RawArticleVersionCreationNextVersionInput | null;
  forkChilrens?: RawArticleVersionCreationForkChilrensInput | null;
}

/**
 * The "RawArticleVersion" nodes' filter
 */
export interface RawArticleVersionFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  editorId?: string | null;
  editorId_not?: string | null;
  editorId_in?: string[] | null;
  editorId_not_in?: string[] | null;
  editorId_contains?: GraphQL$NonEmptyString | null;
  editorId_not_contains?: GraphQL$NonEmptyString | null;
  editorId_starts_with?: GraphQL$NonEmptyString | null;
  editorId_not_starts_with?: GraphQL$NonEmptyString | null;
  editorId_ends_with?: GraphQL$NonEmptyString | null;
  editorId_not_ends_with?: GraphQL$NonEmptyString | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: (string | null)[] | null;
  title_not_in?: (string | null)[] | null;
  title_is_null?: boolean | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  lead?: string | null;
  lead_not?: string | null;
  lead_in?: (string | null)[] | null;
  lead_not_in?: (string | null)[] | null;
  lead_is_null?: boolean | null;
  lead_contains?: GraphQL$NonEmptyString | null;
  lead_not_contains?: GraphQL$NonEmptyString | null;
  lead_starts_with?: GraphQL$NonEmptyString | null;
  lead_not_starts_with?: GraphQL$NonEmptyString | null;
  lead_ends_with?: GraphQL$NonEmptyString | null;
  lead_not_ends_with?: GraphQL$NonEmptyString | null;
  body?: string | null;
  body_not?: string | null;
  body_in?: (string | null)[] | null;
  body_not_in?: (string | null)[] | null;
  body_is_null?: boolean | null;
  body_contains?: GraphQL$NonEmptyString | null;
  body_not_contains?: GraphQL$NonEmptyString | null;
  body_starts_with?: GraphQL$NonEmptyString | null;
  body_not_starts_with?: GraphQL$NonEmptyString | null;
  body_ends_with?: GraphQL$NonEmptyString | null;
  body_not_ends_with?: GraphQL$NonEmptyString | null;
  signature?: string | null;
  signature_not?: string | null;
  signature_in?: (string | null)[] | null;
  signature_not_in?: (string | null)[] | null;
  signature_is_null?: boolean | null;
  signature_contains?: GraphQL$NonEmptyString | null;
  signature_not_contains?: GraphQL$NonEmptyString | null;
  signature_starts_with?: GraphQL$NonEmptyString | null;
  signature_not_starts_with?: GraphQL$NonEmptyString | null;
  signature_ends_with?: GraphQL$NonEmptyString | null;
  signature_not_ends_with?: GraphQL$NonEmptyString | null;
  status?: RawArticleStatus | null;
  status_not?: RawArticleStatus | null;
  status_in?: RawArticleStatus[] | null;
  status_not_in?: RawArticleStatus[] | null;
  comment?: string | null;
  comment_not?: string | null;
  comment_in?: (string | null)[] | null;
  comment_not_in?: (string | null)[] | null;
  comment_is_null?: boolean | null;
  comment_contains?: GraphQL$NonEmptyString | null;
  comment_not_contains?: GraphQL$NonEmptyString | null;
  comment_starts_with?: GraphQL$NonEmptyString | null;
  comment_not_starts_with?: GraphQL$NonEmptyString | null;
  comment_ends_with?: GraphQL$NonEmptyString | null;
  comment_not_ends_with?: GraphQL$NonEmptyString | null;
  leftOver?: string | null;
  leftOver_not?: string | null;
  leftOver_in?: (string | null)[] | null;
  leftOver_not_in?: (string | null)[] | null;
  leftOver_is_null?: boolean | null;
  leftOver_contains?: GraphQL$NonEmptyString | null;
  leftOver_not_contains?: GraphQL$NonEmptyString | null;
  leftOver_starts_with?: GraphQL$NonEmptyString | null;
  leftOver_not_starts_with?: GraphQL$NonEmptyString | null;
  leftOver_ends_with?: GraphQL$NonEmptyString | null;
  leftOver_not_ends_with?: GraphQL$NonEmptyString | null;
  rawArticle?: RawArticleFilterInput | null;
  rawArticle_not?: RawArticleFilterInput | null;
  previousVersion?: RawArticleVersionFilterInput | null;
  previousVersion_not?: RawArticleVersionFilterInput | null;
  previousVersion_is_null?: boolean | null;
  forkOf?: RawArticleVersionFilterInput | null;
  forkOf_not?: RawArticleVersionFilterInput | null;
  forkOf_is_null?: boolean | null;
  nextVersion?: RawArticleVersionFilterInput | null;
  nextVersion_not?: RawArticleVersionFilterInput | null;
  nextVersion_is_null?: boolean | null;
  forkChilrens_every?: RawArticleVersionFilterInput | null;
  forkChilrens_some?: RawArticleVersionFilterInput | null;
  forkChilrens_none?: RawArticleVersionFilterInput | null;
  forkChilrenCount?: GraphQL$UnsignedInt | null;
  forkChilrenCount_not?: GraphQL$UnsignedInt | null;
  forkChilrenCount_gt?: GraphQL$UnsignedInt | null;
  forkChilrenCount_gte?: GraphQL$UnsignedInt | null;
  forkChilrenCount_lt?: GraphQL$UnsignedInt | null;
  forkChilrenCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (RawArticleVersionFilterInput | null)[] | null;
  OR?: (RawArticleVersionFilterInput | null)[] | null;
  NOT?: RawArticleVersionFilterInput | null;
}

/**
 * Identifies exactly one "RawArticleVersion" with one of the following combination of components' value:
 * - _id
 * - id
 * - previousVersion
 */
export interface RawArticleVersionUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  previousVersion?: RawArticleVersionUniqueFilterInput | null;
}

export interface RawArticleVersionUpdateForkChilrensInput {
  deleteAll?: boolean | null;
  deleteMany?: RawArticleVersionFilterInput | null;
  create?: RawArticleVersionCreationWithoutForkOfInput[] | null;
  updateAll?: RawArticleVersionUpdateWithoutForkOfInput | null;
  updateMany?: RawArticleVersionUpdateForkChilrensUpdateManyInput | null;
}

export interface RawArticleVersionUpdateForkChilrensUpdateManyInput {
  where?: RawArticleVersionFilterInput | null;
  data?: RawArticleVersionUpdateWithoutForkOfInput | null;
}

export interface RawArticleVersionUpdateForkOfCreateIfNotExistsInput {
  where: RawArticleVersionUniqueFilterInput;
  data: RawArticleVersionCreationInput;
}

export interface RawArticleVersionUpdateForkOfInput {
  connect?: RawArticleVersionUniqueFilterInput | null;
  connectIfExists?: RawArticleVersionUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: RawArticleVersionCreationInput | null;
  createIfNotExists?: RawArticleVersionUpdateForkOfCreateIfNotExistsInput | null;
  update?: RawArticleVersionUpdateInput | null;
  updateIfExists?: RawArticleVersionUpdateInput | null;
}

/**
 * The "RawArticleVersion" node's update
 */
export interface RawArticleVersionUpdateInput {
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle?: RawArticleVersionUpdateRawArticleInput | null;
  previousVersion?: RawArticleVersionUpdatePreviousVersionInput | null;
  forkOf?: RawArticleVersionUpdateForkOfInput | null;
  nextVersion?: RawArticleVersionUpdateNextVersionInput | null;
  forkChilrens?: RawArticleVersionUpdateForkChilrensInput | null;
}

export interface RawArticleVersionUpdateNextVersionInput {
  delete?: boolean | null;
  deleteIfExists?: boolean | null;
  create?: RawArticleVersionCreationWithoutPreviousVersionInput | null;
  createIfNotExists?: RawArticleVersionCreationWithoutPreviousVersionInput | null;
  update?: RawArticleVersionUpdateWithoutPreviousVersionInput | null;
  updateIfExists?: RawArticleVersionUpdateWithoutPreviousVersionInput | null;
  upsert?: RawArticleVersionUpdateNextVersionUpsertInput | null;
}

export interface RawArticleVersionUpdateNextVersionUpsertInput {
  create: RawArticleVersionCreationWithoutPreviousVersionInput;
  update?: RawArticleVersionUpdateWithoutPreviousVersionInput | null;
}

export interface RawArticleVersionUpdatePreviousVersionCreateIfNotExistsInput {
  where: RawArticleVersionUniqueFilterInput;
  data: RawArticleVersionCreationInput;
}

export interface RawArticleVersionUpdatePreviousVersionInput {
  connect?: RawArticleVersionUniqueFilterInput | null;
  connectIfExists?: RawArticleVersionUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: RawArticleVersionCreationInput | null;
  createIfNotExists?: RawArticleVersionUpdatePreviousVersionCreateIfNotExistsInput | null;
  update?: RawArticleVersionUpdateInput | null;
  updateIfExists?: RawArticleVersionUpdateInput | null;
}

export interface RawArticleVersionUpdateRawArticleCreateIfNotExistsInput {
  where: RawArticleUniqueFilterInput;
  data: RawArticleCreationInput;
}

export interface RawArticleVersionUpdateRawArticleInput {
  connect?: RawArticleUniqueFilterInput | null;
  create?: RawArticleCreationInput | null;
  createIfNotExists?: RawArticleVersionUpdateRawArticleCreateIfNotExistsInput | null;
  update?: RawArticleUpdateInput | null;
}

/**
 * The "RawArticleVersion" node's update
 */
export interface RawArticleVersionUpdateWithoutForkOfInput {
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle?: RawArticleVersionUpdateRawArticleInput | null;
  previousVersion?: RawArticleVersionUpdatePreviousVersionInput | null;
  nextVersion?: RawArticleVersionUpdateNextVersionInput | null;
  forkChilrens?: RawArticleVersionUpdateForkChilrensInput | null;
}

/**
 * The "RawArticleVersion" node's update
 */
export interface RawArticleVersionUpdateWithoutPreviousVersionInput {
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  rawArticle?: RawArticleVersionUpdateRawArticleInput | null;
  forkOf?: RawArticleVersionUpdateForkOfInput | null;
  nextVersion?: RawArticleVersionUpdateNextVersionInput | null;
  forkChilrens?: RawArticleVersionUpdateForkChilrensInput | null;
}

/**
 * The "RawArticleVersion" node's update
 */
export interface RawArticleVersionUpdateWithoutRawArticleInput {
  editorId?: string | null;
  title?: string | null;
  lead?: string | null;
  body?: string | null;
  signature?: string | null;
  status?: RawArticleStatus | null;
  comment?: string | null;
  leftOver?: string | null;
  previousVersion?: RawArticleVersionUpdatePreviousVersionInput | null;
  forkOf?: RawArticleVersionUpdateForkOfInput | null;
  nextVersion?: RawArticleVersionUpdateNextVersionInput | null;
  forkChilrens?: RawArticleVersionUpdateForkChilrensInput | null;
}

/**
 * The "SubSubject" node's creation
 */
export interface SubSubjectCreationWithoutSubjectInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
}

/**
 * The "SubSubject" nodes' filter
 */
export interface SubSubjectFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  subject?: SubjectFilterInput | null;
  subject_not?: SubjectFilterInput | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  AND?: (SubSubjectFilterInput | null)[] | null;
  OR?: (SubSubjectFilterInput | null)[] | null;
  NOT?: SubSubjectFilterInput | null;
}

/**
 * Given a "subject", identifies exactly one "SubSubject" with one of the following combination of components' value:
 * - title / (subject)
 */
export interface SubSubjectUniqueFilterWithoutSubjectInput {
  title: string;
}

/**
 * The "SubSubject" node's update
 */
export interface SubSubjectUpdateWithoutSubjectInput {
  title?: string | null;
}

export interface SubjectCategoryCreationChildrenInput {
  create?: SubjectCategoryCreationWithoutParentInput[] | null;
}

/**
 * The "SubjectCategory" node's creation
 */
export interface SubjectCategoryCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  unit: SubjectCategoryCreationUnitInput;
  legacyId?: number | null;
  title: string;
  parent?: SubjectCategoryCreationParentInput | null;
  children?: SubjectCategoryCreationChildrenInput | null;
  subjects?: SubjectCategoryCreationSubjectsInput | null;
}

export interface SubjectCategoryCreationParentCreateIfNotExistsInput {
  where: SubjectCategoryUniqueFilterInput;
  data: SubjectCategoryCreationInput;
}

export interface SubjectCategoryCreationParentInput {
  connect?: SubjectCategoryUniqueFilterInput | null;
  connectIfExists?: SubjectCategoryUniqueFilterInput | null;
  create?: SubjectCategoryCreationInput | null;
  createIfNotExists?: SubjectCategoryCreationParentCreateIfNotExistsInput | null;
}

export interface SubjectCategoryCreationSubjectsInput {
  create?: SubjectCreationWithoutSubjectCategoryInput[] | null;
}

export interface SubjectCategoryCreationUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface SubjectCategoryCreationUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: SubjectCategoryCreationUnitCreateIfNotExistsInput | null;
}

/**
 * The "SubjectCategory" node's creation
 */
export interface SubjectCategoryCreationWithoutParentInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  unit: SubjectCategoryCreationUnitInput;
  legacyId?: number | null;
  title: string;
  children?: SubjectCategoryCreationChildrenInput | null;
  subjects?: SubjectCategoryCreationSubjectsInput | null;
}

/**
 * The "SubjectCategory" node's creation
 */
export interface SubjectCategoryCreationWithoutUnitInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  legacyId?: number | null;
  title: string;
  parent?: SubjectCategoryCreationParentInput | null;
  children?: SubjectCategoryCreationChildrenInput | null;
  subjects?: SubjectCategoryCreationSubjectsInput | null;
}

/**
 * The "SubjectCategory" nodes' filter
 */
export interface SubjectCategoryFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  unit?: UnitFilterInput | null;
  unit_not?: UnitFilterInput | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  parent?: SubjectCategoryFilterInput | null;
  parent_not?: SubjectCategoryFilterInput | null;
  parent_is_null?: boolean | null;
  children_every?: SubjectCategoryFilterInput | null;
  children_some?: SubjectCategoryFilterInput | null;
  children_none?: SubjectCategoryFilterInput | null;
  childCount?: GraphQL$UnsignedInt | null;
  childCount_not?: GraphQL$UnsignedInt | null;
  childCount_gt?: GraphQL$UnsignedInt | null;
  childCount_gte?: GraphQL$UnsignedInt | null;
  childCount_lt?: GraphQL$UnsignedInt | null;
  childCount_lte?: GraphQL$UnsignedInt | null;
  subjects_every?: SubjectFilterInput | null;
  subjects_some?: SubjectFilterInput | null;
  subjects_none?: SubjectFilterInput | null;
  subjectCount?: GraphQL$UnsignedInt | null;
  subjectCount_not?: GraphQL$UnsignedInt | null;
  subjectCount_gt?: GraphQL$UnsignedInt | null;
  subjectCount_gte?: GraphQL$UnsignedInt | null;
  subjectCount_lt?: GraphQL$UnsignedInt | null;
  subjectCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (SubjectCategoryFilterInput | null)[] | null;
  OR?: (SubjectCategoryFilterInput | null)[] | null;
  NOT?: SubjectCategoryFilterInput | null;
}

/**
 * Identifies exactly one "SubjectCategory" with one of the following combination of components' value:
 * - _id
 * - id
 */
export interface SubjectCategoryUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
}

export interface SubjectCategoryUpdateChildrenInput {
  deleteAll?: boolean | null;
  deleteMany?: SubjectCategoryFilterInput | null;
  create?: SubjectCategoryCreationWithoutParentInput[] | null;
  updateAll?: SubjectCategoryUpdateWithoutParentInput | null;
  updateMany?: SubjectCategoryUpdateChildrenUpdateManyInput | null;
}

export interface SubjectCategoryUpdateChildrenUpdateManyInput {
  where?: SubjectCategoryFilterInput | null;
  data?: SubjectCategoryUpdateWithoutParentInput | null;
}

/**
 * The "SubjectCategory" node's update
 */
export interface SubjectCategoryUpdateInput {
  unit?: SubjectCategoryUpdateUnitInput | null;
  legacyId?: number | null;
  title?: string | null;
  parent?: SubjectCategoryUpdateParentInput | null;
  children?: SubjectCategoryUpdateChildrenInput | null;
  subjects?: SubjectCategoryUpdateSubjectsInput | null;
}

export interface SubjectCategoryUpdateParentCreateIfNotExistsInput {
  where: SubjectCategoryUniqueFilterInput;
  data: SubjectCategoryCreationInput;
}

export interface SubjectCategoryUpdateParentInput {
  connect?: SubjectCategoryUniqueFilterInput | null;
  connectIfExists?: SubjectCategoryUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: SubjectCategoryCreationInput | null;
  createIfNotExists?: SubjectCategoryUpdateParentCreateIfNotExistsInput | null;
  update?: SubjectCategoryUpdateInput | null;
  updateIfExists?: SubjectCategoryUpdateInput | null;
}

export interface SubjectCategoryUpdateSubjectsInput {
  deleteAll?: boolean | null;
  deleteMany?: SubjectFilterInput | null;
  create?: SubjectCreationWithoutSubjectCategoryInput[] | null;
  updateAll?: SubjectUpdateWithoutSubjectCategoryInput | null;
  updateMany?: SubjectCategoryUpdateSubjectsUpdateManyInput | null;
}

export interface SubjectCategoryUpdateSubjectsUpdateManyInput {
  where?: SubjectFilterInput | null;
  data?: SubjectUpdateWithoutSubjectCategoryInput | null;
}

export interface SubjectCategoryUpdateUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface SubjectCategoryUpdateUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: SubjectCategoryUpdateUnitCreateIfNotExistsInput | null;
  update?: UnitUpdateInput | null;
}

/**
 * The "SubjectCategory" node's update
 */
export interface SubjectCategoryUpdateWithoutParentInput {
  unit?: SubjectCategoryUpdateUnitInput | null;
  legacyId?: number | null;
  title?: string | null;
  children?: SubjectCategoryUpdateChildrenInput | null;
  subjects?: SubjectCategoryUpdateSubjectsInput | null;
}

/**
 * The "SubjectCategory" node's update
 */
export interface SubjectCategoryUpdateWithoutUnitInput {
  legacyId?: number | null;
  title?: string | null;
  parent?: SubjectCategoryUpdateParentInput | null;
  children?: SubjectCategoryUpdateChildrenInput | null;
  subjects?: SubjectCategoryUpdateSubjectsInput | null;
}

export interface SubjectCreationAssignmentsInput {
  create?: AssignmentCreationWithoutSubjectInput[] | null;
}

export interface SubjectCreationAttachmentsInput {
  create?: AttachmentCreationWithoutSubjectInput[] | null;
}

/**
 * The "Subject" node's creation
 */
export interface SubjectCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  unit: SubjectCreationUnitInput;
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  subjectCategory?: SubjectCreationSubjectCategoryInput | null;
  title: string;
  subjectUsers?: SubjectCreationSubjectUsersInput | null;
  subSubjects?: SubjectCreationSubSubjectsInput | null;
  assignments?: SubjectCreationAssignmentsInput | null;
  attachments?: SubjectCreationAttachmentsInput | null;
}

export interface SubjectCreationSubSubjectsInput {
  create?: SubSubjectCreationWithoutSubjectInput[] | null;
}

export interface SubjectCreationSubjectCategoryCreateIfNotExistsInput {
  where: SubjectCategoryUniqueFilterInput;
  data: SubjectCategoryCreationInput;
}

export interface SubjectCreationSubjectCategoryInput {
  connect?: SubjectCategoryUniqueFilterInput | null;
  connectIfExists?: SubjectCategoryUniqueFilterInput | null;
  create?: SubjectCategoryCreationInput | null;
  createIfNotExists?: SubjectCreationSubjectCategoryCreateIfNotExistsInput | null;
}

export interface SubjectCreationSubjectUsersInput {
  create?: SubjectUserCreationWithoutSubjectInput[] | null;
}

export interface SubjectCreationUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface SubjectCreationUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: SubjectCreationUnitCreateIfNotExistsInput | null;
}

/**
 * The "Subject" node's creation
 */
export interface SubjectCreationWithoutSubjectCategoryInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  unit: SubjectCreationUnitInput;
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  title: string;
  subjectUsers?: SubjectCreationSubjectUsersInput | null;
  subSubjects?: SubjectCreationSubSubjectsInput | null;
  assignments?: SubjectCreationAssignmentsInput | null;
  attachments?: SubjectCreationAttachmentsInput | null;
}

/**
 * The "Subject" node's creation
 */
export interface SubjectCreationWithoutUnitInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  subjectCategory?: SubjectCreationSubjectCategoryInput | null;
  title: string;
  subjectUsers?: SubjectCreationSubjectUsersInput | null;
  subSubjects?: SubjectCreationSubSubjectsInput | null;
  assignments?: SubjectCreationAssignmentsInput | null;
  attachments?: SubjectCreationAttachmentsInput | null;
}

/**
 * The "Subject" nodes' filter
 */
export interface SubjectFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  unit?: UnitFilterInput | null;
  unit_not?: UnitFilterInput | null;
  comment?: string | null;
  comment_not?: string | null;
  comment_in?: (string | null)[] | null;
  comment_not_in?: (string | null)[] | null;
  comment_is_null?: boolean | null;
  comment_contains?: GraphQL$NonEmptyString | null;
  comment_not_contains?: GraphQL$NonEmptyString | null;
  comment_starts_with?: GraphQL$NonEmptyString | null;
  comment_not_starts_with?: GraphQL$NonEmptyString | null;
  comment_ends_with?: GraphQL$NonEmptyString | null;
  comment_not_ends_with?: GraphQL$NonEmptyString | null;
  synopsis?: string | null;
  synopsis_not?: string | null;
  synopsis_in?: (string | null)[] | null;
  synopsis_not_in?: (string | null)[] | null;
  synopsis_is_null?: boolean | null;
  synopsis_contains?: GraphQL$NonEmptyString | null;
  synopsis_not_contains?: GraphQL$NonEmptyString | null;
  synopsis_starts_with?: GraphQL$NonEmptyString | null;
  synopsis_not_starts_with?: GraphQL$NonEmptyString | null;
  synopsis_ends_with?: GraphQL$NonEmptyString | null;
  synopsis_not_ends_with?: GraphQL$NonEmptyString | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  mediaRequest?: string | null;
  mediaRequest_not?: string | null;
  mediaRequest_in?: (string | null)[] | null;
  mediaRequest_not_in?: (string | null)[] | null;
  mediaRequest_is_null?: boolean | null;
  mediaRequest_contains?: GraphQL$NonEmptyString | null;
  mediaRequest_not_contains?: GraphQL$NonEmptyString | null;
  mediaRequest_starts_with?: GraphQL$NonEmptyString | null;
  mediaRequest_not_starts_with?: GraphQL$NonEmptyString | null;
  mediaRequest_ends_with?: GraphQL$NonEmptyString | null;
  mediaRequest_not_ends_with?: GraphQL$NonEmptyString | null;
  isClassified?: boolean | null;
  isClassified_not?: boolean | null;
  isAuto?: boolean | null;
  isAuto_not?: boolean | null;
  subjectCategory?: SubjectCategoryFilterInput | null;
  subjectCategory_not?: SubjectCategoryFilterInput | null;
  subjectCategory_is_null?: boolean | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  subjectUsers_every?: SubjectUserFilterInput | null;
  subjectUsers_some?: SubjectUserFilterInput | null;
  subjectUsers_none?: SubjectUserFilterInput | null;
  subjectUserCount?: GraphQL$UnsignedInt | null;
  subjectUserCount_not?: GraphQL$UnsignedInt | null;
  subjectUserCount_gt?: GraphQL$UnsignedInt | null;
  subjectUserCount_gte?: GraphQL$UnsignedInt | null;
  subjectUserCount_lt?: GraphQL$UnsignedInt | null;
  subjectUserCount_lte?: GraphQL$UnsignedInt | null;
  subSubjects_every?: SubSubjectFilterInput | null;
  subSubjects_some?: SubSubjectFilterInput | null;
  subSubjects_none?: SubSubjectFilterInput | null;
  subSubjectCount?: GraphQL$UnsignedInt | null;
  subSubjectCount_not?: GraphQL$UnsignedInt | null;
  subSubjectCount_gt?: GraphQL$UnsignedInt | null;
  subSubjectCount_gte?: GraphQL$UnsignedInt | null;
  subSubjectCount_lt?: GraphQL$UnsignedInt | null;
  subSubjectCount_lte?: GraphQL$UnsignedInt | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  attachments_every?: AttachmentFilterInput | null;
  attachments_some?: AttachmentFilterInput | null;
  attachments_none?: AttachmentFilterInput | null;
  attachmentCount?: GraphQL$UnsignedInt | null;
  attachmentCount_not?: GraphQL$UnsignedInt | null;
  attachmentCount_gt?: GraphQL$UnsignedInt | null;
  attachmentCount_gte?: GraphQL$UnsignedInt | null;
  attachmentCount_lt?: GraphQL$UnsignedInt | null;
  attachmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (SubjectFilterInput | null)[] | null;
  OR?: (SubjectFilterInput | null)[] | null;
  NOT?: SubjectFilterInput | null;
}

/**
 * Identifies exactly one "Subject" with one of the following combination of components' value:
 * - _id
 * - id
 * - title / unit
 * - legacyId / unit
 */
export interface SubjectUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title?: string | null;
  unit?: UnitUniqueFilterInput | null;
  legacyId?: number | null;
}

/**
 * Given a "unit", identifies exactly one "Subject" with one of the following combination of components' value:
 * - title / (unit)
 * - legacyId / (unit)
 */
export interface SubjectUniqueFilterWithoutUnitInput {
  title?: string | null;
  legacyId?: number | null;
}

export interface SubjectUpdateAssignmentsCreateIfNotExistsInput {
  where: AssignmentUniqueFilterWithoutSubjectInput;
  data: AssignmentCreationWithoutSubjectInput;
}

export interface SubjectUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  delete?: AssignmentUniqueFilterWithoutSubjectInput[] | null;
  deleteIfExists?: AssignmentUniqueFilterWithoutSubjectInput[] | null;
  create?: AssignmentCreationWithoutSubjectInput[] | null;
  createIfNotExists?: SubjectUpdateAssignmentsCreateIfNotExistsInput[] | null;
  updateAll?: AssignmentUpdateWithoutSubjectInput | null;
  updateMany?: SubjectUpdateAssignmentsUpdateManyInput | null;
  update?: SubjectUpdateAssignmentsUpdateInput[] | null;
  updateIfExists?: SubjectUpdateAssignmentsUpdateIfExistsInput[] | null;
  upsert?: SubjectUpdateAssignmentsUpsertInput[] | null;
}

export interface SubjectUpdateAssignmentsUpdateIfExistsInput {
  where: AssignmentUniqueFilterWithoutSubjectInput;
  data?: AssignmentUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateAssignmentsUpdateInput {
  where: AssignmentUniqueFilterWithoutSubjectInput;
  data?: AssignmentUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateAssignmentsUpsertInput {
  where: AssignmentUniqueFilterWithoutSubjectInput;
  create: AssignmentCreationWithoutSubjectInput;
  update?: AssignmentUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateAttachmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AttachmentFilterInput | null;
  create?: AttachmentCreationWithoutSubjectInput[] | null;
  updateAll?: AttachmentUpdateWithoutSubjectInput | null;
  updateMany?: SubjectUpdateAttachmentsUpdateManyInput | null;
}

export interface SubjectUpdateAttachmentsUpdateManyInput {
  where?: AttachmentFilterInput | null;
  data?: AttachmentUpdateWithoutSubjectInput | null;
}

/**
 * The "Subject" node's update
 */
export interface SubjectUpdateInput {
  unit?: SubjectUpdateUnitInput | null;
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  subjectCategory?: SubjectUpdateSubjectCategoryInput | null;
  title?: string | null;
  subjectUsers?: SubjectUpdateSubjectUsersInput | null;
  subSubjects?: SubjectUpdateSubSubjectsInput | null;
  assignments?: SubjectUpdateAssignmentsInput | null;
  attachments?: SubjectUpdateAttachmentsInput | null;
}

export interface SubjectUpdateSubSubjectsCreateIfNotExistsInput {
  where: SubSubjectUniqueFilterWithoutSubjectInput;
  data: SubSubjectCreationWithoutSubjectInput;
}

export interface SubjectUpdateSubSubjectsInput {
  deleteAll?: boolean | null;
  deleteMany?: SubSubjectFilterInput | null;
  delete?: SubSubjectUniqueFilterWithoutSubjectInput[] | null;
  deleteIfExists?: SubSubjectUniqueFilterWithoutSubjectInput[] | null;
  create?: SubSubjectCreationWithoutSubjectInput[] | null;
  createIfNotExists?: SubjectUpdateSubSubjectsCreateIfNotExistsInput[] | null;
  updateAll?: SubSubjectUpdateWithoutSubjectInput | null;
  updateMany?: SubjectUpdateSubSubjectsUpdateManyInput | null;
  update?: SubjectUpdateSubSubjectsUpdateInput[] | null;
  updateIfExists?: SubjectUpdateSubSubjectsUpdateIfExistsInput[] | null;
  upsert?: SubjectUpdateSubSubjectsUpsertInput[] | null;
}

export interface SubjectUpdateSubSubjectsUpdateIfExistsInput {
  where: SubSubjectUniqueFilterWithoutSubjectInput;
  data?: SubSubjectUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateSubSubjectsUpdateInput {
  where: SubSubjectUniqueFilterWithoutSubjectInput;
  data?: SubSubjectUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateSubSubjectsUpdateManyInput {
  where?: SubSubjectFilterInput | null;
  data?: SubSubjectUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateSubSubjectsUpsertInput {
  where: SubSubjectUniqueFilterWithoutSubjectInput;
  create: SubSubjectCreationWithoutSubjectInput;
  update?: SubSubjectUpdateWithoutSubjectInput | null;
}

export interface SubjectUpdateSubjectCategoryCreateIfNotExistsInput {
  where: SubjectCategoryUniqueFilterInput;
  data: SubjectCategoryCreationInput;
}

export interface SubjectUpdateSubjectCategoryInput {
  connect?: SubjectCategoryUniqueFilterInput | null;
  connectIfExists?: SubjectCategoryUniqueFilterInput | null;
  disconnect?: boolean | null;
  disconnectIfExists?: boolean | null;
  create?: SubjectCategoryCreationInput | null;
  createIfNotExists?: SubjectUpdateSubjectCategoryCreateIfNotExistsInput | null;
  update?: SubjectCategoryUpdateInput | null;
  updateIfExists?: SubjectCategoryUpdateInput | null;
}

export interface SubjectUpdateSubjectUsersCreateIfNotExistsInput {
  where: SubjectUserUniqueFilterWithoutSubjectInput;
  data: SubjectUserCreationWithoutSubjectInput;
}

export interface SubjectUpdateSubjectUsersInput {
  deleteAll?: boolean | null;
  deleteMany?: SubjectUserFilterInput | null;
  delete?: SubjectUserUniqueFilterWithoutSubjectInput[] | null;
  deleteIfExists?: SubjectUserUniqueFilterWithoutSubjectInput[] | null;
  create?: SubjectUserCreationWithoutSubjectInput[] | null;
  createIfNotExists?: SubjectUpdateSubjectUsersCreateIfNotExistsInput[] | null;
}

export interface SubjectUpdateUnitCreateIfNotExistsInput {
  where: UnitUniqueFilterInput;
  data: UnitCreationInput;
}

export interface SubjectUpdateUnitInput {
  connect?: UnitUniqueFilterInput | null;
  create?: UnitCreationInput | null;
  createIfNotExists?: SubjectUpdateUnitCreateIfNotExistsInput | null;
  update?: UnitUpdateInput | null;
}

/**
 * The "Subject" node's update
 */
export interface SubjectUpdateWithoutSubjectCategoryInput {
  unit?: SubjectUpdateUnitInput | null;
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  title?: string | null;
  subjectUsers?: SubjectUpdateSubjectUsersInput | null;
  subSubjects?: SubjectUpdateSubSubjectsInput | null;
  assignments?: SubjectUpdateAssignmentsInput | null;
  attachments?: SubjectUpdateAttachmentsInput | null;
}

/**
 * The "Subject" node's update
 */
export interface SubjectUpdateWithoutUnitInput {
  comment?: string | null;
  synopsis?: string | null;
  legacyId?: number | null;
  mediaRequest?: string | null;
  isClassified?: boolean | null;
  isAuto?: boolean | null;
  subjectCategory?: SubjectUpdateSubjectCategoryInput | null;
  title?: string | null;
  subjectUsers?: SubjectUpdateSubjectUsersInput | null;
  subSubjects?: SubjectUpdateSubSubjectsInput | null;
  assignments?: SubjectUpdateAssignmentsInput | null;
  attachments?: SubjectUpdateAttachmentsInput | null;
}

/**
 * The "SubjectUser" node's creation
 */
export interface SubjectUserCreationWithoutSubjectInput {
  userId: string;
}

/**
 * The "SubjectUser" nodes' filter
 */
export interface SubjectUserFilterInput {
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  userId?: string | null;
  userId_not?: string | null;
  userId_in?: string[] | null;
  userId_not_in?: string[] | null;
  userId_contains?: GraphQL$NonEmptyString | null;
  userId_not_contains?: GraphQL$NonEmptyString | null;
  userId_starts_with?: GraphQL$NonEmptyString | null;
  userId_not_starts_with?: GraphQL$NonEmptyString | null;
  userId_ends_with?: GraphQL$NonEmptyString | null;
  userId_not_ends_with?: GraphQL$NonEmptyString | null;
  subject?: SubjectFilterInput | null;
  subject_not?: SubjectFilterInput | null;
  AND?: (SubjectUserFilterInput | null)[] | null;
  OR?: (SubjectUserFilterInput | null)[] | null;
  NOT?: SubjectUserFilterInput | null;
}

/**
 * Given a "subject", identifies exactly one "SubjectUser" with one of the following combination of components' value:
 * - userId / (subject)
 */
export interface SubjectUserUniqueFilterWithoutSubjectInput {
  userId: string;
}

export interface UnitCreationBrandsInput {
  create?: BrandCreationWithoutUnitInput[] | null;
}

/**
 * The "Unit" node's creation
 */
export interface UnitCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  subjects?: UnitCreationSubjectsInput | null;
  subjectCategories?: UnitCreationSubjectCategoriesInput | null;
  brands?: UnitCreationBrandsInput | null;
}

export interface UnitCreationSubjectCategoriesInput {
  create?: SubjectCategoryCreationWithoutUnitInput[] | null;
}

export interface UnitCreationSubjectsInput {
  create?: SubjectCreationWithoutUnitInput[] | null;
}

/**
 * The "Unit" nodes' filter
 */
export interface UnitFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_gt?: string | null;
  title_gte?: string | null;
  title_lt?: string | null;
  title_lte?: string | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  subjects_every?: SubjectFilterInput | null;
  subjects_some?: SubjectFilterInput | null;
  subjects_none?: SubjectFilterInput | null;
  subjectCount?: GraphQL$UnsignedInt | null;
  subjectCount_not?: GraphQL$UnsignedInt | null;
  subjectCount_gt?: GraphQL$UnsignedInt | null;
  subjectCount_gte?: GraphQL$UnsignedInt | null;
  subjectCount_lt?: GraphQL$UnsignedInt | null;
  subjectCount_lte?: GraphQL$UnsignedInt | null;
  subjectCategories_every?: SubjectCategoryFilterInput | null;
  subjectCategories_some?: SubjectCategoryFilterInput | null;
  subjectCategories_none?: SubjectCategoryFilterInput | null;
  subjectCategoryCount?: GraphQL$UnsignedInt | null;
  subjectCategoryCount_not?: GraphQL$UnsignedInt | null;
  subjectCategoryCount_gt?: GraphQL$UnsignedInt | null;
  subjectCategoryCount_gte?: GraphQL$UnsignedInt | null;
  subjectCategoryCount_lt?: GraphQL$UnsignedInt | null;
  subjectCategoryCount_lte?: GraphQL$UnsignedInt | null;
  brands_every?: BrandFilterInput | null;
  brands_some?: BrandFilterInput | null;
  brands_none?: BrandFilterInput | null;
  brandCount?: GraphQL$UnsignedInt | null;
  brandCount_not?: GraphQL$UnsignedInt | null;
  brandCount_gt?: GraphQL$UnsignedInt | null;
  brandCount_gte?: GraphQL$UnsignedInt | null;
  brandCount_lt?: GraphQL$UnsignedInt | null;
  brandCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (UnitFilterInput | null)[] | null;
  OR?: (UnitFilterInput | null)[] | null;
  NOT?: UnitFilterInput | null;
}

/**
 * Identifies exactly one "Unit" with one of the following combination of components' value:
 * - _id
 * - id
 */
export interface UnitUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
}

export interface UnitUpdateBrandsInput {
  deleteAll?: boolean | null;
  deleteMany?: BrandFilterInput | null;
  create?: BrandCreationWithoutUnitInput[] | null;
  updateAll?: BrandUpdateWithoutUnitInput | null;
  updateMany?: UnitUpdateBrandsUpdateManyInput | null;
}

export interface UnitUpdateBrandsUpdateManyInput {
  where?: BrandFilterInput | null;
  data?: BrandUpdateWithoutUnitInput | null;
}

/**
 * The "Unit" node's update
 */
export interface UnitUpdateInput {
  title?: string | null;
  subjects?: UnitUpdateSubjectsInput | null;
  subjectCategories?: UnitUpdateSubjectCategoriesInput | null;
  brands?: UnitUpdateBrandsInput | null;
}

export interface UnitUpdateSubjectCategoriesInput {
  deleteAll?: boolean | null;
  deleteMany?: SubjectCategoryFilterInput | null;
  create?: SubjectCategoryCreationWithoutUnitInput[] | null;
  updateAll?: SubjectCategoryUpdateWithoutUnitInput | null;
  updateMany?: UnitUpdateSubjectCategoriesUpdateManyInput | null;
}

export interface UnitUpdateSubjectCategoriesUpdateManyInput {
  where?: SubjectCategoryFilterInput | null;
  data?: SubjectCategoryUpdateWithoutUnitInput | null;
}

export interface UnitUpdateSubjectsCreateIfNotExistsInput {
  where: SubjectUniqueFilterWithoutUnitInput;
  data: SubjectCreationWithoutUnitInput;
}

export interface UnitUpdateSubjectsInput {
  deleteAll?: boolean | null;
  deleteMany?: SubjectFilterInput | null;
  delete?: SubjectUniqueFilterWithoutUnitInput[] | null;
  deleteIfExists?: SubjectUniqueFilterWithoutUnitInput[] | null;
  create?: SubjectCreationWithoutUnitInput[] | null;
  createIfNotExists?: UnitUpdateSubjectsCreateIfNotExistsInput[] | null;
  updateAll?: SubjectUpdateWithoutUnitInput | null;
  updateMany?: UnitUpdateSubjectsUpdateManyInput | null;
  update?: UnitUpdateSubjectsUpdateInput[] | null;
  updateIfExists?: UnitUpdateSubjectsUpdateIfExistsInput[] | null;
  upsert?: UnitUpdateSubjectsUpsertInput[] | null;
}

export interface UnitUpdateSubjectsUpdateIfExistsInput {
  where: SubjectUniqueFilterWithoutUnitInput;
  data?: SubjectUpdateWithoutUnitInput | null;
}

export interface UnitUpdateSubjectsUpdateInput {
  where: SubjectUniqueFilterWithoutUnitInput;
  data?: SubjectUpdateWithoutUnitInput | null;
}

export interface UnitUpdateSubjectsUpdateManyInput {
  where?: SubjectFilterInput | null;
  data?: SubjectUpdateWithoutUnitInput | null;
}

export interface UnitUpdateSubjectsUpsertInput {
  where: SubjectUniqueFilterWithoutUnitInput;
  create: SubjectCreationWithoutUnitInput;
  update?: SubjectUpdateWithoutUnitInput | null;
}

export interface WebsiteCreationAssignmentsInput {
  create?: AssignmentCreationWithoutWebsiteInput[] | null;
}

export interface WebsiteCreationBrandCreateIfNotExistsInput {
  where: BrandUniqueFilterInput;
  data: BrandCreationInput;
}

export interface WebsiteCreationBrandInput {
  connect?: BrandUniqueFilterInput | null;
  create?: BrandCreationInput | null;
  createIfNotExists?: WebsiteCreationBrandCreateIfNotExistsInput | null;
}

/**
 * The "Website" node's creation
 */
export interface WebsiteCreationInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  key: string;
  legacyId?: number | null;
  brand: WebsiteCreationBrandInput;
  assignments?: WebsiteCreationAssignmentsInput | null;
}

/**
 * The "Website" node's creation
 */
export interface WebsiteCreationWithoutBrandInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  title: string;
  key: string;
  legacyId?: number | null;
  assignments?: WebsiteCreationAssignmentsInput | null;
}

/**
 * The "Website" nodes' filter
 */
export interface WebsiteFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  _id_not?: GraphQL$UnsignedInt | null;
  _id_in?: GraphQL$UnsignedInt[] | null;
  _id_not_in?: GraphQL$UnsignedInt[] | null;
  _id_gt?: GraphQL$UnsignedInt | null;
  _id_gte?: GraphQL$UnsignedInt | null;
  _id_lt?: GraphQL$UnsignedInt | null;
  _id_lte?: GraphQL$UnsignedInt | null;
  id?: string | null;
  id_not?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: GraphQL$DateTime | null;
  createdAt_not?: GraphQL$DateTime | null;
  createdAt_in?: GraphQL$DateTime[] | null;
  createdAt_not_in?: GraphQL$DateTime[] | null;
  createdAt_gt?: GraphQL$DateTime | null;
  createdAt_gte?: GraphQL$DateTime | null;
  createdAt_lt?: GraphQL$DateTime | null;
  createdAt_lte?: GraphQL$DateTime | null;
  updatedAt?: GraphQL$DateTime | null;
  updatedAt_not?: GraphQL$DateTime | null;
  updatedAt_in?: GraphQL$DateTime[] | null;
  updatedAt_not_in?: GraphQL$DateTime[] | null;
  updatedAt_gt?: GraphQL$DateTime | null;
  updatedAt_gte?: GraphQL$DateTime | null;
  updatedAt_lt?: GraphQL$DateTime | null;
  updatedAt_lte?: GraphQL$DateTime | null;
  creatorId?: string | null;
  creatorId_not?: string | null;
  creatorId_in?: string[] | null;
  creatorId_not_in?: string[] | null;
  lastOperatorId?: string | null;
  lastOperatorId_not?: string | null;
  lastOperatorId_in?: string[] | null;
  lastOperatorId_not_in?: string[] | null;
  title?: string | null;
  title_not?: string | null;
  title_in?: string[] | null;
  title_not_in?: string[] | null;
  title_contains?: GraphQL$NonEmptyString | null;
  title_not_contains?: GraphQL$NonEmptyString | null;
  title_starts_with?: GraphQL$NonEmptyString | null;
  title_not_starts_with?: GraphQL$NonEmptyString | null;
  title_ends_with?: GraphQL$NonEmptyString | null;
  title_not_ends_with?: GraphQL$NonEmptyString | null;
  key?: string | null;
  key_not?: string | null;
  key_in?: string[] | null;
  key_not_in?: string[] | null;
  key_gt?: string | null;
  key_gte?: string | null;
  key_lt?: string | null;
  key_lte?: string | null;
  key_contains?: GraphQL$NonEmptyString | null;
  key_not_contains?: GraphQL$NonEmptyString | null;
  key_starts_with?: GraphQL$NonEmptyString | null;
  key_not_starts_with?: GraphQL$NonEmptyString | null;
  key_ends_with?: GraphQL$NonEmptyString | null;
  key_not_ends_with?: GraphQL$NonEmptyString | null;
  legacyId?: number | null;
  legacyId_not?: number | null;
  legacyId_in?: (number | null)[] | null;
  legacyId_not_in?: (number | null)[] | null;
  legacyId_gt?: number | null;
  legacyId_gte?: number | null;
  legacyId_lt?: number | null;
  legacyId_lte?: number | null;
  legacyId_is_null?: boolean | null;
  brand?: BrandFilterInput | null;
  brand_not?: BrandFilterInput | null;
  assignments_every?: AssignmentFilterInput | null;
  assignments_some?: AssignmentFilterInput | null;
  assignments_none?: AssignmentFilterInput | null;
  assignmentCount?: GraphQL$UnsignedInt | null;
  assignmentCount_not?: GraphQL$UnsignedInt | null;
  assignmentCount_gt?: GraphQL$UnsignedInt | null;
  assignmentCount_gte?: GraphQL$UnsignedInt | null;
  assignmentCount_lt?: GraphQL$UnsignedInt | null;
  assignmentCount_lte?: GraphQL$UnsignedInt | null;
  flatAssignments_every?: FlatAssignmentFilterInput | null;
  flatAssignments_some?: FlatAssignmentFilterInput | null;
  flatAssignments_none?: FlatAssignmentFilterInput | null;
  flatAssignmentCount?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_not?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_gte?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lt?: GraphQL$UnsignedInt | null;
  flatAssignmentCount_lte?: GraphQL$UnsignedInt | null;
  AND?: (WebsiteFilterInput | null)[] | null;
  OR?: (WebsiteFilterInput | null)[] | null;
  NOT?: WebsiteFilterInput | null;
}

/**
 * Identifies exactly one "Website" with one of the following combination of components' value:
 * - _id
 * - id
 * - key
 * - legacyId / brand
 * - title / brand
 */
export interface WebsiteUniqueFilterInput {
  _id?: GraphQL$UnsignedInt | null;
  id?: string | null;
  key?: string | null;
  legacyId?: number | null;
  brand?: BrandUniqueFilterInput | null;
  title?: string | null;
}

/**
 * Given a "brand", identifies exactly one "Website" with one of the following combination of components' value:
 * - legacyId / (brand)
 * - title / (brand)
 */
export interface WebsiteUniqueFilterWithoutBrandInput {
  legacyId?: number | null;
  title?: string | null;
}

export interface WebsiteUpdateAssignmentsInput {
  deleteAll?: boolean | null;
  deleteMany?: AssignmentFilterInput | null;
  create?: AssignmentCreationWithoutWebsiteInput[] | null;
  updateAll?: AssignmentUpdateWithoutWebsiteInput | null;
  updateMany?: WebsiteUpdateAssignmentsUpdateManyInput | null;
}

export interface WebsiteUpdateAssignmentsUpdateManyInput {
  where?: AssignmentFilterInput | null;
  data?: AssignmentUpdateWithoutWebsiteInput | null;
}

export interface WebsiteUpdateBrandCreateIfNotExistsInput {
  where: BrandUniqueFilterInput;
  data: BrandCreationInput;
}

export interface WebsiteUpdateBrandInput {
  connect?: BrandUniqueFilterInput | null;
  create?: BrandCreationInput | null;
  createIfNotExists?: WebsiteUpdateBrandCreateIfNotExistsInput | null;
  update?: BrandUpdateInput | null;
}

/**
 * The "Website" node's update
 */
export interface WebsiteUpdateInput {
  title?: string | null;
  key?: string | null;
  legacyId?: number | null;
  brand?: WebsiteUpdateBrandInput | null;
  assignments?: WebsiteUpdateAssignmentsInput | null;
}

/**
 * The "Website" node's update
 */
export interface WebsiteUpdateWithoutBrandInput {
  title?: string | null;
  key?: string | null;
  legacyId?: number | null;
  assignments?: WebsiteUpdateAssignmentsInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
