import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  SubjectForm_DeleteAssignment,
  SubjectForm_DeleteAssignmentVariables,
} from '../../../__generated__/queries-topic';

const DELETE_ASSIGNMENT = gql`
  mutation SubjectForm_DeleteAssignment($id: ID!) {
    deleteAssignment(where: { id: $id }) {
      id
    }
  }
`;

export const useAssignmentDeleter = (
  options: MutationHookOptions<
    SubjectForm_DeleteAssignment,
    SubjectForm_DeleteAssignmentVariables
  >,
) =>
  useMutation<
    SubjectForm_DeleteAssignment,
    SubjectForm_DeleteAssignmentVariables
  >(DELETE_ASSIGNMENT, options);
