import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  CreateRawArticleVersionEditorTransfer,
  CreateRawArticleVersionEditorTransferVariables,
} from '../../../__generated__/queries-topic';
import { VERSION_FRAGMENT } from '../version.fragment.topic.graphql';

const CREATE_RAW_ARTICLE_VERSION_EDITOR_TRANSFER = gql`
  mutation CreateRawArticleVersionEditorTransfer(
    $data: RawArticleVersionCreationInput!
  ) {
    createRawArticleVersion(data: $data) {
      ...ArticleForm_RawArticleVersionFragment
      editorId
    }
  }
  ${VERSION_FRAGMENT}
`;

export const useRawArticleVersionEditorTransferCreator = (
  options?: MutationHookOptions<
    CreateRawArticleVersionEditorTransfer,
    CreateRawArticleVersionEditorTransferVariables
  >,
) =>
  useMutation<
    CreateRawArticleVersionEditorTransfer,
    CreateRawArticleVersionEditorTransferVariables
  >(CREATE_RAW_ARTICLE_VERSION_EDITOR_TRANSFER, options);
