import { IconButton, ListItem, ListItemText } from '@mui/material';
import FilterIcon from '@mui/icons-material/Filter';
import React, { FC } from 'react';
import {
  getColorByStatus,
  getLabelByStatus,
} from '../../../utils/mediaSearchStatus';
import {
  GetSubjectForMediaStatusDialog_subject_assignments,
  TemplateType,
} from '../../../__generated__/queries-topic';
import { AssignmentTitle } from '../../AssignmentTitle';
import { ArticleLink } from '../../Link/ArticleLink';
import { useStyles } from './styles';

interface AssignmentItemProps {
  assignment: GetSubjectForMediaStatusDialog_subject_assignments;
  withLink?: boolean;
  withMediaStatus?: boolean;
}

export const AssignmentItem: FC<AssignmentItemProps> = ({
  assignment,
  withLink,
  withMediaStatus,
}) => {
  const classes = useStyles();
  const isAuto =
    Boolean(assignment?.printHeading?.autoPrototype) ||
    Boolean(assignment.printTemplate?.id);
  const isMultiSubject =
    Boolean(assignment?.printHeading?.autoPrototype) &&
    assignment.printHeading?.printTemplates[0]?.printTemplate.type ===
      TemplateType.MULTIPLE_ARTICLE_TEMPLATE;

  return (
    <ListItem className={classes.root}>
      <ListItemText
        primary={
          <AssignmentTitle assignment={assignment} withLinkOnPrintIssue />
        }
      />
      {withLink && (
        <IconButton
          aria-label="Aller au texte"
          data-cy="subject-list-assignment-goto-article"
          className={classes.button}
          disabled={!!assignment.articleTemplate && !isMultiSubject}
          size="large"
        >
          <ArticleLink
            subjectId={assignment.subject.id}
            assignment={{
              isMultiSubject: !!assignment.articleTemplate || isMultiSubject,
              printTemplateId: assignment.printTemplate?.id,
              printHeadingId: assignment.printHeading?.id,
              autoPrototype: isAuto,
              id: assignment && assignment.id,
              rawArticle: assignment.rawArticle,
              website: assignment.website,
            }}
            tooltipProps={{ placement: 'top-end' }}
          />
        </IconButton>
      )}
      {withMediaStatus && (
        <FilterIcon
          style={{ color: getColorByStatus(assignment.mediaSearchStatus) }}
          titleAccess={getLabelByStatus(assignment.mediaSearchStatus)}
        />
      )}
    </ListItem>
  );
};
