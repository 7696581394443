import { Dialog } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import {
  LocationPage_GetPrintHeadingByPrintIssue_printHeadings,
  LocationPage_GetSubjectsFromPrintHeading,
} from '../../../../__generated__/queries-topic';
import { generateSubjectTitle } from '../../utils';
import { DialogDisableAutoSubject } from './DialogDisableAutoSubject';
import { DialogSubjectCategory } from './DialogSubjectCategory';
import { DialogSubjectExist } from './DialogSubjectExist';
import { DialogSubjectTitle } from './DialogSubjectTitle';
import { useSubjectsFromPrintHeadingGetter } from './getSubjectsFromPrintHeading.topic.graphql';
import { useStyles } from './styles';

export interface AutoSubjectDialogFormProps {
  open: boolean;
  printHeading: LocationPage_GetPrintHeadingByPrintIssue_printHeadings;
  printIssueTitle: string;
  printIssueId: string;
  printPublicationTrigramme: string;
  unitId: string;
  handleClose: () => void;
}

interface AutoSubjectDialogFormState {
  subjects: LocationPage_GetSubjectsFromPrintHeading['subjects'];
  dialogStep: number;
}

export const AutoSubjectDialogForm: FC<AutoSubjectDialogFormProps> = ({
  open,
  printHeading,
  handleClose,
  printIssueTitle,
  printIssueId,
  printPublicationTrigramme,
  unitId,
}) => {
  const classes = useStyles();
  const [
    { subjects, dialogStep },
    setDialogState,
  ] = useState<AutoSubjectDialogFormState>({
    subjects: [],
    dialogStep: printHeading.hasAutomaticSubject ? 3 : 0,
  });
  const subjectTitle = generateSubjectTitle(
    printPublicationTrigramme,
    printIssueTitle,
    printHeading.title,
  );
  const getSubjectsFromPrintHeading = useSubjectsFromPrintHeadingGetter();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getSubjectsFromPrintHeading({
        where: {
          flatAssignments_some: {
            printHeading: { id: printHeading.id },
          },
        },
      });
      setDialogState({
        subjects: data.subjects,
        dialogStep: data.subjects.length > 0 ? 0 : 1,
      });
    };
    if (!printHeading.hasAutomaticSubject) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printHeading]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      classes={{ paperFullWidth: classes.dialog }}
    >
      {subjects.length > 0 && dialogStep === 0 && (
        <DialogSubjectExist
          subjects={subjects}
          handleClose={handleClose}
          changeStep={(step: number) => {
            setDialogState((prevState) => ({ ...prevState, dialogStep: step }));
          }}
        />
      )}
      {dialogStep === 1 && (
        <DialogSubjectTitle
          title={subjectTitle}
          handleClose={handleClose}
          changeStep={(step: number) => {
            setDialogState((prevState) => ({ ...prevState, dialogStep: step }));
          }}
        />
      )}
      {dialogStep === 2 && (
        <DialogSubjectCategory
          unitId={unitId}
          title={subjectTitle}
          subjectId={
            subjects.find((subject) => subject.title === subjectTitle)?.id
          }
          handleClose={handleClose}
          printHeading={printHeading}
          printIssueId={printIssueId}
        />
      )}
      {dialogStep === 3 && (
        <DialogDisableAutoSubject
          handleClose={handleClose}
          printHeading={printHeading}
          printIssueId={printIssueId}
        />
      )}
    </Dialog>
  );
};
