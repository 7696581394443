import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  LoadingButton,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { propEq, reject } from 'ramda';
import React, { useCallback, useState } from 'react';
import { LocationPage_GetPrintIssuesByPrintPublication } from '../../../../__generated__/queries-topic';
import { SortablePrintIssue } from '../../../../utils/sortPrintIssues';
import {
  GET_PRINT_HEADING_BY_PRINT_ISSUE,
  useGetPrintHeadingByPrintIssue,
} from '../../PrintHeading/getPrintHeadingsByPrintIssue.topic.graphql';
import { GET_PRINT_ISSUES_BY_PRINT_PUBLICATION } from '../getPrintIssuesByPrintPublication.topic.graphql';
import { usePrintIssueDeleter } from './deletePrintIssue.topic.graphql';
import { useGetDeletePrintIssueInformation } from './getDeletePrintIssueInformation.topic.graphql';
import { useStyles } from './styles';

interface PrintIssueDialogFormProps {
  open: boolean;
  printIssue?: SortablePrintIssue;
  handleClose: () => void;
  printPublicationId: string;
  printPublicationTitle: string;
}

export const DeletePrintIssueDialog: React.FC<PrintIssueDialogFormProps> = ({
  open,
  printIssue,
  handleClose,
  printPublicationId,
  printPublicationTitle,
}) => {
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    data: printHeadingData,
    error: printHeadingError,
    loading: printHeadingLoading,
  } = useGetPrintHeadingByPrintIssue({ printIssueId: printIssue?.id || '' });
  const [deletePrintIssue] = usePrintIssueDeleter(
    printHeadingData?.printHeadings,
    {
      onCompleted: () => {
        handleClose();
        pushNotification({
          message: `La parution ${printIssue?.title} a bien été supprimée pour ${printPublicationTitle}`,
          type: NotificationTypeEnum.success,
        });
      },
      update: (cache, { data: deletedPrintIssueData }) => {
        if (!deletedPrintIssueData?.deletePrintIssue?.id) {
          return;
        }
        // refresh printHeadings cache
        cache.writeQuery({
          query: GET_PRINT_HEADING_BY_PRINT_ISSUE,
          variables: { printIssueId: printIssue?.id },
          data: {
            printIssue: null,
          },
        });
        // refresh printIssues cache
        const currentPrintPublicationData = cache.readQuery<LocationPage_GetPrintIssuesByPrintPublication>(
          {
            query: GET_PRINT_ISSUES_BY_PRINT_PUBLICATION,
            variables: { printPublicationId },
          },
        );
        if (
          currentPrintPublicationData &&
          currentPrintPublicationData.printPublication
        ) {
          const printIssueIdDeleted = propEq('id', printIssue?.id);
          cache.writeQuery({
            query: GET_PRINT_ISSUES_BY_PRINT_PUBLICATION,
            variables: { printPublicationId },
            data: {
              ...currentPrintPublicationData,
              printIssues: reject(
                printIssueIdDeleted,
                currentPrintPublicationData.printIssues,
              ),
            },
          });
        }
      },
    },
  );
  const {
    data,
    loading: printIssueLoading,
    error,
  } = useGetDeletePrintIssueInformation({
    id: printIssue?.id || '',
  });

  const handleDeletePrintIssue = useCallback(async () => {
    setIsDeleting(true);
    printHeadingData?.printHeadings &&
      (await deletePrintIssue({
        variables: { id: printIssue?.id || '' },
      }));
    setIsDeleting(false);
  }, [printIssue, printHeadingData, deletePrintIssue]);

  if (!printIssue) {
    return null;
  }
  if (printHeadingError) {
    return <p>ERROR</p>;
  }
  if (!printHeadingLoading && !printHeadingData?.printHeadings) {
    return <p>NO DATA</p>;
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.paper }}
    >
      {printIssueLoading ? (
        <div>LOADING</div>
      ) : error ? (
        <div>REAL ERROR</div>
      ) : !data || !data.printIssue ? (
        <div>NO DATA</div>
      ) : (
        <>
          <DialogTitle>
            Supprimer une parution{' '}
            <strong>
              {data.printIssue.printPublication?.title} {data.printIssue.title}
            </strong>
          </DialogTitle>
          {data.printIssue.printHeadings.length > 0 ? (
            <DialogContent>
              <DialogContentText>
                {`La parution ${data.printIssue.title} 
                              ne peut pas être supprimée pour 
                              ${data.printIssue.printPublication?.title} car des sujets y sont affectés`}
              </DialogContentText>
            </DialogContent>
          ) : (
            <DialogContent>
              <DialogContentText>
                {`Êtes-vous sûr de vouloir supprimer la parution ${data.printIssue.title} 
                              de ${data.printIssue.printPublication?.title} ?`}
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>
            {data.printIssue.printHeadings.length === 0 && (
              <LoadingButton
                color="secondary"
                variant="contained"
                loading={isDeleting}
                onClick={handleDeletePrintIssue}
              >
                Confirmer suppression
              </LoadingButton>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
