import Typography from '@mui/material/Typography';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import React, { FC, useState } from 'react';
import { UnitSpeedDial_GetUnits_units } from '../../../__generated__/queries-topic';
import { useGetUnits } from './getUnits.topic.graphql';
import { useStyles } from './styles';

interface UnitSpeedDialProps {
  onUnitClick?: (unit: UnitSpeedDial_GetUnits_units) => void;
}

export const UnitSpeedDial: FC<UnitSpeedDialProps> = ({ onUnitClick }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { data: unitsData } = useGetUnits();

  let isTouch = false;
  if (typeof document !== 'undefined') {
    // TODO WTF it's always false ?
    isTouch = 'ontouchstart' in document.documentElement && false;
  }

  const handleClick = () => setIsOpen((prev) => !prev);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleUnitClick = (unit: UnitSpeedDial_GetUnits_units) => {
    handleClick();
    onUnitClick && onUnitClick(unit);
  };

  const units = unitsData && unitsData.units ? unitsData.units : [];
  if (units && units.length === 1) {
    const [unit] = units;
    return (
      <SpeedDial
        data-cy="unit-speed-dial"
        ariaLabel="Unit speed dial"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClick={() => handleUnitClick(unit)}
        open={isOpen}
      />
    );
  }
  return (
    <SpeedDial
      data-cy="unit-speed-dial"
      ariaLabel="Unit speed dial"
      className={classes.speedDial}
      icon={<SpeedDialIcon />}
      onBlur={handleClose}
      onClick={handleClick}
      onClose={handleClose}
      onFocus={isTouch ? undefined : handleOpen}
      onMouseEnter={isTouch ? undefined : handleOpen}
      onMouseLeave={handleClose}
      open={isOpen}
    >
      {units.map((unit) => (
        <SpeedDialAction
          key={unit.id}
          className={classes.speedDialAction}
          icon={<Typography variant="subtitle1">{unit.title}</Typography>}
          tooltipTitle={unit.title}
          onClick={() => handleUnitClick(unit)}
        />
      ))}
    </SpeedDial>
  );
};
