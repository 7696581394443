import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  EmailArticle,
  EmailArticleVariables,
} from '../../__generated__/queries-topic';

const EMAIL_ARTICLE_MUTATION = gql`
  mutation EmailArticle(
    $rawArticle: ID!
    $recipients: [String!]!
    $comment: String
  ) {
    emailRawArticle(
      rawArticle: { id: $rawArticle }
      recipients: $recipients
      comment: $comment
    ) {
      success
    }
  }
`;

export const useEmailArticleSender = (
  options?: MutationHookOptions<EmailArticle, EmailArticleVariables>,
) =>
  useMutation<EmailArticle, EmailArticleVariables>(
    EMAIL_ARTICLE_MUTATION,
    options,
  );
