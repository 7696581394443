import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { SubjectListPage_GetPrintPublicationsByUnit_units_brands_printPublications } from '../../../../__generated__/queries-topic';
import { useGetPrintIssuesByPrintPublication } from '../../../../apollo/queries/getPrintIssuesByPrintPublication.topic.graphql';
import {
  CircularLoader,
  LoaderSizes,
} from '../../../../components/Loader/Circular';
import { sortPrintIssues } from '../../../../utils/sortPrintIssues';
import { useGetPrintPublicationsByUnits } from './getPrintPublicationsByUnit.topic.graphql';
import { useStyles } from './styles';

interface IssueSelectorProps {
  selectedPrintPublication: string;
  selectedIssue: string;
  addFilter: (...args: any[]) => any;
  unitId?: string;
}

export const IssueSelector: React.FC<IssueSelectorProps> = ({
  selectedPrintPublication,
  selectedIssue,
  addFilter,
  unitId,
}) => {
  const classes = useStyles();
  const {
    data: printPublicationData,
    loading: printPublicationLoading,
  } = useGetPrintPublicationsByUnits();
  const { data: issuesData } = useGetPrintIssuesByPrintPublication({
    id: selectedPrintPublication,
  });

  if (printPublicationLoading) {
    return <CircularLoader size={LoaderSizes.small} />;
  }
  if (!printPublicationData) {
    return <Typography>Error</Typography>;
  }
  const units = unitId
    ? printPublicationData.units.filter((unit) => unit.id === unitId)
    : printPublicationData.units || [];
  const printPublications = units.reduce(
    (acc, unit) =>
      acc.concat(
        unit.brands.reduce(
          (acc2, brand) => acc2.concat(brand.printPublications),
          [] as SubjectListPage_GetPrintPublicationsByUnit_units_brands_printPublications[],
        ),
      ),
    [] as SubjectListPage_GetPrintPublicationsByUnit_units_brands_printPublications[],
  );
  const printIssues = sortPrintIssues(
    issuesData?.printPublication?.printIssues || [],
  );

  return (
    <>
      <Typography className={classes.subheading} variant="subtitle1">
        En consultation :
      </Typography>
      <FormControl className={classes.formControl} variant="standard">
        <Select
          className={classes.selectRoot}
          disableUnderline
          value={
            printPublications.some(({ id }) => id === selectedPrintPublication)
              ? selectedPrintPublication
              : ''
          }
          displayEmpty
          onChange={(event) => {
            addFilter({
              printPublication: event.target.value,
              issue: '',
            });
          }}
          inputProps={{
            name: 'support',
            id: 'support',
          }}
          variant="standard"
        >
          <MenuItem key="no-choice" value="">
            <em>Support</em>
          </MenuItem>
          {printPublications.map(({ id, title }) => (
            <MenuItem key={id} value={id}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} variant="standard">
        <Select
          className={classes.selectRoot}
          disableUnderline
          disabled={!selectedPrintPublication}
          value={
            printIssues.some(({ id }) => id === selectedIssue)
              ? selectedIssue
              : ''
          }
          displayEmpty
          onChange={(event) => addFilter({ printIssue: event.target.value })}
          inputProps={{
            name: 'issue',
            id: 'issue',
          }}
          variant="standard"
        >
          <MenuItem key="no-choice" value="">
            <em>Parution</em>
          </MenuItem>
          {printIssues.map(({ id, title }) => (
            <MenuItem key={id} value={id}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
