import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {
  LoadingButton,
  NotificationTypeEnum,
  pluralize,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import { useDeletePhoto } from 'apollo/mutations/photos.photo.graphql';
import React, { FC, useState } from 'react';
import { SearchPhotos_searchPhotos } from '__generated__/queries-photo';

interface DeleteDialogProps {
  photos: SearchPhotos_searchPhotos[];
  setSelectedPhotoIds: (selectedIds: string[]) => void;
}

export const DeleteDialog: FC<DeleteDialogProps> = ({
  photos,
  setSelectedPhotoIds,
}) => {
  const { closeDialog } = useDialog();
  const [loading, setLoading] = useState(false);
  const deletePhoto = useDeletePhoto();
  const { pushNotification } = useNotification();

  const deletePhotos = async () => {
    setLoading(true);
    try {
      await Promise.all(photos.map((photo) => deletePhoto(photo.id)));
      closeDialog();
      setSelectedPhotoIds([]);
    } catch (e: any) {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <DialogTitle>Supprimer</DialogTitle>

      <DialogContent>
        <Typography variant="body1">
          Souhaitez-vous supprimer{' '}
          {photos.length > 1 ? `ces ${photos.length}` : 'cette'}{' '}
          {pluralize('photo', photos.length)}?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Annuler
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={loading}
          onClick={deletePhotos}
        >
          Supprimer
        </LoadingButton>
      </DialogActions>
    </>
  );
};
