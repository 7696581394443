import { gql, useQuery } from '@apollo/client';
import {
  GetPrintPublicationByUnit,
  GetPrintPublicationByUnitVariables,
} from '../../__generated__/queries-topic';

const GET_PRINT_PUBLICATION_BY_UNIT = gql`
  query GetPrintPublicationByUnit($unitId: ID!) {
    units(where: { id: $unitId }, first: 20) {
      id
      title
      brands(first: 20) {
        id
        title
        printPublications(first: 20) {
          id
          title
        }
        websites(first: 20) {
          id
          title
          key
        }
      }
    }
  }
`;

export const useGetPrintPublicationByUnit = (
  variables: GetPrintPublicationByUnitVariables,
) =>
  useQuery<GetPrintPublicationByUnit, GetPrintPublicationByUnitVariables>(
    GET_PRINT_PUBLICATION_BY_UNIT,
    { variables },
  );
