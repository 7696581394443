import { gql, useQuery } from '@apollo/client';
import {
  GetUnfilteredSubjectListByIssue,
  GetUnfilteredSubjectListByIssueVariables,
} from '../../../__generated__/queries-topic';
import { SUBJECT_FORM_FLAT_ASSIGNMENT_FRAGMENT } from '../../SubjectForm/getSubjectQuery.topic.graphql';

const GET_UNFILTERED_SUBJECT_LIST_BY_ISSUE = gql`
  query GetUnfilteredSubjectListByIssue($id: ID!) {
    printIssue(where: { id: $id }) {
      id
      title
      printHeadings(first: 250, orderBy: order_ASC) {
        id
        title
        autoPrototype
        printTemplates(first: 1, where: { active: true }) {
          printTemplate {
            type
          }
        }
        assignments(first: 200) {
          id
          printTemplate {
            id
          }
          mediaSearchStatus
          pageNumber
          subject {
            id
            title
            comment
            subjectCategory {
              id
              title
              parent {
                id
                title
              }
            }
            isClassified
            assignments: flatAssignments(first: 20) {
              ...SubjectForm_FlatAssignment
            }
          }
          website {
            id
            title
            key
          }
          rawArticle {
            id
            versions(first: 20, where: { nextVersion_is_null: true }) {
              id
              status
              title
              createdAt
              editor {
                id
                name
              }
            }
          }
          articleTemplate {
            id
          }
        }
      }
    }
  }
  ${SUBJECT_FORM_FLAT_ASSIGNMENT_FRAGMENT}
`;

export const useGetFilteredSubjectListByIssue = (
  variables: GetUnfilteredSubjectListByIssueVariables,
) =>
  useQuery<
    GetUnfilteredSubjectListByIssue,
    GetUnfilteredSubjectListByIssueVariables
  >(GET_UNFILTERED_SUBJECT_LIST_BY_ISSUE, {
    variables,
    fetchPolicy: 'network-only',
    skip: variables.id.length < 1,
  });
