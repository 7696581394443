import { gql, useQuery } from '@apollo/client';
import { GetUnits } from '../../__generated__/queries-topic';

const GET_UNITS = gql`
  query GetUnits {
    units(first: 100) {
      id
      title
    }
  }
`;

export const useGetUnits = () => useQuery<GetUnits>(GET_UNITS);
