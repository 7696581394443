import { gql, useQuery } from '@apollo/client';
import {
  LocationPage_GetPrintPublicationsByUnit,
  LocationPage_GetPrintPublicationsByUnitVariables,
} from '../../../__generated__/queries-topic';
import { PRINT_PUBLICATION_FRAGMENT } from './printPublicationFragment.topic.graphql';

export const GET_PRINT_PUBLICATIONS_BY_UNIT = gql`
  query LocationPage_GetPrintPublicationsByUnit($unitId: ID!) {
    unit(where: { id: $unitId }) {
      id
      title
      brands(first: 20) {
        id
        title
        printPublications(first: 20) {
          ...PrintPublicationFragment
        }
      }
    }
  }
  ${PRINT_PUBLICATION_FRAGMENT}
`;

export const useGetPrintPublicationsByUnit = (
  variables: LocationPage_GetPrintPublicationsByUnitVariables,
) =>
  useQuery<
    LocationPage_GetPrintPublicationsByUnit,
    LocationPage_GetPrintPublicationsByUnitVariables
  >(GET_PRINT_PUBLICATIONS_BY_UNIT, { variables, skip: !variables.unitId });
