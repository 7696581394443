import { gql, useQuery } from '@apollo/client';
import {
  GetSubjectForMediaStatusDialog,
  GetSubjectForMediaStatusDialogVariables,
} from '../../__generated__/queries-topic';
import { ASSIGNMENT_FRAGMENT } from '../ListItem/AssignmentItem/fragment.topic.graphql';

const GET_SUBJECT_FOR_MEDIA_STATUS_DIALOG = gql`
  query GetSubjectForMediaStatusDialog($id: ID!) {
    subject(where: { id: $id }) {
      id
      assignments(first: 20) {
        ...AssignmentItem
      }
    }
  }
  ${ASSIGNMENT_FRAGMENT}
`;

export const useGetSubjectForMediaStatusDialog = (
  variables: GetSubjectForMediaStatusDialogVariables,
) =>
  useQuery<
    GetSubjectForMediaStatusDialog,
    GetSubjectForMediaStatusDialogVariables
  >(GET_SUBJECT_FOR_MEDIA_STATUS_DIALOG, { variables });
