import { ApolloQueryResult } from '@apollo/client';
import { Lock, MoreVert } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { useDialog, useMenu } from '@prismamedia/one-components';
import { FC } from 'react';
import { paths } from '../../../routing';
import { gaSendEvent } from '../../../utils/googleAnalytics';
import {
  GetSubjects,
  GetSubjectsVariables,
  GetSubjects_subjects,
  RawArticleStatus,
} from '../../../__generated__/queries-topic';
import { CountAvatar } from '../../CountAvatar';
import { EmailSubjectDialog } from '../../EmailDialog/EmailSubjectDialog';
import { MediaLink } from '../../Link/MediaLink';
import { TopicLink } from '../../Link/TopicLink';
import { ShareSubjectDialog } from '../../ShareDialog/ShareSubjectDialog';
import { SubjectDeleteDialog } from '../../SubjectDeleteDialog';
import { AssignmentItem } from '../AssignmentItem';
import { useStyles } from './styles';

interface SubjectItemProps {
  subject: GetSubjects_subjects;
  textStatusIds: RawArticleStatus[];
  refetch?: (
    variables?: GetSubjectsVariables,
  ) => Promise<ApolloQueryResult<GetSubjects>>;
  isUserAdmin?: boolean;
}

export const SubjectItem: FC<SubjectItemProps> = ({
  subject,
  textStatusIds,
  refetch,
  isUserAdmin,
}) => {
  const classes = useStyles();
  const { openMenu, closeMenu } = useMenu();
  const filteredAssignments = subject.assignments.filter(
    (assignment) =>
      !textStatusIds.length ||
      (assignment.rawArticle &&
        textStatusIds.includes(assignment.rawArticle.versions[0].status)),
  );
  const { openDialog, closeDialog } = useDialog();

  return (
    <Paper classes={{ root: classes.paper }} elevation={0} square={true}>
      <Grid container={true} spacing={1} alignItems="center">
        <Grid item={true} className={classes.articleTitleAndCounter}>
          <CountAvatar count={subject.assignmentCount} />
          <TopicLink
            to={paths.SUBJECT_EDIT}
            params={{ id: subject.id }}
            linkClass={classes.articleLink}
          >
            <Typography
              data-cy="subject-item"
              variant="h6"
              className={classes.articleTitle}
            >
              {subject.title}
            </Typography>
          </TopicLink>
          {subject.isClassified && <Lock />}
        </Grid>
        <Grid item={true} className={classes.articleCategory}>
          {subject.subjectCategory && (
            <>
              <Typography variant="body1">
                {`${
                  subject.subjectCategory.parent &&
                  subject.subjectCategory.parent.title
                }
                 • ${subject.subjectCategory.title}`}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item={true}>
          <MediaLink
            subjectId={subject.id}
            className={classes.alignMediaLink}
            onClick={() => gaSendEvent('List subject', 'click', 'photo access')}
          />
        </Grid>
        <Grid item={true}>
          <IconButton
            onClick={(e) =>
              openMenu(
                e.currentTarget,
                <div>
                  <MenuItem>
                    <TopicLink
                      to={paths.SUBJECT_EDIT}
                      params={{ id: subject.id }}
                      linkClass={classes.menuItemLink}
                      onClick={() => closeMenu()}
                    >
                      Editer le sujet
                    </TopicLink>
                  </MenuItem>
                  <MenuItem>
                    <TopicLink
                      to={paths.SUBJECT_ASSETS}
                      params={{ id: subject.id }}
                      linkClass={classes.menuItemLink}
                      onClick={() => closeMenu()}
                    >
                      Dépot d'images
                    </TopicLink>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      openDialog(
                        <EmailSubjectDialog
                          subjectId={subject.id}
                          closeDialog={closeDialog}
                        />,
                      );
                      closeMenu();
                    }}
                  >
                    Envoyer le lien par email
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      openDialog(
                        <ShareSubjectDialog
                          subjectId={subject.id}
                          closeDialog={closeDialog}
                        />,
                      );
                      closeMenu();
                    }}
                  >
                    Partager le sujet
                  </MenuItem>
                  {isUserAdmin && (
                    <MenuItem
                      disabled={subject.assignmentCount > 0}
                      onClick={() => {
                        openDialog(
                          <SubjectDeleteDialog
                            subject={subject}
                            refetchSubject={refetch}
                            closeDialog={closeDialog}
                          />,
                        );
                        closeMenu();
                      }}
                    >
                      Supprimer le sujet
                    </MenuItem>
                  )}
                </div>,
              )
            }
          >
            <MoreVert />
          </IconButton>
        </Grid>
      </Grid>
      <div className={classes.articleAssignments}>
        {filteredAssignments.length > 0 ? (
          <List disablePadding>
            {filteredAssignments.map((assignment) => (
              <AssignmentItem
                key={assignment.id}
                assignment={assignment}
                withLink
              />
            ))}
          </List>
        ) : (
          <List disablePadding>
            <ListItem className={classes.noAssignmentListItem}>
              <Typography className={classes.noAssignmentText}>
                En attente d'affectation
              </Typography>
            </ListItem>
          </List>
        )}
      </div>
    </Paper>
  );
};
