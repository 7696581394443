import { gql, useQuery } from '@apollo/client';
import {
  GetPrintSubject,
  GetPrintSubjectVariables,
} from '../../../__generated__/queries-topic';

const PRINT_SUBJECT_FLAT_ASSIGNMENT = gql`
  fragment PrintSubject_FlatAssignment on FlatAssignment {
    assignment {
      id
      mediaSearchStatus
    }
    printPublication {
      id
      title
    }
    printIssue {
      id
      title
    }
    printHeading {
      id
      title
    }
    website {
      id
      key
      title
    }
  }
`;

const GET_PRINT_SUBJECT = gql`
  query GetPrintSubject($id: ID!) {
    subject(where: { id: $id }) {
      title
      synopsis
      comment
      mediaRequest
      subjectCategory {
        id
        title
        parent {
          title
        }
      }
      assignments: flatAssignments(first: 20) {
        ...PrintSubject_FlatAssignment
      }
    }
  }
  ${PRINT_SUBJECT_FLAT_ASSIGNMENT}
`;

export const useGetPrintSubject = (variables: GetPrintSubjectVariables) =>
  useQuery<GetPrintSubject, GetPrintSubjectVariables>(GET_PRINT_SUBJECT, {
    variables,
    skip: variables.id.length < 1,
  });
