import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import React, { useState } from 'react';
import {
  CircularLoader,
  LoaderSizes,
} from '../../../../components/Loader/Circular';
import { LocationPage_GetPrintHeadingByPrintIssue_printHeadings } from '../../../../__generated__/queries-topic';
import { useUpdatePrintHeadingAutoProto } from './updatePrintHeading.topic.graphql';

interface AutoPrototypePrintTemplateDialogProps {
  open?: boolean;
  printHeading: LocationPage_GetPrintHeadingByPrintIssue_printHeadings;
  handleClose: () => void;
}

export const AutoPrototypePrintTemplateDialog: React.FC<AutoPrototypePrintTemplateDialogProps> = ({
  open = false,
  printHeading,
  handleClose,
}) => {
  // const commonClasses = useCommonStyles();
  const { pushNotification } = useNotification();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const updateAutoPrototype = useUpdatePrintHeadingAutoProto(printHeading.id);

  const handleUpdatePrintHeading = async () => {
    setIsSubmiting(true);
    try {
      const { errors } = await updateAutoPrototype(!printHeading.autoPrototype);

      if (errors) {
        throw Error(errors[0].message);
      }

      pushNotification({
        message: `Le pré-maquette est activé avec succés pour la tétiére ${printHeading.title}`,
        type: NotificationTypeEnum.success,
      });
      handleClose();
    } catch (err) {
      pushNotification({
        message: `Un problème est survenu lors de l'activation pré-maquette pour la tétiére ${printHeading.title}`,
        type: NotificationTypeEnum.error,
      });
    }
    setIsSubmiting(false);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-edit-printTemplate"
      maxWidth="md"
    >
      <DialogTitle id="dialog-edit-printTemplate">
        Pré-maquette tétiére {printHeading.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText hidden={!!printHeading.printTemplateCount}>
          Au moins un gabarit associé est requis pour pouvoir activer la
          fonctionnalité pré-maquette.
          <br /> Pour pouvoir créer un gabarit, cliquer sur le menu contextuel
          d'une tétiere, et appuyer sur <i>Gérer les gabarits</i>
        </DialogContentText>
        <DialogContentText hidden={!printHeading.printTemplateCount}>
          Voulez-vous {printHeading.autoPrototype ? 'désactiver' : 'activer'} la
          fonctionnalité pré-maquette pour la tétiére{' '}
          <b>{printHeading.title}</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button
          onClick={handleUpdatePrintHeading}
          color="primary"
          autoFocus
          disabled={!(printHeading.printTemplateCount && !isSubmiting)}
        >
          {isSubmiting && <CircularLoader size={LoaderSizes.xsmall} />}
          {printHeading.autoPrototype ? 'Désactiver' : 'Activer'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
