import { useQuery, gql } from '@apollo/client';
import { CATEGORY_FRAGMENT } from 'apollo/fragments/category.photo.graphql';
import { useMemo } from 'react';
import {
  GetCategories,
  GetCategoriesVariables,
} from '__generated__/queries-photo';

const CATEGORY_IDS_TO_IGNORE = ['6eff2655-1fe3-46bb-8c47-30815b3dab3f']; // PEOPLE_VOI

export const GET_CATEGORIES = gql`
  query GetCategories(
    $where: CategoryWhereInput
    $first: Int!
    $orderBy: [CategoryOrderByInput!]
    $skip: Int
  ) {
    categories(where: $where, first: $first, orderBy: $orderBy, skip: $skip) {
      ...Category
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const useGetCategories = (
  variables: GetCategoriesVariables,
  skip?: boolean,
) => {
  const queryResult = useQuery<GetCategories, GetCategoriesVariables>(
    GET_CATEGORIES,
    {
      variables,
      skip,
      fetchPolicy: 'cache-and-network',
    },
  );

  return useMemo(
    () => ({
      ...queryResult,
      data: queryResult.data
        ? {
            ...queryResult.data,
            categories: queryResult.data.categories
              .filter(({ id }) => !CATEGORY_IDS_TO_IGNORE.includes(id))
              .map((cat) => {
                if (
                  cat.parent?.id &&
                  CATEGORY_IDS_TO_IGNORE.includes(cat.parent.id)
                ) {
                  return { ...cat, parent: null };
                }
                return cat;
              }),
          }
        : queryResult.data,
    }),
    [queryResult],
  );
};
