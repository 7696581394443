import { gql, useQuery } from '@apollo/client';
import {
  GetUserBySearch,
  GetUserBySearchVariables,
} from '../../../__generated__/queries-topic';

const GET_USER_BY_SEARCH = gql`
  query GetUserBySearch($id: ID!, $search: String!) {
    rawArticleVersion(where: { id: $id }) {
      id
      allowedTransfers(name: $search) {
        id
        name
      }
    }
  }
`;

export const useGetUserBySearch = (variables: GetUserBySearchVariables) =>
  useQuery<GetUserBySearch, GetUserBySearchVariables>(GET_USER_BY_SEARCH, {
    variables,
  });
