import { gql, useApolloClient, useMutation } from '@apollo/client';
import { CATEGORY_FRAGMENT } from 'apollo/fragments/category.photo.graphql';
import {
  CreateCategory,
  CreateCategoryVariables,
} from '__generated__/queries-photo';

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($data: CategoryCreateInput!) {
    createCategory(data: $data) {
      ...Category
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const useCreateCategory = () => {
  const [createCategory] = useMutation<CreateCategory, CreateCategoryVariables>(
    CREATE_CATEGORY,
  );

  return (variables: CreateCategoryVariables) => createCategory({ variables });
};

export const useUpdateCategoryCache = () => {
  const client = useApolloClient();

  return () =>
    client.refetchQueries({
      updateCache: (cache) =>
        cache.modify({
          fields: {
            categories: (prev, { DELETE }) => DELETE,
          },
        }),
    });
};
