import { gql, useMutation } from '@apollo/client';
import {
  ExportPrintIssueToCenshare,
  ExportPrintIssueToCenshareVariables,
  ExportToCentshare,
  ExportToCentshareVariables,
} from '__generated__/queries-topic';

const EXPORT_TO_CENTSHARE = gql`
  mutation ExportToCentshare(
    $assignment: String!
    $issue: String!
    $subject: String!
    $brand: String!
    $photos: [String!]
  ) {
    exportToCentshare(
      assignment: $assignment
      issue: $issue
      subject: $subject
      brand: $brand
      photos: $photos
    ) {
      message
    }
  }
`;

export const useExportToCentshare = () => {
  const [exportToCentshare] = useMutation<
    ExportToCentshare,
    ExportToCentshareVariables
  >(EXPORT_TO_CENTSHARE);

  return (variables: ExportToCentshareVariables) =>
    exportToCentshare({ variables });
};

const EXPORT_PRINT_ISSUE_TO_CENTSHARE = gql`
  mutation ExportPrintIssueToCenshare($printIssueId: String!) {
    exportPrintIssueToCenshare(printIssueId: $printIssueId) {
      message
    }
  }
`;

export const useExportPrintIssueToCenshare = () => {
  const [exportPrintIssueToCenshare] = useMutation<
    ExportPrintIssueToCenshare,
    ExportPrintIssueToCenshareVariables
  >(EXPORT_PRINT_ISSUE_TO_CENTSHARE);

  return (variables: ExportPrintIssueToCenshareVariables) =>
    exportPrintIssueToCenshare({ variables });
};
