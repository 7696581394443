import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { GetSubject_subject_attachments } from '../../../../__generated__/queries-topic';
import { AttachmentIcon } from '../../../../components/AttachmentIcon';
import { useAttachmentDeleter } from './deleteAttachment.topic.graphql';
import { useStyles } from './styles';

interface DeleteAttachmentDialogProps {
  open: boolean;
  attachment?: GetSubject_subject_attachments;
  handleCloseDialog: () => void;
  removeAttachment: (attachment: GetSubject_subject_attachments) => void;
}

export const DeleteAttachmentDialog: React.FC<DeleteAttachmentDialogProps> = ({
  handleCloseDialog,
  attachment,
  open,
  removeAttachment,
}) => {
  const classes = useStyles();
  const [deleteAttachment] = useAttachmentDeleter();
  if (!attachment) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        <strong>Êtes-vous sûr de vouloir supprimer cette piece jointe ?</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.content}>
          <AttachmentIcon contentType={attachment.contentType} />
          {attachment.filename}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttonsActions}>
        <Button onClick={handleCloseDialog}>Annuler</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={async () => {
            await deleteAttachment({
              variables: { id: attachment.id },
            });
            removeAttachment(attachment);
            handleCloseDialog();
          }}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
