import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  LocationPage_DeletePrintHeading,
  LocationPage_DeletePrintHeadingVariables,
} from '../../../../__generated__/queries-topic';

const DELETE_PRINT_HEADING = gql`
  mutation LocationPage_DeletePrintHeading($id: ID!) {
    deletePrintHeading(where: { id: $id }) {
      id
    }
  }
`;

export const usePrintHeadingDeleter = (
  options?: MutationHookOptions<
    LocationPage_DeletePrintHeading,
    LocationPage_DeletePrintHeadingVariables
  >,
) =>
  useMutation<
    LocationPage_DeletePrintHeading,
    LocationPage_DeletePrintHeadingVariables
  >(DELETE_PRINT_HEADING, options);
