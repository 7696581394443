import { CustomReactImageGalleryItem } from '../OneFullscreenGallery';
import { SearchPhotos_searchPhotos } from '__generated__/queries-photo';
import { getPreview, PreviewSize } from 'utils/getPreview';

export const getImagesForGallery = (
  photos: SearchPhotos_searchPhotos[],
): CustomReactImageGalleryItem[] =>
  photos.map((photo, i) => ({
    original: getPreview(photo, PreviewSize.XL) || '',
    thumbnail: getPreview(photo) || '',
    index: i,
    fallbacks: [{ src: photo.downloadUrl }],
  }));
