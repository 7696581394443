import { gql, useMutation } from '@apollo/client';
import { DeleteSubject } from '../../__generated__/queries-topic';

const DELETE_SUBJECT = gql`
  mutation DeleteSubject($id: ID!) {
    deleteSubject(where: { id: $id }) {
      id
    }
  }
`;

export const useSubjectDeleter = () =>
  useMutation<DeleteSubject>(DELETE_SUBJECT);
