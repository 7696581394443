import { gql, useApolloClient, QueryOptions } from '@apollo/client';
import { useCallback } from 'react';
import {
  GetPrintTemplateDraft,
  GetPrintTemplateDraftVariables,
} from '../../__generated__/queries-topic';

const GET_PRINT_TEMPLATES_DRAFT_QUERY = gql`
  query GetPrintTemplateDraft(
    $printHeadingId: String!
    $printTemplateId: String
    $format: PrintTemplateDraftFormatType!
    $pages: [Int!]
    $fieldsData: [PrintTemplateDraftFieldsDataType]
    $embedRessources: Boolean
  ) {
    printTemplateDrafts(
      printHeadingId: $printHeadingId
      printTemplateId: $printTemplateId
      format: $format
      pages: $pages
      fieldsData: $fieldsData
      embedRessources: $embedRessources
    ) {
      draftId
      artifacts {
        page
        url
      }
    }
  }
`;

export const useGetTemplateDraft = () => {
  const client = useApolloClient();
  return useCallback(
    (
      {
        printHeadingId,
        printTemplateId,
        format,
        pages,
        fieldsData,
        embedRessources = false,
      }: GetPrintTemplateDraftVariables,
      options?: Omit<QueryOptions, 'query' | 'variables'>,
    ) => {
      return client.query<
        GetPrintTemplateDraft,
        GetPrintTemplateDraftVariables
      >({
        query: GET_PRINT_TEMPLATES_DRAFT_QUERY,
        variables: {
          printHeadingId,
          printTemplateId,
          format,
          pages,
          fieldsData,
          embedRessources,
        },
        ...options,
      });
    },
    [client],
  );
};
