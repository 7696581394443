import { gql, useQuery } from '@apollo/client';
import {
  LocationPage_GetPrintIssuesByPrintPublication,
  LocationPage_GetPrintIssuesByPrintPublicationVariables,
} from '../../../__generated__/queries-topic';

export const GET_PRINT_ISSUES_BY_PRINT_PUBLICATION = gql`
  query LocationPage_GetPrintIssuesByPrintPublication(
    $printPublicationId: ID!
  ) {
    printIssues(
      first: 1000
      where: { printPublication: { id: $printPublicationId } }
      orderBy: createdAt_DESC
    ) {
      id
      title
    }
    printPublication(where: { id: $printPublicationId }) {
      id
      title
    }
  }
`;

export const useGetPrintIssuesByPrintPublication = (
  variables: LocationPage_GetPrintIssuesByPrintPublicationVariables,
) =>
  useQuery<
    LocationPage_GetPrintIssuesByPrintPublication,
    LocationPage_GetPrintIssuesByPrintPublicationVariables
  >(GET_PRINT_ISSUES_BY_PRINT_PUBLICATION, {
    variables,
    skip: !variables.printPublicationId,
  });
