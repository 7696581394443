import { gql, useQuery } from '@apollo/client';
import {
  GetAssignmentByRawArticleId,
  GetAssignmentByRawArticleIdVariables,
} from '../../../__generated__/queries-topic';

const GET_ASSIGNMENT_BY_RAW_ARTICLE_ID = gql`
  query GetAssignmentByRawArticleId($id: ID!) {
    assignment(where: { rawArticle: { id: $id } }) {
      id
      printPublication {
        id
        title
      }
      printIssue {
        id
        title
        printPublication {
          id
          title
        }
      }
      printHeading {
        id
        title
        printIssue {
          id
          title
          printPublication {
            id
            title
          }
        }
      }
    }
  }
`;

export const useGetAssignmentByRawArticleId = (
  variables: GetAssignmentByRawArticleIdVariables,
) =>
  useQuery<GetAssignmentByRawArticleId, GetAssignmentByRawArticleIdVariables>(
    GET_ASSIGNMENT_BY_RAW_ARTICLE_ID,
    { variables },
  );
