import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  LocationPage_UpdatePrintHeadingOrder,
  LocationPage_UpdatePrintHeadingOrderVariables,
} from '../../../__generated__/queries-topic';
import { PRINT_ISSUE_FRAGMENT } from '../PrintIssue/printIssueFragment.topic.graphql';

const UPDATE_PRINT_HEADING_ORDER = gql`
  mutation LocationPage_UpdatePrintHeadingOrder(
    $id: ID!
    $data: PrintIssueUpdateInput!
  ) {
    updatePrintIssue(data: $data, where: { id: $id }) {
      ...PrintIssueFragment
    }
  }
  ${PRINT_ISSUE_FRAGMENT}
`;

export const usePrintHeadingOrderUpdater = (
  options?: MutationHookOptions<
    LocationPage_UpdatePrintHeadingOrder,
    LocationPage_UpdatePrintHeadingOrderVariables
  >,
) =>
  useMutation<
    LocationPage_UpdatePrintHeadingOrder,
    LocationPage_UpdatePrintHeadingOrderVariables
  >(UPDATE_PRINT_HEADING_ORDER, options);
