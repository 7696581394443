import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import classNames from 'classnames';
import React, { FC } from 'react';
import { GetRawArticleMajorVersions_rawArticle_majorVersions } from '../../../../__generated__/queries-topic';
import { AssignmentFormModel } from '../../Form/dataTransformer';
import { duplicateArticle } from '../createRawArticleVersionMutation.topic.graphql';
import { useStyles } from './styles';
import { useAssignmentUpdate } from 'apollo/mutations/upsertAssignment.topic.graphql';

interface DuplicateAssignmentListProps {
  subjectId: string;
  assignmentOriginIndex: number;
  assignments: AssignmentFormModel[];
  selectedAssignment: AssignmentFormModel;
  refetchSubject?: () => void;
  closeDialog: (e: React.SyntheticEvent) => void;
  handleNewAssignment: (e: React.SyntheticEvent) => void;
  versionSelected: GetRawArticleMajorVersions_rawArticle_majorVersions | null;
  onAssignmentClick: (
    assignment: AssignmentFormModel,
  ) => (e: React.SyntheticEvent) => void;
  notificateSuccess: () => void;
}

export const DuplicateAssignmentList: FC<DuplicateAssignmentListProps> = ({
  assignments,
  selectedAssignment,
  refetchSubject,
  closeDialog,
  handleNewAssignment,
  subjectId,
  versionSelected,
  onAssignmentClick,
  notificateSuccess,
  assignmentOriginIndex,
}) => {
  const classes = useStyles();

  const [updateAssignment] = useAssignmentUpdate({
    onCompleted: async () => {
      if (refetchSubject) {
        await refetchSubject();
        notificateSuccess();
      }
    },
  });

  // assignment that's ready to be duplicate into
  const emptyAssignments = assignments.filter((assignment) => {
    // remove those with an article or a web one
    return assignment.rawArticle
      ? assignment.rawArticle.id === null && assignment.websiteTitle === ''
      : true;
  });

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        Sur quelle parution souhaitez-vous dupliquer votre article ?
      </DialogTitle>
      <DialogContent>
        {emptyAssignments.length ? (
          emptyAssignments.map((assignment, index) => (
            <div
              className={classNames(classes.assignment, {
                selected: assignment.id === selectedAssignment.id,
              })}
              key={index}
              onClick={onAssignmentClick(assignment)}
            >
              <Typography variant="body1">
                {assignment.supportId && assignment.supportKey
                  ? `WEB - ${assignment.websiteTitle}`
                  : `${assignment.supportTitle} • 
                  ${assignment.printIssueTitle} • 
                  ${assignment.printHeadingTitle}`}
              </Typography>
            </div>
          ))
        ) : (
          <Typography variant="body1">Aucune affectation disponible</Typography>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <div className={classes.addSupport} onClick={handleNewAssignment}>
          <Add />
          <Typography color="inherit" variant="subtitle1">
            Ajouter un autre support
          </Typography>
        </div>
        <div>
          <Button onClick={closeDialog} key="cancel">
            Annuler
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={!selectedAssignment.id}
            onClick={() => {
              if (versionSelected !== null) {
                const payload = duplicateArticle(
                  subjectId,
                  versionSelected,
                  selectedAssignment,
                  assignments[assignmentOriginIndex],
                  true,
                );
                updateAssignment({
                  variables: {
                    data: {
                      mediaSearchStatus: selectedAssignment.mediaSearchStatus,
                      printTemplate:
                        selectedAssignment.autoPrototype &&
                        !selectedAssignment.printTemplateId
                          ? {
                              connect: {
                                id:
                                  selectedAssignment.printHeading
                                    ?.printTemplates?.[0]?.printTemplate.id,
                              },
                            }
                          : undefined,
                      rawArticle: {
                        create: payload.data,
                      },
                    },
                    where: { id: selectedAssignment.id },
                  },
                });
              }
            }}
          >
            CONFIRMER
          </Button>
        </div>
      </DialogActions>
    </>
  );
};
