import {
  FetchResult,
  gql,
  Reference,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import {
  Task,
  TaskEventName,
  TaskOptions,
  TaskStatus,
} from '@prismamedia/one-components';
import {
  ArchiveWhereUniqueInput,
  CategoryWhereUniqueInput,
  ClonePhoto,
  ClonePhotoVariables,
  CreatePhoto,
  CreatePhotoVariables,
  DeletePhoto,
  DeletePhotoVariables,
  MetadataNameEnumType,
  PhotoUpdateInput,
  SearchPhotos_searchPhotos,
  UpdatePhoto,
  UpdatePhotoFile,
  UpdatePhotoFileVariables,
  UpdatePhotoVariables,
} from '__generated__/queries-photo';
import { PHOTO_FRAGMENT } from 'apollo/fragments/photo.photo.graphql';
import { GET_UNDER_SUBJECTS } from 'apollo/queries/photoMetadatas.photo.graphql';
import {
  GET_PHOTOS_COUNT,
  SEARCH_PHOTOS,
} from 'apollo/queries/photos.photo.graphql';
import { ImagesTaskQueue } from 'queues';
import { MetadataAsArray } from 'utils/getMetadata';

export interface UpsertPhotoMetadataValue {
  metadata: MetadataNameEnumType;
  value?: string;
}

const buildPhotoMetadatasUpdate = ({
  photo,
  metadatas = [],
}: {
  photo: Pick<SearchPhotos_searchPhotos, 'metadataByName' | 'archive'>;
  metadatas?: UpsertPhotoMetadataValue[];
}): PhotoUpdateInput => {
  const operations: Record<
    string,
    {
      create: UpsertPhotoMetadataValue[];
      delete: (UpsertPhotoMetadataValue & { id: number })[];
    }
  > = metadatas.reduce(
    (mds, md) => ({ ...mds, [md.metadata]: { create: [], delete: [] } }),
    {},
  );

  // delete old values
  metadatas.forEach((m) => {
    photo.metadataByName?.[m.metadata]?.forEach((v: any) => {
      // eslint-disable-next-line fp/no-mutating-methods
      operations[m.metadata].delete.push({
        id: v.id,
        metadata: m.metadata,
      });
    });
  });

  // add new values
  metadatas.forEach((md) => {
    if (md.value) {
      // eslint-disable-next-line fp/no-mutating-methods
      operations[md.metadata].create.push({
        metadata: md.metadata,
        value: md.value,
      });
    }
  });

  return Object.entries(operations).reduce(
    (mds, [md, op]) => ({
      ...mds,
      [`Metadata_${md}`]: {
        create: op.create.map(({ metadata, value }) => ({
          metadata: { connect: { name: metadata } },
          archive: { connect: { id: photo.archive.id } },
          value,
        })),
        delete: op.delete.map((c) => ({ id: c.id })),
      },
    }),
    {},
  );
};

export const CREATE_PHOTO = gql`
  mutation CreatePhoto($data: PhotoCreateInput!) {
    createPhoto(data: $data) {
      ...Photo
      uploadUrl
    }
  }
  ${PHOTO_FRAGMENT}
`;

// default photos tasks retry options
const taskRetryOptions: TaskOptions['retryOptions'] = {
  maxAttempts: 3,
  maxBackoff: 20000, // 20s
  multiplier: 2,
  minBackoff: 5000, // 5s
};

export const useCreatePhoto = () => {
  const [createPhoto] = useMutation<CreatePhoto, CreatePhotoVariables>(
    CREATE_PHOTO,
    {},
  );
  const [updatePhoto] = useMutation<UpdatePhoto, UpdatePhotoVariables>(
    UPDATE_PHOTO,
  );

  return async ({
    file,
    archiveConnect,
    category,
    metadatas = [],
  }: {
    file: File;
    archiveConnect: ArchiveWhereUniqueInput;
    category?: CategoryWhereUniqueInput;
    metadatas?: UpsertPhotoMetadataValue[];
  }) => {
    let photo: FetchResult<UpdatePhoto>;
    const abort = new AbortController();

    const createPhotoTask = new Task(file.name, {
      run: async (report) => {
        report(0, 'création de photo...');
        const createPhotoRes = await createPhoto({
          refetchQueries:
            ImagesTaskQueue.Stats.PENDING === 0 ? [GET_UNDER_SUBJECTS] : [],
          variables: {
            data: {
              contentType: file.type,
              archive: {
                connect: archiveConnect,
              },
              category: category ? { connect: category } : undefined,
            },
          },
          context: {
            fetchOptions: { signal: abort.signal },
          },
        });

        if (!createPhotoRes.data?.createPhoto.uploadUrl)
          throw new Error('No uploadUrl');

        report(30, 'Téléchargement photo...');

        const res = await fetch(createPhotoRes.data.createPhoto.uploadUrl, {
          method: 'PUT',
          body: file,
          signal: abort.signal,
        });

        if (!res.ok) throw new Error(`Error ${res.status} (${res.statusText})`);

        report(90, 'extraction métadonnés...');

        // We need to first update isUploaded to true, to trigger backend metadata extraction
        const updatePhotoRes = await updatePhoto({
          refetchQueries: [SEARCH_PHOTOS, GET_PHOTOS_COUNT],
          variables: {
            where: { id: createPhotoRes.data.createPhoto.id },
            data: {
              isUploaded: true,
              ...buildPhotoMetadatasUpdate({
                photo: createPhotoRes.data.createPhoto,
                metadatas: metadatas.concat({
                  metadata: MetadataAsArray.BackupName,
                  value: file.name,
                }),
              }),
            },
          },
          context: {
            fetchOptions: { signal: abort.signal },
          },
        });

        if (updatePhotoRes.errors?.length) {
          throw new Error(
            `unable to extract photo metadatas, ${updatePhotoRes.errors.join(
              '\n',
            )}`,
          );
        }
      },
      stop: () => abort.abort(),
      retryOptions: taskRetryOptions,
    });

    ImagesTaskQueue.register(createPhotoTask);

    return new Promise<FetchResult<UpdatePhoto>>((resolve, reject) =>
      createPhotoTask.on(TaskEventName.StatusChange, (status) => {
        switch (status) {
          case TaskStatus.FAILED:
            reject(createPhotoTask.Error);
            return;
          case TaskStatus.STOPED:
            reject(new Error('tâche arrêtée'));
            return;
          case TaskStatus.FINISED:
            resolve(photo);
        }
      }),
    );
  };
};

export const UPDATE_PHOTO = gql`
  mutation UpdatePhoto(
    $where: PhotoWhereUniqueInput!
    $data: PhotoUpdateInput!
  ) {
    updatePhoto(where: $where, data: $data) {
      ...Photo
    }
  }
  ${PHOTO_FRAGMENT}
`;

export const useUpdatePhoto = () => {
  const [updatePhoto] = useMutation<UpdatePhoto, UpdatePhotoVariables>(
    UPDATE_PHOTO,
  );

  return ({
    photo,
    data,
    metadatas = [],
  }: {
    photo: Pick<SearchPhotos_searchPhotos, 'id' | 'metadataByName' | 'archive'>;
    data?: Omit<PhotoUpdateInput, 'photoMetadatas'>;
    metadatas?: UpsertPhotoMetadataValue[];
  }) => {
    return updatePhoto({
      refetchQueries: [GET_UNDER_SUBJECTS],
      variables: {
        where: { id: photo.id },
        data: {
          ...buildPhotoMetadatasUpdate({
            photo,
            metadatas,
          }),
          ...data,
        },
      },
      update: (cache, { data: updatedPhotoData }) => {
        if (!updatedPhotoData?.updatePhoto) {
          return;
        }

        cache.modify({
          fields: {
            searchPhotos(prev: readonly Reference[] = []) {
              const updateRef = `Photo:${updatedPhotoData.updatePhoto?.id}`;
              const photoIndex = prev.findIndex((o) => o.__ref === updateRef);

              if (photoIndex !== -1) {
                cache.updateFragment(
                  {
                    id: updateRef,
                    fragment: gql`
                      ${PHOTO_FRAGMENT}
                    `,
                  },
                  (prevData) => ({
                    ...prevData,
                    ...updatedPhotoData.updatePhoto,
                    // optimistic update of updateAt field to avoid re-querying the server
                    updatedAt: new Date(Date.now()).toISOString(),
                  }),
                );

                return;
              }

              const newPhotoRef = cache.writeFragment({
                data: updatedPhotoData.updatePhoto,
                fragment: gql`
                  ${PHOTO_FRAGMENT}
                `,
              });

              if (!newPhotoRef) {
                return;
              }

              return [...prev, newPhotoRef];
            },
          },
        });
      },
    });
  };
};

export const DELETE_PHOTO = gql`
  mutation DeletePhoto($where: PhotoWhereUniqueInput!) {
    deletePhoto(where: $where) {
      id
    }
  }
`;

export const useDeletePhoto = () => {
  const [deletePhoto] = useMutation<DeletePhoto, DeletePhotoVariables>(
    DELETE_PHOTO,
  );

  return (id: string) =>
    deletePhoto({
      refetchQueries: [SEARCH_PHOTOS, GET_PHOTOS_COUNT, GET_UNDER_SUBJECTS],
      variables: {
        where: { id },
      },
      update: (cache) =>
        cache.modify({
          fields: {
            photos: (prev, { DELETE }) => DELETE,
          },
        }),
      awaitRefetchQueries: true,
    });
};

export const UPDATE_PHOTO_FILE = gql`
  mutation UpdatePhotoFile($photoId: String!) {
    updatePhotoFile(photoId: $photoId) {
      ...Photo
    }
  }
  ${PHOTO_FRAGMENT}
`;

export const useUpdatePhotoFile = () => {
  const [updatePhotoFile] = useMutation<
    UpdatePhotoFile,
    UpdatePhotoFileVariables
  >(UPDATE_PHOTO_FILE);

  return (variables: UpdatePhotoFileVariables) => {
    let result: FetchResult<UpdatePhotoFile>;
    const abort = new AbortController();

    const exportTask = new Task(`export photo ${variables.photoId}`, {
      run: async () => {
        result = await updatePhotoFile({
          variables,
          context: {
            fetchOptions: { signal: abort.signal },
          },
        });
      },
      stop: () => abort.abort(),
      retryOptions: taskRetryOptions,
    });

    ImagesTaskQueue.register(exportTask);

    return new Promise<FetchResult<UpdatePhotoFile>>((resolve, reject) =>
      exportTask.on(TaskEventName.StatusChange, (status) => {
        switch (status) {
          case TaskStatus.FAILED:
            reject(exportTask.Error);
            return;
          case TaskStatus.STOPED:
            reject(new Error('tâche arrêtée'));
            return;
          case TaskStatus.FINISED:
            resolve(result);
        }
      }),
    );
  };
};

export const useUpdatePhotoCache = () => {
  const client = useApolloClient();

  return () =>
    client.refetchQueries({
      updateCache: (cache) =>
        cache.modify({
          fields: {
            photos: (prev, { DELETE }) => DELETE,
            photoCount: (prev, { DELETE }) => DELETE,
          },
        }),
    });
};

export const CLONE_PHOTO = gql`
  mutation ClonePhoto($sourceId: String!, $create: PhotoCreateInput!) {
    clonePhoto(sourceId: $sourceId, create: $create) {
      ...Photo
    }
  }
  ${PHOTO_FRAGMENT}
`;

export const useClonePhoto = () => {
  const [clonePhoto] = useMutation<ClonePhoto, ClonePhotoVariables>(
    CLONE_PHOTO,
  );

  return (variables: ClonePhotoVariables) => {
    let result: FetchResult<UpdatePhotoFile>;
    const abort = new AbortController();

    const exportTask = new Task(`Copier photo ${variables.sourceId}`, {
      run: async () => {
        await clonePhoto({ variables });
      },
      stop: () => abort.abort(),
      retryOptions: taskRetryOptions,
    });

    ImagesTaskQueue.register(exportTask);

    return new Promise<FetchResult<UpdatePhotoFile>>((resolve, reject) =>
      exportTask.on(TaskEventName.StatusChange, (status) => {
        switch (status) {
          case TaskStatus.FAILED:
            reject(exportTask.Error);
            return;
          case TaskStatus.STOPED:
            reject(new Error('tâche arrêtée'));
            return;
          case TaskStatus.FINISED:
            resolve(result);
        }
      }),
    );
  };
};
