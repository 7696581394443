import { useApolloClient } from '@apollo/client';
import { Button, DialogActions, DialogTitle } from '@mui/material';
import {
  LoadingButton,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { path } from 'ramda';
import React, { FC, useCallback, useState } from 'react';
import { LocationPage_GetPrintHeadingByPrintIssue_printHeadings } from '../../../../__generated__/queries-topic';
import { mutateHeadingAutomaticSubject } from './utils';

interface DialogDisableAutoSubjectProps {
  handleClose: () => void;
  printHeading: LocationPage_GetPrintHeadingByPrintIssue_printHeadings;
  printIssueId: string;
}

export const DialogDisableAutoSubject: FC<DialogDisableAutoSubjectProps> = ({
  handleClose,
  printHeading,
  printIssueId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification } = useNotification();
  const client = useApolloClient();
  const handleDisableAutoSubject = useCallback(
    async () => {
      setIsLoading(true);
      const resultHeading = await mutateHeadingAutomaticSubject(
        client,
        {
          id: printHeading.id,
          title: printHeading.title,
          order: printHeading.order,
          autoPrototype: printHeading.autoPrototype,
          hasAutomaticSubject: false,
        },
        printIssueId,
      );
      if (
        path(['data', 'upsertPrintHeading', 'status'], resultHeading) === 'OK'
      ) {
        pushNotification({
          message: 'La fonction a été correctement désactivée',
          type: NotificationTypeEnum.success,
        });
      } else {
        pushNotification({
          message: "Une erreur s'est produite",
          type: NotificationTypeEnum.error,
        });
      }
      setIsLoading(false);
      handleClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [printHeading, printIssueId],
  );

  return (
    <>
      <DialogTitle>
        Êtes-vous sûr de vouloir désactiver la fonctionnalité de création
        automatique de sujet sur cette têtière ?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <LoadingButton
          data-cy="create-unit-save-button"
          color="primary"
          variant="contained"
          loading={isLoading}
          onClick={handleDisableAutoSubject}
        >
          Confirmer
        </LoadingButton>
      </DialogActions>
    </>
  );
};
