import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  LocationPage_UpsertPrintPublication,
  LocationPage_UpsertPrintPublicationVariables,
} from '../../../../__generated__/queries-topic';
import { PRINT_PUBLICATION_FRAGMENT } from '../printPublicationFragment.topic.graphql';

const UPSERT_PRINT_PUBLICATION = gql`
  mutation LocationPage_UpsertPrintPublication(
    $create: PrintPublicationCreationInput!
    $update: PrintPublicationUpdateInput!
    $where: PrintPublicationUniqueFilterInput!
  ) {
    upsertPrintPublication(create: $create, update: $update, where: $where) {
      ...PrintPublicationFragment
    }
  }
  ${PRINT_PUBLICATION_FRAGMENT}
`;

export const usePrintPublicationUpsert = (
  options?: MutationHookOptions<
    LocationPage_UpsertPrintPublication,
    LocationPage_UpsertPrintPublicationVariables
  >,
) =>
  useMutation<
    LocationPage_UpsertPrintPublication,
    LocationPage_UpsertPrintPublicationVariables
  >(UPSERT_PRINT_PUBLICATION, options);
