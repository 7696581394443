import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  GetRawArticleMajorVersions,
  GetRawArticleMajorVersionsVariables,
} from '../../__generated__/queries-topic';

const MAJOR_VERSION_FRAGMENT = gql`
  fragment ArticleHistory_MajorVersion on RawArticleVersion {
    id
    status
    title
    lead
    body
    signature
    leftOver
    editorId
    createdAt
    editor {
      id
      name
      avatarUrl
    }
    previousMinorVersions(first: 150) {
      id
      editor {
        id
        name
        avatarUrl
      }
      createdAt
    }
  }
`;

const GET_RAW_ARTICLE_MAJOR_VERSIONS = gql`
  query GetRawArticleMajorVersions($id: ID!) {
    rawArticle(where: { id: $id }) {
      id
      majorVersions(first: 100) {
        ...ArticleHistory_MajorVersion
      }
    }
  }
  ${MAJOR_VERSION_FRAGMENT}
`;

export const useGetRawArticleMajorVersions = (
  variables: GetRawArticleMajorVersionsVariables,
  options: Omit<
    QueryHookOptions<
      GetRawArticleMajorVersions,
      GetRawArticleMajorVersionsVariables
    >,
    'variables'
  >,
) =>
  useQuery<GetRawArticleMajorVersions, GetRawArticleMajorVersionsVariables>(
    GET_RAW_ARTICLE_MAJOR_VERSIONS,
    {
      variables,
      ...options,
    },
  );
