import { gql, useQuery } from '@apollo/client';
import { useGetter } from '../../../apollo/useGetter';
import { ASSIGNMENT_FRAGMENT } from '../../../components/ListItem/AssignmentItem/fragment.topic.graphql';
import {
  GetSubjects,
  GetSubjectsVariables,
  RawArticleStatus,
} from '../../../__generated__/queries-topic';

export const getWhereInputLocationFilter = (locationIds: string[]) => ({
  assignments_some: {
    OR: [
      { printPublication: { id_in: locationIds } },
      {
        printIssue: {
          OR: [
            { id_in: locationIds },
            { printPublication: { id_in: locationIds } },
          ],
        },
      },
      {
        printHeading: {
          OR: [
            { id_in: locationIds },
            {
              printIssue: {
                OR: [
                  { id_in: locationIds },
                  { printPublication: { id_in: locationIds } },
                ],
              },
            },
          ],
        },
      },
      { website: { id_in: locationIds } },
    ],
  },
});

export const getWhereInputTextStatusFilter = (
  textStatusIds: RawArticleStatus[],
) => ({
  assignments_some: {
    rawArticle: {
      versions_some: {
        nextVersion_is_null: true,
        status_in: textStatusIds,
      },
    },
  },
});

export const getWhereInputCategoryFilter = (categoryIds: string[]) => ({
  OR: [
    { subjectCategory: { id_in: categoryIds } },
    { subjectCategory: { parent: { id_in: categoryIds } } },
  ],
});

export const getWhereInputSearchFilter = (searchString: string) => ({
  title_contains: searchString,
});

export const getWhereInputUnitFilter = (unitId: string) => ({
  unit: {
    id: unitId,
  },
});

const GET_SUBJECTS = gql`
  query GetSubjects(
    $where: SubjectFilterInput!
    $skip: UnsignedInt!
    $first: UnsignedInt = 20
  ) {
    subjects(
      where: $where
      skip: $skip
      orderBy: [createdAt_DESC]
      first: $first
    ) {
      id
      title
      comment
      assignments(first: 20) {
        ...AssignmentItem
      }
      assignmentCount
      subjectCategory {
        id
        title
        parent {
          id
          title
        }
      }
      unit {
        id
        title
        photoArchiveId
        brands(first: 20) {
          id
          title
        }
      }
      isClassified
    }
  }
  ${ASSIGNMENT_FRAGMENT}
`;

export const useGetSubjects = (variables: GetSubjectsVariables) =>
  useQuery<GetSubjects, GetSubjectsVariables>(GET_SUBJECTS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

export const useSubjectsGetter = () =>
  useGetter<GetSubjects, GetSubjectsVariables>(GET_SUBJECTS);
