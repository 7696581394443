import { gql, useQuery } from '@apollo/client';
import {
  GetRawArticle,
  GetRawArticleVariables,
} from '../../__generated__/queries-topic';
import { VERSION_FRAGMENT } from '../../pages/ArticleForm/version.fragment.topic.graphql';
import { useGetter } from '../useGetter';
import { PRINT_TEMPLATE_PRINT_TEMPLATE_FRAGMENT } from './printTemplate.topic.graphql';

const PRINT_TEMPLATE_ASSIGNMENT_FRAGMENT = gql`
  fragment PrintTemplateAssignment on Assignment {
    printTemplate {
      id
      label
      data
      fields {
        id
        name
        type
        page {
          offset
          coordinates {
            x
            y
            height
            width
            rotation
          }
        }
        data
        typeVariation
        paragraphStyles {
          id
          name
        }
        characterStyles {
          id
          name
        }
      }
    }
  }
`;

const ARTICLE_FORM_ASSIGNMENT_FRAGMENT = gql`
  fragment ArticleForm_Assignment on Assignment {
    id
    printPublication {
      id
      title
    }
    printIssue {
      id
      title
      printPublication {
        id
        title
      }
    }
    printHeading {
      id
      title
      autoPrototype
      printTemplates(first: 50) {
        id
        active
        ...printTemplate
      }
      printIssue {
        id
        title
        printPublication {
          id
          title
        }
      }
    }
    website {
      id
      title
      key
    }
  }
  ${PRINT_TEMPLATE_PRINT_TEMPLATE_FRAGMENT}
`;

const ARTICLE_FORM_RAW_ARTICLE_FRAGMENT = gql`
  fragment ArticleForm_RawArticle on RawArticle {
    id
    versions(first: 1, where: { nextVersion_is_null: true }) {
      ...ArticleForm_RawArticleVersionFragment
    }
    versionList: versions(first: 100, orderBy: _id_DESC) {
      id
      status
      editorId
      editor {
        id
        name
        avatarUrl
      }
    }
    assignment {
      ...ArticleForm_Assignment
      ...PrintTemplateAssignment
      subject {
        id
        title
        synopsis
        unit {
          id
          title
        }
      }
      articleTemplate {
        id
        label
        block
        fields {
          id
          name
          type
          enabled
          data
          typeVariation
          paragraphStyles {
            id
            name
          }
          characterStyles {
            id
            name
          }
          coordinates {
            x
            y
            width
            height
            rotation
          }
          page {
            offset
            number
            coordinates {
              x
              y
              width
              height
              rotation
            }
          }
        }
        printTemplate {
          id
          type
          fields {
            id
            name
            type
            page {
              offset
              coordinates {
                x
                y
                height
                width
                rotation
              }
            }
            data
            typeVariation
            paragraphStyles {
              id
              name
            }
            characterStyles {
              id
              name
            }
          }
        }
      }
      printTemplate {
        id
      }
    }
  }
  ${VERSION_FRAGMENT}
  ${ARTICLE_FORM_ASSIGNMENT_FRAGMENT}
  ${PRINT_TEMPLATE_ASSIGNMENT_FRAGMENT}
`;

export const GET_RAW_ARTICLE = gql`
  query GetRawArticle($id: ID!) {
    rawArticle(where: { id: $id }) {
      ...ArticleForm_RawArticle
    }
  }
  ${ARTICLE_FORM_RAW_ARTICLE_FRAGMENT}
`;

export const useGetRawArticle = (variables: GetRawArticleVariables) =>
  useQuery<GetRawArticle, GetRawArticleVariables>(GET_RAW_ARTICLE, {
    variables,
    fetchPolicy: 'network-only',
  });

export const useRawArticleGetter = () =>
  useGetter<GetRawArticle, GetRawArticleVariables>(GET_RAW_ARTICLE);
