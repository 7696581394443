import { gql, useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import {
  DeleteSubjectUser,
  DeleteSubjectUserVariables,
} from '../../../__generated__/queries-topic';

const DELETE_SUBJECT_USER = gql`
  mutation DeleteSubjectUser($userId: String!, $subjectId: ID!) {
    deleteSubjectUser(where: { userId: $userId, subject: { id: $subjectId } }) {
      userId
    }
  }
`;

export const useSubjectUserDeleter = (
  options?: MutationHookOptions<DeleteSubjectUser, DeleteSubjectUserVariables>,
) =>
  useMutation<DeleteSubjectUser, DeleteSubjectUserVariables>(
    DELETE_SUBJECT_USER,
    options,
  );
